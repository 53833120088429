"use client";

import {onGetTableResponse} from "@/libs/helper";
import {NeoDashboardService} from "./dashboard.service";
import {driversService} from "./drivers.service";
import {reortsService} from "./reports.service";
import {userService} from "./users.service";
import {vehicleService} from "./vehicle.service";

const onGet = async (url: string, id: number, body: any): Promise<any> => {
  switch (id) {
    case 1:
      return await userService.onGetUsers(url, body);

    case 2:
      return await vehicleService.onGetVehicles(url, body);

    case 3:
      return await driversService.onGetDrivers(url, body);

    case 4:
      if (Object.prototype.hasOwnProperty.call(body, "trackerId")) {
        if (body.trackerId) {
          return await reortsService.onGetReportData(url, body);
        }
      } else {
        if (
          Object.prototype.hasOwnProperty.call(body, "from") &&
          Object.prototype.hasOwnProperty.call(body, "to")
        ) {
          return await reortsService.onGetReportData(url, body);
        }
        return onGetTableResponse();
      }
      return onGetTableResponse();
    case 5:
      if (
        Object.prototype.hasOwnProperty.call(body, "from") &&
        Object.prototype.hasOwnProperty.call(body, "to")
      ) {
        return await NeoDashboardService.onGetDashboardReportData(url, body);
      }
      return onGetTableResponse();
    default:
      return onGetTableResponse();
  }
};

const onPut = async (
  id: number,
  institutionUserId: string | null,
  url: string,
  actionKey: string,
  body: any
): Promise<any> => {
  switch (id) {
    case 1:
      return await userService.onUserAction(
        url,
        institutionUserId,
        actionKey,
        body
      );
    case 3:
      return await driversService.onDriverAction(
        url,
        institutionUserId,
        actionKey,
        body
      );
    default:
      return onGetTableResponse();
  }
};

export const tableService = {
  onGet,
  onPut
};
