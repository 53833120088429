"use client";
import {NeoCard, NeoError} from "@/components/shared";
import {useAllVehicleContext} from "@/context/live/allvehicle.context";
import {useLiveContext} from "@/context/live/live.context";
import {BusIcon} from "@/data";
import {Checkbox, Spinner} from "@nextui-org/react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useJsApiLoader
} from "@react-google-maps/api";

import {PageType} from "@/libs/enums/live";
import {calcTime} from "@/libs/helper";
import {trackingService} from "@/service/tracking.service";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Info} from "./info";
import {PlayBack} from "./playback";
const mapKey: string = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "";

export const MapView: React.FC<MapViewProps> = ({
  page,
  dateRange,
  tabStatus,
  vehicleSelctedRoute
}) => {
  const darkModeStyle = [
    {elementType: "geometry", stylers: [{color: "#212121"}]},
    {elementType: "labels.icon", stylers: [{visibility: "off"}]},
    {elementType: "labels.text.fill", stylers: [{color: "#757575"}]},
    {elementType: "labels.text.stroke", stylers: [{color: "#212121"}]},
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{color: "#757575"}]
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}]
    },
    {featureType: "administrative.land_parcel", stylers: [{visibility: "off"}]},
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{color: "#bdbdbd"}]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{color: "#181818"}]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [{color: "#1b1b1b"}]
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{color: "#2c2c2c"}]
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{color: "#8a8a8a"}]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{color: "#373737"}]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{color: "#3c3c3c"}]
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [{color: "#4e4e4e"}]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}]
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{color: "#000000"}]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{color: "#3d3d3d"}]
    }
  ];

  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#7c93a3"
        },
        {
          lightness: "-10"
        }
      ]
    },
    {
      featureType: "administrative.country",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#c2d1d6"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#dde3e3"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#c2d1d6"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#a9b4b8"
        },
        {
          lightness: "0"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a3c7df"
        }
      ]
    }
  ];
  const [color, setColor] = useState("red");
  const [showAllVehicle, setshowAllVehicle] = useState(false);
  const [mapZoom, setMapZoom] = useState<number>(16);
  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey: mapKey
  });
  const mapRef = useRef<google.maps.Map | null>(null);

  const {selectedVehicle, gpsLocations, filterValues} = useLiveContext();
  const {data} = useAllVehicleContext();
  const prevSelectedVehicle = useRef(selectedVehicle);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 11.0168,
    lng: 76.9558
  });
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);
  const [markerPositions, setMarkerPositions] = useState<NeoMap[]>([]);
  const [boardingPoints, setBoardingPoints] = useState<NeoMap[]>([]);
  const [refRoutePolylinePaths, setRefRoutePolylinePaths] = useState<
    google.maps.LatLng[]
  >([]);
  const [routes, setRoutes] = useState<google.maps.LatLng[]>([]);
  const [paths, setPaths] = useState<NeoMap[]>([]);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [hoverWindowInfo, setHoverWindowInfo] = useState<HoverInfo>(
    {} as HoverInfo
  );
  const [showHoverInfo, setShowHoverInfo] = useState<boolean>(false);
  const [lastLocations, setLastLocations] = useState<{
    [vehicleId: string]: {
      lastTime: number;
    };
  }>(null as any);
  const [colorMarker, setColorMarker] = useState<ColorStationary[]>([]);
  const [colorMarkerInfo, setColorMarkerInfo] = useState<any>(null);
  const [bplocationInfo, setBplocationInfo] = useState<any>(null);
  const setZoom = useCallback(
    (data: NeoMap[]) => {
      if (data.length === 0 || !map) {
        return;
      }
      const position = data[0];
      const latLng = new window.google.maps.LatLng(position.lat!, position.lng);
      map.setCenter(latLng);
      map.setZoom(mapZoom);
    },
    [map, mapZoom]
  );

  const setBound = useCallback(
    (locations: NeoMap[], livePosition: NeoMap) => {
      if (!locations || !map) {
        return;
      }
      const liveLocation = new google.maps.LatLng(
        livePosition.lat!,
        livePosition.lng!
      );
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((position: any) => {
        bounds.extend(
          new window.google.maps.LatLng(position.lat, position.lng)
        );
      });
      // if (livePosition) {
      //   const liveLocation = new google.maps.LatLng(
      //     livePosition.lat!,
      //     livePosition.lng!
      //   );
      //   bounds.extend(liveLocation);
      // }
      // map.fitBounds(bounds);
      map.panTo(liveLocation);
    },
    [map]
  );

  useEffect(() => {
    resetLiveMapData();
    setshowAllVehicle(false);
  }, [page]);

  const resetLiveMapData = () => {
    setRefRoutePolylinePaths([]);
    setColorMarker([]);
    setColorMarkerInfo(null);
    setSelectedMarker([]);
    setShowInfo(false);
    setRoutes([]);
    setMarkerPositions([]);
    setBoardingPoints([]);
    setBplocationInfo(null);
  };
  useEffect(() => {
    if (isLoaded && selectedVehicle && routes.length == 0 && !showAllVehicle) {
      if (
        selectedVehicle?.location?.lat !== undefined &&
        selectedVehicle?.location?.lng !== undefined
      ) {
        const livePositions = [
          {
            lat: selectedVehicle?.location?.lat,
            lng: selectedVehicle?.location?.lng
          }
        ];
        if (!checkPosition(markerPositions, livePositions)) {
          setMarkerPositions(livePositions);
          setZoom(livePositions);
        }
      } else {
        setMarkerPositions([]);
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(position => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setZoom([{lat: latitude, lng: longitude}] as NeoMap[]);
          });
        }
      }
    }
  }, [
    isLoaded,
    routes,
    map,
    mapCenter?.lat,
    mapCenter?.lng,
    markerPositions,
    selectedVehicle,
    setZoom
  ]);

  useEffect(() => {
    if (
      isLoaded &&
      selectedVehicle &&
      gpsLocations &&
      page === PageType.LIVE &&
      !showAllVehicle
    ) {
      const locations =
        gpsLocations[selectedVehicle.vehicleId]?.locations || [];
      const refRoutes =
        gpsLocations[selectedVehicle.vehicleId]?.refRouteDetails || [];
      const refRoutesUserBoard =
        gpsLocations[selectedVehicle.vehicleId]?.userBoardings || [];
      const trackerStatus =
        gpsLocations[selectedVehicle.vehicleId]?.trackerStatus;
      // the below condtion is based when changing the one vehicle to another vehicle
      if (prevSelectedVehicle.current !== selectedVehicle) {
        setColorMarkerInfo(null);
        setBplocationInfo(null);
      }

      if (locations && locations.length > 0) {
        const livePosition =
          locations.length == 1
            ? locations[0]
            : locations[locations.length - 1];
        setMarkerPositions([livePosition]);
        // setSelectedMarker(livePosition);
        if (showInfo) {
          const sumOfDistances = locations.reduce(
            (total, currentValue) =>
              total + (currentValue.di ? currentValue.di : 0),
            0
          );
          handleMarkerClick(livePosition, sumOfDistances);
        }
        const routes: google.maps.LatLng[] =
          locations as unknown as google.maps.LatLng[];
        setRoutes(routes);
        const routess: google.maps.LatLng[] =
          locations as unknown as google.maps.LatLng[];
        if (lastLocations && lastLocations[selectedVehicle.vehicleId]) {
          const lastTime = lastLocations[selectedVehicle.vehicleId].lastTime;
          const filteredRoutes = locations.filter((location: any) => {
            return location.ti > lastTime;
          });
          // console.log("before count"+ routes.length);
          if (filteredRoutes.length > 0) {
            setRoutes((preRoutes: any) => [...preRoutes, ...filteredRoutes]);
          } else {
            setRoutes(routess);
          }
        } else {
          setRoutes(routess);
        }
        if (showInfo) {
          //if the vehicle infowindow is open update the info
        }
        setLastLocations((prev: any) => {
          const lastLoaction = {
            ...prev,
            [selectedVehicle.vehicleId]: {
              lastTime: locations[locations.length - 1].ti
            }
          };
          return lastLoaction;
        });
        if (trackerStatus === 0) {
          // moving
          const checkCurrentVisbleLatlng = checkLatLngInBounds(
            livePosition.lat,
            livePosition.lng
          );
          if (checkCurrentVisbleLatlng) {
            setBound(locations, livePosition);
          }
        }
        if (prevSelectedVehicle.current !== selectedVehicle) {
          setBound(locations, livePosition);
        }
        stationaryColorMarker(routes);
      } else {
        setRoutes([]);
        setPaths([]);
      }
      if (refRoutes)
        setRefRoutePolylinePaths(refRoutes as unknown as google.maps.LatLng[]);
      if (refRoutesUserBoard) setBoardingPoints(refRoutesUserBoard);
      prevSelectedVehicle.current = selectedVehicle;
    }
  }, [isLoaded, selectedVehicle, gpsLocations]);
  //show all use effect
  useEffect(() => {
    if (showAllVehicle) {
      resetLiveMapData();
      let filteredArray: any = [];
      if (data.length > 0) {
        if (
          tabStatus === 0 &&
          filterValues?.vehicleId &&
          filterValues?.vehicleId !== undefined
        ) {
          const filteredNames = filterValues.vehicleId as string;
          filteredArray = filteredNames.split(",");
        }
        let allLoctions = [];
        if (filteredArray.length > 0) {
          allLoctions = data
            .filter(item => filteredArray.includes(item.name.trim()))
            .map(item => ({
              name: item.name,
              date: item.tripDate as string,
              totalDistance: item.distance as string,
              speed: item.speed as number,
              lat: item.location.lat,
              lng: item.location.lng
            }));
        } else {
          allLoctions = data.map(item => ({
            name: item.name,
            date: item.tripDate as string,
            totalDistance: item.distance as string,
            speed: item.speed as number,
            lat: item.location.lat,
            lng: item.location.lng
          }));
        }

        setMarkerPositions(allLoctions);
        //when selectedVehicle changes
        if (prevSelectedVehicle.current !== selectedVehicle) {
          const infoMarker = allLoctions.find(
            (location: NeoMap) => location?.name === selectedVehicle?.name
          );
          if (infoMarker) {
            setSelectedMarker(infoMarker);
            setShowInfo(true);
            handleMarkerClick(infoMarker);
          }
        } else {
          if (showInfo) {
            const infoMarker = allLoctions.find(
              (location: NeoMap) => location.name === selectedMarker.name
            );
            if (infoMarker) {
              handleMarkerClick(infoMarker);
            }
          }
        }
        // Update prevSelectedVehicle to the current value
        prevSelectedVehicle.current = selectedVehicle;
      }
    }
  }, [showAllVehicle, data, selectedVehicle, filterValues]);

  useEffect(() => {
    setRefRoutePolylinePaths([]);
    setBoardingPoints([]);
    if (vehicleSelctedRoute) {
      //routeRefDataBasedOnrouteid
      const loadData = async () => {
        await trackingService
          .routeRefDataBasedOnrouteid(vehicleSelctedRoute)
          .then((response: any) => {
            const refRoutes = response.refRouteDetails;
            const refRoutesUserBoard = response.userBoardings;

            if (refRoutes)
              setRefRoutePolylinePaths(
                refRoutes as unknown as google.maps.LatLng[]
              );
            if (refRoutesUserBoard) setBoardingPoints(refRoutesUserBoard);
          })
          .catch(() => {});
      };
      loadData();
    }
  }, [vehicleSelctedRoute]);
  const stationaryColorMarker = (trackingData: any) => {
    let staionaryfrom = 0;
    const stationaryData: ColorStationary[] = trackingData.reduce(
      (acc: any, item: any, index: number, array: any) => {
        if (item.sp < 3) {
          if (staionaryfrom === 0) {
            staionaryfrom = item.ti;
          }
        } else {
          if (staionaryfrom !== 0) {
            const diffMin = diff_minutes(staionaryfrom, item.ti);
            if (diffMin > 1) {
              var fillColor = "";
              if (diffMin >= 5) {
                //red
                fillColor = "#f00";
              } else if (diffMin > 3 && diffMin < 5) {
                //orange
                fillColor = "#FFA500";
              } else if (diffMin >= 1 || diffMin > 3) {
                //blue
                fillColor = "#0000FF";
              }
              let diffMinStr: any = diffMin;
              if (diffMin > 60) {
                var hours = Math.floor(diffMin / 60);
                var remainingMinutes = diffMin % 60;
                `${hours} hours and ${remainingMinutes} minutes`;
                diffMinStr = `${hours} hours and ${remainingMinutes} minutes`;
              }
              acc.push({
                latitude: item.lat,
                longitude: item.lng,
                diffTime: diffMinStr,
                staionary_start: staionaryfrom,
                staionary_end: item.ti,
                fillColor: fillColor
              });
            }
            staionaryfrom = 0;
          }
        }
        return acc;
      },
      []
    );
    setColorMarker(stationaryData);
  };
  const diff_minutes = function (dt2: number, dt1: number) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };
  const checkLatLngInBounds = (lat: any, lng: any) => {
    const bounds = mapRef.current?.getBounds();

    if (bounds) {
      const latLng = new window.google.maps.LatLng(lat, lng);
      return bounds.contains(latLng);
    }

    return false;
  };
  const containerStyle = {
    width: "100%",
    height: "auto"
  };

  const onMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMap(map);
  };

  const handleMarkerClick = (position: NeoMap, totalDisVal: any = "") => {
    setSelectedMarker(() => null);
    const updatedMarkerData = {...position}; // Create a copy of markerData to avoid mutating the original object

    if (!showAllVehicle) {
      const date = calcTime(updatedMarkerData.ti as number);
      const speed = updatedMarkerData.sp;
      const totalDistance = totalDisVal
        ? (totalDisVal / 1000).toFixed(2)
        : selectedVehicle?.distance;

      const formattedDistance = totalDistance?.toString().includes("km")
        ? totalDistance
        : `${totalDistance} km`;

      const lat = updatedMarkerData.lat;
      const lng = updatedMarkerData.lng;
      const selectedMarkData = {
        name: selectedVehicle?.name,
        date: date,
        speed: speed,
        totalDistance: formattedDistance,
        lat: lat,
        lng: lng
      };
      setSelectedMarker(selectedMarkData);
      setShowInfo(true);
    } else {
      const lat = updatedMarkerData.lat;
      const lng = updatedMarkerData.lng;
      const name = updatedMarkerData.name;
      const distance = updatedMarkerData?.totalDistance;
      const date = updatedMarkerData.date;
      const speed = updatedMarkerData.speed;
      //const date = updatedMarkerData.tripDate;
      const selectedMarkData = {
        name: name,
        date: date,
        speed: speed,
        totalDistance: distance,
        lat: lat,
        lng: lng
      };
      setSelectedMarker(selectedMarkData);
      setShowInfo(true);
    }
  };

  const handlePlaybackUpdate = (event: TPlaybackData) => {
    //console.log(event);
    if (event && event.coordinates) {
      setMarkerPositions([event.coordinates]);
    }
  };

  const setPlaybackPolylinePaths = (routes: NeoMap[]) => {
    setRoutes(routes as unknown as google.maps.LatLng[]);
    stationaryColorMarker(routes);
    if (routes.length > 0) {
      const coordinates = routes[0];
      setMapCenter({
        lat: coordinates.lat || 0.0,
        lng: coordinates.lng || 0.0
      });
      setMarkerPositions([coordinates]);
      setMapZoom(12);
    }
  };
  function closestLocation(targetLocation: any, locationData: any) {
    function vectorDistance(dx: any, dy: any) {
      return Math.sqrt(dx * dx + dy * dy);
    }

    function locationDistance(location1: any, location2: any) {
      var dx = location1.lat - location2.lat,
        dy = location1.lng - location2.lng;

      return vectorDistance(dx, dy);
    }

    return locationData.reduce(function (prev: any, curr: any) {
      var prevDistance = locationDistance(targetLocation, prev),
        currDistance = locationDistance(targetLocation, curr);
      return prevDistance < currDistance ? prev : curr;
    });
  }
  const handleMouseOver = (event: google.maps.MapMouseEvent, data: any) => {
    var latLng = event.latLng;
    var eLat = latLng ? latLng.lat().toFixed(8) : "",
      eLng = latLng ? latLng.lng().toFixed(8) : "";

    if (eLat && eLng) {
      const targetLocation = {
        lat: eLat,
        lng: eLng
      };
      const closest = closestLocation(targetLocation, data);

      if (closest && closest.ti !== undefined) {
        setShowHoverInfo(false);
        const latLng = closest.lat + " , " + closest.lng;
        setHoverWindowInfo({
          latLan: event.latLng || null,
          info: {
            speed: closest.sp,
            duration: calcTime(closest.ti),
            latLng: latLng
          }
        });
        setShowHoverInfo(true);
      }
    }

    //console.log(start);
  };
  const handleCheckboxChange = (event: any) => {
    setshowAllVehicle(event.target.checked);
  };
  const handleColorMarkerClick = (markerData: ColorStationary) => {
    setColorMarkerInfo(() => null);
    const updatedMarkerData = {...markerData}; // Create a copy of markerData to avoid mutating the original object

    const newStartTime = calcTime(markerData.staionary_start as number);
    const newEndTime = calcTime(markerData.staionary_end as number);
    updatedMarkerData.staionary_start = newStartTime;
    updatedMarkerData.staionary_end = newEndTime;
    setColorMarkerInfo(updatedMarkerData);
  };
  const generateDirectionMarkers = () => {
    const markers = [];
    for (let i = 0; i < routes.length - 1; i++) {
      const angle = calculateAngle(routes[i], routes[i + 1]);
      markers.push(
        <Marker
          key={i}
          position={routes[i + 1]}
          icon={{
            path: "M0,-5L-10,0L0,5",
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 1,
            rotation: angle,
            scale: 1
          }}
        />
      );
    }
    return markers;
  };

  // Calculate the angle between two points
  const calculateAngle = (from: any, to: any) => {
    return (Math.atan2(to.lat - from.lat, to.lng - from.lng) * 180) / Math.PI;
  };

  const handleVehicleRouteName = (
    vehicleName: string,
    vehicleIndex: number
  ) => {
    const parts = vehicleName.split("_");
    // Check if any part is <= 3
    if (parts[0].length <= 3) {
      return parts[0];
    } else {
      return vehicleIndex + 1;
    }
  };

  const handleBpMarkerClick = (position: any) => {
    const updatedMarkerData = {...position};
    const bpmarkerData = {
      bpName: updatedMarkerData.bpName,
      lat: updatedMarkerData.lat,
      lng: updatedMarkerData.lng,
      users: updatedMarkerData.users
    };
    setBplocationInfo(bpmarkerData);
  };
  return (
    <div className="flex h-full">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={onMapLoad}
          options={{
            styles: mapStyles, // Apply custom theme here
            disableDefaultUI: false // Optional: Hide default controls
          }}
          // options={{
          //   styles: darkModeStyle, // Apply the dark mode style here
          //   disableDefaultUI: true, // Optional: Disable default map controls
          // }}
        >
          {markerPositions.map((position, index) => (
            <Marker
              key={index}
              // icon={{
              //   url: `https://via.placeholder.com/1x1/${encodeURIComponent('#4285F4')}/ffffff?text=1+`, // Placeholder image with background color
              //   scaledSize: new window.google.maps.Size(30, 30), // Size of the marker
              //   labelOrigin: new window.google.maps.Point(15, 12) // Positioning of label relative to the icon
              // }}
              icon={{
                url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(BusIcon.replace("%color%", color))}`,
                scale: 4,
                scaledSize: new window.google.maps.Size(40, 40)
              }}
              // icon={{
              //   url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Custom marker icon
              //   labelOrigin: new window.google.maps.Point(16, 10) // Positioning of label relative to the icon
              // }}
              label={{
                text: showAllVehicle
                  ? (position.name
                      ? handleVehicleRouteName(position.name, index)
                      : "-"
                    ).toString()
                  : "-", // Text of the label
                //className: 'custom-marker-label', // CSS class for styling the label
                color: "black" // Text color
                // backgroundColor: 'blue', // Background color
                // fontSize: '14px', // Font size
                // padding: '5px 10px', // Padding
                // borderRadius: '10px', // Rounded corners
                // fontWeight: 'bold' // Font weight
              }}
              animation={google.maps.Animation.DROP}
              position={{lat: position.lat!, lng: position.lng!}}
              onClick={() => handleMarkerClick(position)}
            ></Marker>
          ))}
          {showInfo && page === PageType.LIVE && (
            <InfoWindow
              position={{lat: selectedMarker?.lat!, lng: selectedMarker?.lng!}}
              onCloseClick={() => setShowInfo(false)}
            >
              <Info data={selectedMarker ? selectedMarker : []} type="Live" />
            </InfoWindow>
          )}
          {boardingPoints.map((position, key) => (
            <Marker
              key={key}
              icon={{
                //live_bp_home.png ||  live_bp_parking
                url:
                  position.type !== undefined && position.type >= 0
                    ? "/images/boarding_change.png"
                    : "/images/boarding_change.png",
                scaledSize: new google.maps.Size(30, 30) // Adjust width and height as needed
              }}
              animation={google.maps.Animation.DROP}
              position={{lat: position.lat!, lng: position.lng!}}
              onClick={() => handleBpMarkerClick(position)}
            ></Marker>
          ))}

          {/* stationary color marker start*/}
          {colorMarker.map((position, key) => (
            <Marker
              key={key}
              icon={{
                path: google.maps.SymbolPath.CIRCLE, // Example of using a circle symbol
                scale: 5,
                fillColor: position.fillColor,
                fillOpacity: 0.6,
                strokeWeight: 0.4
              }}
              animation={google.maps.Animation.DROP}
              position={{lat: position.latitude!, lng: position.longitude!}}
              onClick={() => handleColorMarkerClick(position)}
            ></Marker>
          ))}

          {colorMarkerInfo && (
            <InfoWindow
              position={{
                lat: colorMarkerInfo.latitude,
                lng: colorMarkerInfo.longitude
              }}
              onCloseClick={() => setColorMarkerInfo(null)}
            >
              <Info data={colorMarkerInfo} type="ColorMarker" />
            </InfoWindow>
          )}
          {/* stationary color marker end */}
          <Polyline
            path={routes}
            options={{strokeColor: "#008000", strokeWeight: 5}}
            onMouseOver={event => handleMouseOver(event, routes)}
            onMouseOut={() => setShowHoverInfo(false)}
          />
          {/* {generateDirectionMarkers()} */}
          {showHoverInfo && hoverWindowInfo.latLan && (
            <InfoWindow position={hoverWindowInfo.latLan}>
              <Info type="Speed" data={hoverWindowInfo.info} />
            </InfoWindow>
          )}
          {/* <NeoPolylines path={routes} /> */}

          {refRoutePolylinePaths && (
            <Polyline
              path={refRoutePolylinePaths}
              options={{
                strokeColor: "#000",
                strokeOpacity: 0,
                icons: [
                  {
                    icon: {
                      path: "M 0,-2 0,1",
                      strokeOpacity: 1,
                      strokeWeight: 3,
                      scale: 2
                    },
                    offset: "0",
                    repeat: "20px"
                  }
                ]
              }}
            />
          )}
          {bplocationInfo && (
            <InfoWindow
              position={{
                lat: bplocationInfo.lat,
                lng: bplocationInfo.lng
              }}
              onCloseClick={() => setBplocationInfo(null)}
            >
              <Info data={bplocationInfo} type="BpMarkerInfo" />
            </InfoWindow>
          )}
          {page === PageType.PLAYBACK && (
            <PlayBack
              setPlaybackPolylinePaths={setPlaybackPolylinePaths}
              handlePlaybackUpdate={handlePlaybackUpdate}
              dateRange={dateRange}
            />
          )}
          {page === PageType.LIVE && (
            <div className="flex h-full items-center justify-center bg-white dark:bg-black dark:text-white">
              <NeoCard className="absolute top-2 flex flex-col rounded-xl  p-2">
                <Checkbox
                  checked={showAllVehicle}
                  onChange={handleCheckboxChange}
                  className=" border border-white bg-white checked:border-white checked:bg-white focus:ring-white dark:bg-black"
                >
                  Show All Vehicle
                </Checkbox>
              </NeoCard>
            </div>
          )}
        </GoogleMap>
      ) : loadError ? (
        <NeoError message={loadError.message}></NeoError>
      ) : (
        <div className="flex h-[calc(100vh-64px)] w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
};

const checkPosition = (markerPositions: NeoMap[], livePositions: NeoMap[]) => {
  if (livePositions.length > 1 && livePositions[0].lat) {
    return true;
  }
  return JSON.stringify(markerPositions) === JSON.stringify(livePositions);
};

type NeoPaths = {
  range: string;
  path: NeoMap[];
  color: string;
  reset: boolean;
};

type HoverInfo = {
  latLan: google.maps.LatLng | null;
  info: SpeedProps;
};

const NeoPolylines: React.FC<{path: any[]}> = ({path}) => {
  const [segments, setSegments] = useState<NeoPaths[]>([]);
  const {selectedVehicle} = useLiveContext();
  const vehicleInfo = useRef<string | null>(null);
  const [hoverWindowInfo, setHoverWindowInfo] = useState<HoverInfo>(
    {} as HoverInfo
  );
  const [showHoverInfo, setShowHoverInfo] = useState<boolean>(false);

  const handleMouseOver = (
    event: google.maps.MapMouseEvent,
    data: NeoPaths
  ) => {
    const precision = 4;
    const start = data?.path.find(
      x =>
        x.lng !== undefined &&
        x.lat !== undefined &&
        (Math.round(x.lng * Math.pow(10, precision)) ===
          Math.round((event.latLng?.lng() || 0) * Math.pow(10, precision)) ||
          Math.round(x.lat * Math.pow(10, precision)) ===
            Math.round((event.latLng?.lat() || 0) * Math.pow(10, precision)))
    );

    if (start && start.ti !== undefined) {
      setHoverWindowInfo({
        latLan: event.latLng || null,
        info: {
          speed: start.sp,
          duration: calcTime(start.ti)
        }
      });
      setShowHoverInfo(true);
    }
  };

  useEffect(() => {
    //if (path.length > 0) {

    if (!vehicleInfo.current && selectedVehicle?.vehicleId) {
      vehicleInfo.current = selectedVehicle?.vehicleId!;
    } else {
      if (vehicleInfo.current != selectedVehicle?.vehicleId) {
        vehicleInfo.current = selectedVehicle?.vehicleId!;
        setSegments([]);
      }
    }

    const colors = {
      speed: "red",
      normal: "",
      delay: "yellow"
    };
    const routes = path.map((item, index) => ({...item, index}));
    const frames = (startIndex: number) => {
      let prevRange = "";
      const segment: NeoPaths = {
        range: "",
        color: "",
        path: [],
        reset: false
      };

      let currentIndex = startIndex;
      for (let index = startIndex; index < routes.length; index++) {
        currentIndex = index;
        const speed = routes[index].sp!;
        const range =
          speed >= 0 && speed <= 7
            ? "0-7"
            : speed >= 8 && speed <= 70
              ? "8-70"
              : "70-120";
        if (prevRange !== range && segment.path.length === 0) {
          (segment.range = range),
            (segment.color =
              range === "0-7"
                ? colors.delay
                : range === "8-70"
                  ? colors.normal
                  : colors.speed),
            segment.path.push(routes[index]);
        } else if (prevRange === range && segment.path.length > 0) {
          segment.path.push(routes[index]);
        } else if (prevRange !== range && segment.path.length > 0) {
          break;
        }
        prevRange = range;
      }
      return {
        index: currentIndex,
        segment: segment
      };
    };
    const plots = [];
    let index = 0;
    while (index < routes.length) {
      const out = frames(index);
      index = out.index;
      if (index >= routes.length - 1 && plots.length > 0) {
        setSegments(plots);
        break;
      }
      plots.push(out.segment);
    }
    // }
  }, [path, selectedVehicle]);

  return (
    <>
      {segments.map((segment, index) => (
        <Polyline
          key={index}
          path={segment.path as unknown as google.maps.LatLng[]}
          onMouseOver={event => handleMouseOver(event, segment)}
          onMouseOut={() => setShowHoverInfo(false)}
          options={{
            strokeColor: segment.color,
            strokeWeight: 2
          }}
        />
      ))}
      {showHoverInfo && hoverWindowInfo.latLan && (
        <InfoWindow position={hoverWindowInfo.latLan}>
          <Info type="Speed" data={hoverWindowInfo.info} />
        </InfoWindow>
      )}
    </>
  );
};
