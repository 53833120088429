"use client";

import {NeoCard, NeoChips, NeoProgress} from "@/components/shared";
import {useAllVehicleContext} from "@/context/live/allvehicle.context";
import {useLiveContext} from "@/context/live/live.context";
import {SystemError} from "@/libs/consts";
import {
  GPSTracker,
  GPSTrackerStatus,
  SYNC,
  StatusType
} from "@/libs/enums/live";
import {liveService} from "@/service/live.service";
import {Badge, Image, Spinner} from "@nextui-org/react";
import {Label} from "flowbite-react";
import {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import {SlArrowRight} from "react-icons/sl";

export const VehicleView: React.FC<VehicleViewProps> = function ({
  status,
  client
}) {
  const {setSelectedVehicle, setGpsTracker, setMeta, setCountDown} =
    useLiveContext();
  const {data, setData, tabVehicles, setTabVehicles} = useAllVehicleContext();
  //const [data, setData] = useState<TrackingInfo[] | []>([]);
  const [items, setItems] = useState<ILiveVehicles>();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<string | null>(null);
  const sync = useRef<SYNC | null>(SYNC.STOP);
  const statusType = useRef<StatusType | null>(null);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const timerId = useRef<NodeJS.Timeout | null>(null);
  const selectedVehicleId = useRef<string | null>(null);
  const institutionUserId = useRef<string | null>(null);
  const {filterValues, setFilterValues} = useLiveContext();
  const [vehicleFilter, setVehicleFilter] = useState("");
  /**
   * WARNING
   *
   * Do not touch this use effect dependencies unless it really needed,
   *  it will create lot of unnecessary service calls which will leads to application memory issue
   *
   */

  useEffect(() => {
    const loadData = async () => {
      await liveService
        .onGetAllVehicles(institutionUserId.current!)
        .then((response: ILiveVehicles) => {
          if (response?.details && response?.details.length > 0) {
            setItems(response);
          }
        })
        .catch(() => {
          sync.current = SYNC.STOP;
          clearInterval(intervalId.current!);
          clearInterval(timerId.current!);
          toast.error(SystemError);
        });
    };
    if (
      sync.current == SYNC.START &&
      intervalId.current == null &&
      client.institutionUserId != null
    ) {
      intervalId.current = setInterval(() => {
        clearInterval(timerId.current!);
        loadData();
        timerId.current = setInterval(() => {
          setCountDown((prevCountdown: number) =>
            prevCountdown === 1 ? 5 : prevCountdown - 1
          );
        }, 1000);
      }, 5000);
    }
    return () => {
      clearInterval(intervalId.current!);
      clearInterval(timerId.current!);
    };
  }, [setCountDown, sync.current, institutionUserId.current]);

  const handleFilter = (items: ILiveVehicles) => {
    return Number(statusType.current) === 0
      ? items?.trackingInfo
      : items?.trackingInfo?.filter(
          (obj: any) => obj.filterStatus === Number(statusType.current)
        );
  };

  useEffect(() => {
    if (items?.trackingInfo && loading === false) {
      const data = handleFilter(items);
      setData(data ?? []);
      setTabVehicles(data ?? []);
      setMeta(items.meta);
    }
    if (filterValues?.vehicleId && filterValues?.vehicleId !== undefined) {
      const filteredNames = filterValues.vehicleId as string;
      // if (filteredNames.length > 0 && filteredNames !== "") {
      //   const filteredArray = filteredNames.split(",");
      //   const filtereddata = filterDropDown(items?.trackingInfo, filteredArray);
      //   setData(filtereddata ?? []);
      // }
      let filteredArray: any = [];
      if (Array.isArray(filteredNames)) {
        filteredArray = filteredNames;
      } else if (filterValues.vehicleId.length > 0 && filteredNames !== "") {
        filteredArray = filteredNames.split(",");
      }
      if (filteredArray.length > 0) {
        const filtereddata = filterDropDown(tabVehicles, filteredArray);
        setData(filtereddata ?? []);
      }
    }
  }, [items, loading, setMeta, filterValues.vehicleId]);

  // useEffect(() =>{
  //     const filteredNames = filterValues.vehicleId as string;
  //     const filteredArray = filteredNames.split(',');
  //     const data = filterDropDown(filteredArray);
  //     setData(data ?? []);
  // },[filterValues.vehicleId]);

  const filterDropDown = (
    allvehicleData: any,
    filteredArray: Array<string>
  ) => {
    return allvehicleData.filter((item: any) =>
      filteredArray.includes(item.name.trim())
    );
  };
  useEffect(() => {
    //We got all feature filter value here
    const searchText = filterValues.searchText;
    setVehicleFilter(searchText);
  }, [filterValues]);

  /**
   * WARNING
   *
   * Do not touch this use effect dependencies unless it really needed,
   *  it will create lot of unnecessary service calls which will leads to application memory issue
   *
   */

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await liveService
        .onGetAllVehicles(institutionUserId.current!)
        .then((response: ILiveVehicles) => {
          if (response?.details && response?.details.length > 0) {
            setItems(response);
            statusType.current = status;
            sync.current = SYNC.START;
            const data = handleFilter(response) || [];
            if (data.length >= 1) {
              //  data[0].institutionUserId = client.institutionUserId!;
              if (selectedVehicleId.current != null) {
                const vechile =
                  data?.find(x => x.vehicleId === selectedVehicleId.current) ||
                  null;
                // if (vechile) {
                //   vechile.institutionUserId = client.institutionUserId!;
                // }
                selectedVehicleId.current = vechile
                  ? vechile?.vehicleId
                  : data[0].vehicleId;
                setSelectedVehicle(vechile ? vechile : data[0]);
              } else {
                const vechileId = data[0].vehicleId;
                selectedVehicleId.current = vechileId;
                setSelectedVehicle(data[0]);
              }
              setSelected(selectedVehicleId.current);
              setGpsTracker(GPSTracker.START);
            } else {
              selectedVehicleId.current = null;
              setSelectedVehicle(null);
              setGpsTracker(GPSTracker.STOP);
            }
          }
        })
        .catch(() => {
          toast.error(SystemError);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    //when change Tab or switch institute that time called these function
    if (status && client.institutionUserId) {
      setGpsTracker(GPSTracker.STOP);
      sync.current = SYNC.STOP;
      institutionUserId.current = client.institutionUserId;
      setSelectedVehicle(null);
      setSelected(null);
      setFilterValues(prev => ({
        ...prev,
        vehicleId: []
      }));
      fetchData();
    }
  }, [status, selectedVehicleId, client.institutionUserId]);

  useEffect(() => {
    setFilterValues(prev => ({
      ...prev,
      driverId: [],
      vehicleId: []
    }));
  }, [client.institutionUserId]);

  const handleAction = (item: TrackingInfo) => {
    if (selected != item.vehicleId) {
      setSelected(item.vehicleId);
      selectedVehicleId.current = item.vehicleId!;
      // item.institutionUserId = client.institutionUserId;
      setSelectedVehicle(item);
      setGpsTracker(GPSTracker.START);
    }
  };
  const handleVehicleRouteName = (
    vehicleName: string,
    vehicleIndex: number
  ) => {
    const parts = vehicleName.split("_");
    // Check if any part is <= 3
    if (parts[0].length <= 3) {
      return parts[0];
    } else {
      return vehicleIndex + 1;
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner color="primary" />
        </div>
      ) : data && data.length == 0 ? (
        <div className="flex h-full w-full justify-center pt-7">
          <h1>No vehicles found</h1>
        </div>
      ) : (
        data &&
        data
          .filter((item: any) => {
            // Filter the data based on search text
            return item.name
              .toLowerCase()
              .includes(vehicleFilter ? vehicleFilter.toLowerCase() : "");
          })
          .map((item, index) => (
            <div key={index} onClick={() => handleAction(item)}>
              <NeoCard
                radius="sm"
                className={`m-3 cursor-pointer p-2 ${selected === item.vehicleId && "border-l-2 border-l-neo-yellow"}`}
              >
                <div className="flex">
                  <div className="grow">
                    <div className="flex flex-col">
                      <div className="flex w-full flex-col">
                        <div className="flex basis-full flex-row items-center gap-1 ">
                          {/* Vehicle Avatar */}
                          <p
                            className="w-7/12 truncate p-2 px-2 text-sm font-bold text-neo-black dark:text-neo-yellow"
                            title={item?.name}
                          >
                            {item?.name}
                          </p>
                          <p className="w-5/12 text-end text-xs">
                            {item?.tripDate!}
                          </p>
                        </div>
                        <div>
                          {(item?.gpsTrackerStatus ===
                            GPSTrackerStatus.MOVING ||
                            item?.gpsTrackerStatus ===
                              GPSTrackerStatus.STATIONARY) &&
                            item.progress != null && (
                              <div className="flex flex-col gap-0">
                                <div className="flex items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="size-5 text-yellow-500"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>

                                  <Label
                                    className="basis-6/12 text-xs"
                                    value={
                                      item?.estimatedTime! ?? "estimating.."
                                    }
                                  />
                                </div>
                                <div className="flex flex-row items-end">
                                  <NeoProgress
                                    aria-label="progress"
                                    minValue={0}
                                    size="sm"
                                    radius="sm"
                                    maxValue={100}
                                    color="success"
                                    value={item?.progress!}
                                    className="basis-8/12"
                                    showValueLabel={false}
                                  />
                                  <Label
                                    className="basis-4/12 text-center text-xs font-bold text-neo-green"
                                    value={item?.progress! + "%"}
                                  />
                                </div>
                              </div>
                            )}
                          {(item?.gpsTrackerStatus ===
                            GPSTrackerStatus.MOVING ||
                            item?.gpsTrackerStatus ===
                              GPSTrackerStatus.STATIONARY) &&
                            item.progress != null && (
                              <div className="pt-1">
                                <Label
                                  className="grow text-right text-xs font-bold text-neo-green"
                                  value={item?.tripStatus}
                                />
                              </div>
                            )}
                        </div>
                        <div className="flex justify-between">
                          <div className="flex items-center gap-1">
                            <Image
                              alt="Distance"
                              src="/icons/distance.svg"
                              radius="none"
                            />
                            <SplitLabel
                              startValue={item?.distance! ?? "Unknown"}
                              endValue=""
                              type="text"
                            />
                          </div>
                          <div className="flex items-center gap-1">
                            <Image
                              src="/icons/vehicleSpeed.svg"
                              alt="speed"
                              radius="none"
                            />
                            <SplitLabel
                              startValue={`${item?.speed ?? 0} kph`}
                              endValue={item?.currentStatus ?? "Unknown"}
                              type="chip"
                              status={item?.gpsTrackerStatus}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-none items-center text-lg font-bold">
                    <SlArrowRight />
                  </div>
                </div>
              </NeoCard>
            </div>
          ))
      )}
    </>
  );
};

const VehicleAvatar: React.FC<VehicleAvatarProps> = ({
  url,
  trackerStatus,
  vehicleIndex
}) => {
  return (
    <>
      <div className="relative w-max">
        <Badge
          content={vehicleIndex}
          color={
            trackerStatus == GPSTrackerStatus.MOVING
              ? "success"
              : trackerStatus == GPSTrackerStatus.OFFLINE
                ? "danger"
                : "warning"
          }
          shape="circle"
          showOutline={false}
        >
          <Image
            src={url}
            alt="avatar"
            className="h-14 w-14 rounded-full border"
          />
        </Badge>
        {/* <div
          className={`absolute right-0.5 top-0.5 z-[10] h-6 w-6 rounded-full border-1 border-white text-center shadow-md`}
          style={{
            backgroundColor:
              trackerStatus == GPSTrackerStatus.MOVING
                ? "rgb(var(--color-green))"
                : trackerStatus == GPSTrackerStatus.OFFLINE
                  ? "rgb(var(--color-red))"
                  : "rgb(var(--color-yellow))"
          }}
        >
          
        </div> */}
      </div>
    </>
  );
};

const SplitLabel: React.FC<SplitLabelProps> = ({
  startValue,
  endValue,
  type,
  status
}) => {
  return (
    <div className="flex items-center whitespace-nowrap">
      {startValue && (
        <span className="text-xs font-extrabold">{startValue}</span>
      )}
      {endValue && (
        <span className="flex items-center text-xs text-neo-gray">
          <span className="mx-1">|</span>
          {type === "chip" ? (
            <NeoChips
              variant="light"
              radius="sm"
              color={
                status === GPSTrackerStatus.MOVING
                  ? "success"
                  : status === GPSTrackerStatus.STATIONARY
                    ? "warning"
                    : status === GPSTrackerStatus.OFFLINE
                      ? "danger"
                      : "default"
              }
            >
              {endValue}
            </NeoChips>
          ) : (
            <span className="text-xs">{endValue}</span>
          )}
        </span>
      )}
    </div>
  );
};
