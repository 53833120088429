"use client";
import {NeoModal} from "@/components/@modal/neo.modal";
import {useNeoContext} from "@/context/neo/neo.context";
import {useSidebarContext} from "@/context/sidebar/sidebar.context";
import {DashboardReport} from "@/data";
import {convertKMTOM, convertMTOKM} from "@/libs/helper";
import {NeoDashboardService} from "@/service/dashboard.service";
import type {NextPage} from "next";
import {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import BusAttendanceWidget from "./widgets/Widget-BusAttendance";
import CampusAttendanceWidget from "./widgets/Widget-CampusAttendance";
import DistanceDeviationWidget from "./widgets/Widget-DistanceDeviation";
import DistanceDrivenWidget from "./widgets/Widget-DistanceDriven";
import InsuranceSummaryWidget from "./widgets/Widget-InsuranceSummary";
import PermitSummaryWidget from "./widgets/Widget-PermitSummary";
import SpeedDeviationWidget from "./widgets/Widget-SpeedDeviation";
import TripStatusWidget from "./widgets/Widget-TripStatus";
import VehicleStatusWidget from "./widgets/Widget-VehicleStatus";
const dashbordreports = DashboardReport as DASHBOARDREPORTCONFIG;

export const NeoTrackDashboard: NextPage = function () {
  const {filterContext, clients, offset} = useNeoContext();
  const {isCollapsed} = useSidebarContext();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instituteuserids, setinstituteuserids] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalData, setModalData] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [dashboardReports, setDashboardReports] = useState(null);
  const [deviationlimit, setdeviationlimit] = useState([]);
  const [widgetlists, setwidgetlists] = useState<number[]>([]);
  const [widgets, setwidgets] = useState<number[]>([]);
  const [fleetSeries, setFleetSeries] = useState([0, 0, 0]);
  const [fleetChartKey, setFleetChartKey] = useState<number>(0);
  const fleet_Options: any = {
    labels: ["Moving", "Stationary", "Offline"],
    colors: ["#349C42", "#164EA4", "#FFB231"],
    dataLabels: {
      enabled: false,
      formatter: (
        val: number,
        {seriesIndex, w}: {seriesIndex: number; w: any}
      ) => w.config.series[seriesIndex]
    },
    tooltip: {
      y: {
        formatter: (val: NumberConstructor) => val
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "18px",
              fontWeight: 600
            },
            total: {
              label: "Vehicles",
              showAlways: true,
              show: true,
              fontSize: "18px",
              fontWeight: 600,
              color: isDarkMode ? "#FFC727" : "#000000"
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      formatter: function (seriesName: any, {seriesIndex}: any) {
        var value = fleetSeries[seriesIndex];
        return `${seriesName}: ${value}`;
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    responsive: [
      {
        breakpoint: 250,
        options: {
          chart: {
            width: 250
          }
        }
      }
    ]
  };
  const [fleetOptions, setFleetOptions] = useState(fleet_Options);
  const [tripDatas, setTripDatas] = useState({
    total: 0,
    inprogress: 0,
    completed: 0
  });
  const [speedDeviation, setSpeedDeviation] = useState({
    total: 0,
    deviationvehicles: 0,
    deviationcount: 0,
    threshold: 0
  });
  const [distanceDriven, setDistanceDriven] = useState({
    total: 0,
    scheduled: 0,
    unscheduled: 0,
    scheduledpercent: "0%",
    unscheduledpercent: "0%"
  });
  const [distanceDeviation, setDistanceDeviation] = useState({
    total: 0,
    deviationvehicles: 0,
    deviationcount: 0,
    threshold: 0,
    deviatepercent: "0%"
  });
  const [busAttendance, setBusAttendance] = useState({
    total: 0,
    present: 0,
    absent: 0
  });
  const [campusAttendance, setCampusAttendance] = useState({
    total: 0,
    present: 0,
    absent: 0,
    presentpercent: "0%",
    absentpercent: "0%"
  });
  const [speedLimit, setSpeedLimit] = useState({
    min: 0,
    max: 0,
    value: 0
  });
  const [distanceLimit, setDistanceLimit] = useState({
    min: 0,
    max: 0,
    value: 0
  });
  const [insurancedatas, setInsuranceDatas] = useState({
    range: [],
    upToDate: false,
    total: 0,
    active: 0,
    expired: 0,
    noData: 0
  });
  const [insuranceSeries, setInsuranceSeries] = useState([0, 0, 0]);
  const [insuranceChartKey, setInsuranceChartKey] = useState<number>(0);
  const insurance_Options: any = {
    labels: ["Active Policies", "Expired Policies", "No Data"],
    colors: ["#349C42", "#FF5555", "#A3A4A4"],
    dataLabels: {
      enabled: false,
      formatter: (
        val: number,
        {seriesIndex, w}: {seriesIndex: number; w: any}
      ) => w.config.series[seriesIndex]
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val.toFixed(2)}%`; // Shows value with two decimal places and percentage symbol
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "14px",
              fontWeight: 600,
              offsetY: -5
            },
            total: {
              label: "Vehicles",
              showAlways: true,
              show: true,
              fontSize: "14px",
              fontWeight: 600,
              color: isDarkMode ? "#FFC727" : "#000000",
              formatter: function () {
                return `${insurancedatas.total}`; //problem
              },
              offsetY: 10
            }
          }
        }
      }
    },
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
      formatter: function (seriesName: any, {seriesIndex}: any) {
        var value = insuranceSeries[seriesIndex];
        return `${seriesName}: ${value}`;
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    responsive: [
      {
        breakpoint: 250,
        options: {
          chart: {
            width: 250
          }
        }
      }
    ]
  };
  const [insuranceOptions, setInsuranceOptions] = useState(insurance_Options);
  const [permitdatas, setPermitDatas] = useState({
    range: [],
    upToDate: false,
    total: 0,
    active: 0,
    expired: 0,
    noData: 0,
    permitpercentage: "0%"
  });
  const widgetnames: Record<number, string> = {
    1: "Vehicle Status",
    2: "Trip Status",
    3: "Bus Attendance",
    4: "Campus Attendance",
    5: "Speed Deviation",
    6: "Distance Deviation",
    7: "Distance Driven",
    8: "Insurance Summary",
    9: "Permit Summary"
  };
  const [widgetloadings, setwidgetloadings] = useState({
    loading_widget_1: true,
    loading_widget_2: true,
    loading_widget_3: true,
    loading_widget_4: true,
    loading_widget_5: true,
    loading_widget_6: true,
    loading_widget_7: true,
    loading_widget_8: true,
    loading_widget_9: true
  });
  const modalOpenRef = useRef(modalOpen);
  const instituteuseridsRef = useRef(instituteuserids);
  const widgetlist: number[] = [1, 2, 7, 5, 6, 3, 4, 8, 9];
  const fetchdatas = async () => {
    /*
      VEHICLES_STATUS_WIDGET = 1;
      TRIPS_STATUS_WIDGET = 2;
      BUS_ATTENDANCE_WIDGET = 3;
      CAMPUS_ATTENDANCE_WIDGET = 4;
      SPEED_DEVIATION_WIDGET = 5;
      DISTANCE_DEVIATION_WIDGET = 6;
      DISTANCE_DRIVEN_WIDGET = 7;
    */
    if (instituteuseridsRef.current.length > 0) {
      setisOpen(false);
      setModalOpen(false);
      setLoading(false);
      const size = 3;
      const widgetgroups = [];
      for (let i = 0; i < widgets.length; i += size) {
        widgetgroups.push(widgets.slice(i, i + size));
      }
      for (const widgetgroup of widgetgroups) {
        const requestData = {
          offset,
          branch: instituteuseridsRef.current,
          widgets: widgetgroup
        };

        const response =
          await NeoDashboardService.onGetDashboardDatas(requestData);
        if (response?.data?.status !== "success") {
          toast.error(response?.data?.message);
          continue;
        }
        const responsedata = response.data.data;
        widgetgroup.forEach((widgetId: number) => {
          switch (widgetId) {
            case 1: // Fleet Data
              if (responsedata?.fleet) {
                const {moving, stationary, offline} = responsedata.fleet;
                setFleetSeries(prevFleetSeries => {
                  if (
                    prevFleetSeries[0] === moving &&
                    prevFleetSeries[1] === stationary &&
                    prevFleetSeries[2] === offline
                  ) {
                    return prevFleetSeries; // No change
                  }
                  return [moving, stationary, offline];
                });
              }

              setwidgetloadings(prev => ({...prev, loading_widget_1: false}));
              break;

            case 2: // Trip Data
              if (responsedata?.trips) {
                const {total, inProgress, completed} = responsedata.trips;
                setTripDatas(prev => ({
                  ...prev,
                  total,
                  inprogress: inProgress,
                  completed
                }));
              }
              setwidgetloadings(prev => ({...prev, loading_widget_2: false}));
              break;

            case 3: // Bus Attendance
              var totalbusattendance: any = 0;
              var buspresent = 0;
              var busabsent = 0;
              if (responsedata?.busAttendance) {
                const {total, present, absent} = responsedata.busAttendance;
                totalbusattendance = total;
                buspresent = present;
                busabsent = absent;
              }
              setBusAttendance((prevData: any) => ({
                ...prevData,
                ["total"]: totalbusattendance,
                ["present"]: buspresent,
                ["absent"]: busabsent
              }));
              setwidgetloadings(prev => ({...prev, loading_widget_3: false}));
              break;

            case 4: // Campus Attendance
              var totalcampus: any = 0;
              var campuspresent = 0;
              var campusabsent = 0;
              var presentpercent = "0%";
              var absentpercent = "0%";
              if (responsedata?.campusAttendance) {
                const campusattendance = responsedata?.campusAttendance;
                totalcampus = campusattendance.total;
                campuspresent = campusattendance.present;
                campusabsent = campusattendance.absent;
                if (totalcampus > 0) {
                  presentpercent = calculatepercentage(
                    campuspresent,
                    totalcampus
                  );
                  absentpercent = calculatepercentage(
                    campusabsent,
                    totalcampus
                  );
                }
              }
              setCampusAttendance((prevData: any) => ({
                ...prevData,
                ["total"]: totalcampus,
                ["present"]: campuspresent,
                ["absent"]: campusabsent,
                ["presentpercent"]: presentpercent,
                ["absentpercent"]: absentpercent
              }));
              setwidgetloadings(prev => ({...prev, loading_widget_4: false}));
              break;

            case 5: // Speed Deviation
              if (responsedata?.vehicleSpeedDeviation) {
                const {total, deviationVehicles, deviationCount, threshold} =
                  responsedata.vehicleSpeedDeviation;
                setSpeedDeviation(prev => ({
                  ...prev,
                  total,
                  deviationvehicles: deviationVehicles,
                  deviationcount: deviationCount,
                  threshold
                }));
              }
              setwidgetloadings(prev => ({...prev, loading_widget_5: false}));
              break;

            case 6: // Distance Deviation
              if (responsedata?.vehicleDistanceDeviation) {
                const {total, deviationVehicles, deviationCount, threshold} =
                  responsedata.vehicleDistanceDeviation;
                const distanceThreshold = parseFloat(convertMTOKM(threshold));
                const deviatePercent = calculatepercentage(
                  deviationVehicles,
                  total
                );
                setDistanceDeviation(prev => ({
                  ...prev,
                  total,
                  deviationvehicles: deviationVehicles,
                  deviationcount: deviationCount,
                  threshold: distanceThreshold,
                  deviatepercent: deviatePercent
                }));
                deviationchartdatas(deviatePercent);
              }
              setwidgetloadings(prev => ({...prev, loading_widget_6: false}));
              break;

            case 7: // Distance Driven
              if (responsedata?.distanceDriven) {
                const {total, scheduled, unScheduled} =
                  responsedata.distanceDriven;
                let scheduledpercent = "0%";
                let unscheduledpercent = "0%";
                if (total && scheduled) {
                  scheduledpercent = calculatepercentage(scheduled, total);
                }
                if (total && unScheduled) {
                  unscheduledpercent = calculatepercentage(unScheduled, total);
                }
                setDistanceDriven((prevData: any) => ({
                  ...prevData,
                  ["total"]: convertMTOKM(total),
                  ["scheduled"]: convertMTOKM(scheduled),
                  ["unscheduled"]: convertMTOKM(unScheduled),
                  ["scheduledpercent"]: scheduledpercent,
                  ["unscheduledpercent"]: unscheduledpercent
                }));
              }
              setwidgetloadings(prev => ({...prev, loading_widget_7: false}));
              break;

            case 8: // Insurances Summary
              if (responsedata?.insuranceSummary) {
                const {total, active, expired, noData, range, upToDate} =
                  responsedata.insuranceSummary;
                // Insurace % calculation start
                const totalVehicle = total;
                const activePer = active
                  ? parseFloat(((active / totalVehicle) * 100).toFixed(2))
                  : 0;

                const expiredPer = expired
                  ? parseFloat(((expired / totalVehicle) * 100).toFixed(2))
                  : 0;

                const noDataPer = noData
                  ? parseFloat(((noData / totalVehicle) * 100).toFixed(2))
                  : 0;
                //Insurance % calculation end
                setInsuranceSeries(prevInsuranceSeries => {
                  if (
                    prevInsuranceSeries[0] === activePer &&
                    prevInsuranceSeries[1] === expiredPer &&
                    prevInsuranceSeries[2] === noDataPer
                  ) {
                    return prevInsuranceSeries;
                  }
                  return [activePer, expiredPer, noDataPer];
                });
                setInsuranceDatas((prevData: any) => ({
                  ...prevData,
                  ["range"]: range,
                  ["upToDate"]: upToDate,
                  ["total"]: total,
                  ["active"]: active,
                  ["expired"]: expired,
                  ["noData"]: noData
                }));
              }
              setwidgetloadings(prev => ({...prev, loading_widget_8: false}));
              break;

            case 9: // Permit Summary
              if (responsedata?.permitSummary) {
                const {total, active, expired, noData, range, upToDate} =
                  responsedata.permitSummary;
                const permit_totla = active + expired;
                const permitpercentage = calculatepercentage(
                  active,
                  permit_totla
                );
                setPermitDatas((prevData: any) => ({
                  ...prevData,
                  ["range"]: range,
                  ["upToDate"]: upToDate,
                  ["total"]: total,
                  ["active"]: active,
                  ["expired"]: expired,
                  ["noData"]: noData,
                  ["permitpercentage"]: permitpercentage
                }));
              }
              setwidgetloadings(prev => ({...prev, loading_widget_9: false}));
              break;
            default:
              break;
          }
        });
      }
      setwidgets(widgetlists);
    }
  };

  const openPopup = (reportname: popupParams) => {
    const report = dashboardReports?.[reportname.title]?.[0];
    if (report && dashbordreports?.[0]) {
      const updatedDashReports = [...dashbordreports];
      (updatedDashReports[0] as any).activeReport = report;
      const menudata = updatedDashReports[0] as any;
      menudata.dashboardfilter = {instituteuserids: instituteuserids};
      if (reportname?.filter) {
        menudata.dashboardfilter["status"] = reportname.filter.status;
      }
      setModalData(menudata);
      setModalName("dashboardreport");
      setModalOpen(true);
    }
  };

  const handleDashboardSubmit = (requestdatas: any) => {
    if (modalName == "dashboardfilter") {
      setModalData(requestdatas);
      setinstituteuserids(requestdatas);
      instituteuseridsRef.current = requestdatas;
    } else if (modalName == "dashboardsetting") {
      /*type :   1 - Speed(kmph) , 2 - Distance(meter)*/
      const speedrequestdata = requestdatas.speedLimit;
      const distancerequestdata = requestdatas.distanceLimit;
      const speedlimitdata = speedrequestdata.value;
      const distancelimitdata = convertKMTOM(distancerequestdata.value);

      const requestdata: any = [];

      /*Speed Limit Data*/
      const speedsettingdata: any = {type: 1, value: speedlimitdata};
      requestdata.push(speedsettingdata);

      /*Distance Limit Data*/
      const distancesettingdata: any = {type: 2, value: distancelimitdata};
      requestdata.push(distancesettingdata);
      updateusersetting(requestdata);
    } else if (modalName == "widgetordering") {
      const orderingids: any = [];
      requestdatas.map((widgetdata: any, index: number) => {
        orderingids.push(widgetdata.key);
      });
      setwidgetlists(orderingids);
      setwidgets(orderingids);
      if (filterContext.client.institutionUserId) {
        const storagewidget =
          "storagewidget_" + filterContext.client.institutionUserId;
        localStorage.setItem(storagewidget, orderingids);
      }

      setModalOpen(false);
    }
  };

  const toggleMenu = () => {
    setisOpen(!isOpen);
  };

  const institutionfilter = () => {
    var requestdata: any = [];
    setModalData(requestdata);
    setModalData(prevData => {
      const updatedData = prevData.length === 0 ? instituteuserids : prevData;
      return updatedData;
    });
    setModalName("dashboardfilter");
    setModalOpen(true);
  };

  const updateusersetting = async (requestdata: any) => {
    const requestdatas = {settings: requestdata};
    setLoading(true);
    await NeoDashboardService.onSaveUserSetting(requestdatas)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          toast.success(response?.data?.message);
          setModalOpen(false);
          fetchdatas();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch(err => {
        toast.error("Sorry!, We have technical issue please try again later");
      })
      .finally(() => {
        //setLoading(false);
      });
  };

  const usersetting = async () => {
    setModalName("dashboardsetting");
    await NeoDashboardService.onGetSettingDatas().then((response: any) => {
      if (response.data) {
        var responsedatas = response.data;
        if (responsedatas?.status == "success") {
          if (responsedatas?.data) {
            const responsedata = responsedatas?.data;
            if (responsedata?.speed) {
              const speed = responsedata?.speed;
              var speedmin = speed.min;
              var speedmax = speed.max;
              var speedvalue = speed.value;
              setSpeedLimit((prevData: any) => ({
                ...prevData,
                ["min"]: speedmin,
                ["max"]: speedmax,
                ["value"]: speedvalue
              }));
            }
            if (responsedata?.distance) {
              const distance = responsedata?.distance;
              var distancemin: any = parseFloat(convertMTOKM(distance.min));
              var distancemax: any = parseFloat(convertMTOKM(distance.max));
              var distancevalue: any = parseFloat(convertMTOKM(distance.value));
              setDistanceLimit((prevData: any) => ({
                ...prevData,
                ["min"]: distancemin,
                ["max"]: distancemax,
                ["value"]: distancevalue
              }));
            }
          } else {
            toast.error(response?.message);
          }
        } else {
          toast.error(response?.message);
        }
      }
    });
  };

  const ordering = () => {
    const widgetorders: any = [];
    const widgetids: any = widgetlists;
    widgetids.map((widgetid: any, index: number) => {
      var orderdata = {key: widgetid, name: widgetnames[widgetid]};
      widgetorders.push(orderdata);
    });
    setModalData(widgetorders);
    setModalName("widgetordering");
    setModalOpen(true);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);
    const handleChange = (event: MediaQueryListEvent) => {
      setIsDarkMode(event.matches);
    };
    mediaQuery.addEventListener("change", handleChange);
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  useEffect(() => {
    /*
      VEHICLES_STATUS_WIDGET = 1;
      TRIPS_STATUS_WIDGET = 2;
      BUS_ATTENDANCE_WIDGET = 3;
      CAMPUS_ATTENDANCE_WIDGET = 4;
      SPEED_DEVIATION_WIDGET = 5;
      DISTANCE_DEVIATION_WIDGET = 6;
      DISTANCE_DRIVEN_WIDGET = 7;
    */
    if (filterContext.client.institutionUserId) {
      const storagewidget =
        "storagewidget_" + filterContext.client.institutionUserId;
      const storagewidgets = localStorage.getItem(storagewidget);
      if (storagewidgets) {
        const storagewidgetids: number[] = storagewidgets
          .split(",")
          .map(Number);
        if (storagewidgetids.length != widgetlist.length) {
          const widgetlistids: any = widgetlist;
          localStorage.setItem(storagewidget, widgetlistids);
          setwidgetlists(widgetlist);
          setwidgets(widgetlist);
        } else {
          setwidgetlists(storagewidgetids);
          setwidgets(storagewidgetids);
        }
      } else {
        setwidgetlists(widgetlist);
        setwidgets(widgetlist);
      }
    }

    if (dashbordreports) {
      const menudatas: any = {};
      dashbordreports.forEach((reportdata: any) => {
        if (reportdata?.menus) {
          reportdata?.menus.forEach((menudata: any) => {
            if (!menudatas[menudata.menu]) {
              menudatas[menudata.menu] = [];
            }
            menudata.dashboardfilter = {instituteuserids: instituteuserids};
            menudatas[menudata.menu].push(menudata);
          });
        }
      });
      setDashboardReports(menudatas);
    }

    const instituteusers: any = [];
    if (clients) {
      clients.forEach((e: any, index: number) => {
        instituteusers.push(e.institutionUserId);
      });
      if (instituteusers.length > 0) {
        instituteuseridsRef.current = instituteusers;
        setinstituteuserids(instituteusers);
      }
    }
  }, [clients, filterContext]);

  useEffect(() => {
    //setInsuranceSeries(fleetSeries);
    setFleetOptions((prevOptions: any) => ({
      ...prevOptions,
      plotOptions: {
        ...prevOptions.plotOptions,
        pie: {
          ...prevOptions.plotOptions?.pie,
          donut: {
            ...prevOptions.plotOptions?.pie?.donut,
            labels: {
              ...prevOptions.plotOptions?.pie?.donut?.labels,
              total: {
                ...prevOptions.plotOptions?.pie?.donut?.labels?.total,
                color: isDarkMode ? "#FFFFFF" : "#000000"
              },
              value: {
                ...prevOptions.plotOptions?.pie?.donut?.labels?.value,
                color: isDarkMode ? "#FFFFFF" : "#000000"
              }
            }
          }
        }
      },
      legend: {
        ...prevOptions.legend,
        formatter: function (seriesName: any, {seriesIndex}: any) {
          var value = fleetSeries[seriesIndex];
          return `${seriesName}: ${value}`;
        },
        labels: {
          colors: isDarkMode ? "#FFFFFF" : "#000000"
        }
      }
    }));
    setFleetChartKey(prevKey => prevKey + 1);
  }, [fleetSeries, isDarkMode]);

  useEffect(() => {
    setInsuranceOptions((prevOptions: any) => ({
      ...prevOptions,
      plotOptions: {
        ...prevOptions.plotOptions,
        pie: {
          ...prevOptions.plotOptions?.pie,
          donut: {
            ...prevOptions.plotOptions?.pie?.donut,
            labels: {
              ...prevOptions.plotOptions?.pie?.donut?.labels,
              total: {
                ...prevOptions.plotOptions?.pie?.donut?.labels?.total,
                formatter: function () {
                  return `${insurancedatas.total}`; //problem
                },
                color: isDarkMode ? "#FFFFFF" : "#000000"
              },
              value: {
                ...prevOptions.plotOptions?.pie?.donut?.labels?.value,
                color: isDarkMode ? "#FFFFFF" : "#000000"
              }
            }
          }
        }
      },
      legend: {
        ...prevOptions.legend,
        formatter: function (seriesName: any, {seriesIndex}: any) {
          var value = insuranceSeries[seriesIndex];
          return `${seriesName}: ${value}`;
        },
        labels: {
          colors: isDarkMode ? "#FFFFFF" : "#000000"
        }
      }
    }));
    setInsuranceChartKey(prevKey => prevKey + 1);
  }, [insuranceSeries, isDarkMode]);

  useEffect(() => {
    modalOpenRef.current = modalOpen;
    instituteuseridsRef.current = instituteuserids;
  }, [modalOpen, instituteuserids]);

  useEffect(() => {
    setModalData(instituteuserids);
    fetchdatas();
  }, [instituteuserids]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!modalOpenRef.current) {
        fetchdatas();
      }
    }, 60000);
    return () => clearInterval(intervalId);
  }, [fetchdatas]);

  useEffect(() => {
    if (modalName == "dashboardsetting") {
      const widgetids: any = [5, 6];
      setModalData([]);
      const settingdata: any = {
        speedLimit: speedLimit,
        distanceLimit: distanceLimit
      };
      setwidgets(widgetids);
      setModalData(settingdata);
      setModalOpen(true);
    }
  }, [speedLimit, distanceLimit]);

  function calculatepercentage(value: any, total: any) {
    const percentage = ((value / total) * 100).toFixed(2) + "%";
    return percentage;
  }

  function convertPercentageToNumber(percentage: any) {
    const number = parseFloat(percentage.replace("%", "")) / 10;
    return Math.round(number);
  }

  function deviationchartdatas(deviationpercentage: any) {
    var nondeviation = 10;
    var deviationarray: any = [];
    var nondeviationarray: any = [];
    var percentnumber = parseFloat(deviationpercentage.replace("%", ""));
    for (let i = 1; i <= 10; i++) {
      let percentage: any = 0;
      if (percentnumber > 10) {
        percentage = "100%";
        percentnumber -= 10;
      } else if (percentnumber > 0) {
        percentage = `${(percentnumber * 10).toFixed(1)}%`;
        percentnumber = 0;
      }
      deviationarray.push(percentage);
    }
    setdeviationlimit(deviationarray);
  }

  return (
    <>
      <div className="ml-2 items-center">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-2">
            <h5 className="items-start text-lg font-semibold">Dashboard</h5>
            <div className="mr-2 items-end">
              <div className="flex justify-end">
                <img
                  className="mr-2 cursor-pointer"
                  src="/icons/adjustments-vertical.svg"
                  alt=""
                  title="Ordering"
                  onClick={ordering}
                />
                <img
                  className="h-5 w-5 max-w-xs cursor-pointer"
                  src="/icons/filter.svg"
                  alt=""
                  title="Institution"
                  onClick={institutionfilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-1">
        <div className="mt-2 grid grid-cols-1 gap-1 sm:grid-cols-2 lg:grid-cols-3">
          {widgetlists.map(
            (widgetid: any, index: number) =>
              ("widget_" + widgetid === "widget_1" && (
                <VehicleStatusWidget
                  isLoading={widgetloadings.loading_widget_1}
                  fleetChartKey={fleetChartKey}
                  fleetOptions={fleetOptions}
                  fleetSeries={fleetSeries}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_2" && (
                <TripStatusWidget
                  isLoading={widgetloadings.loading_widget_2}
                  tripDatas={tripDatas}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_7" && (
                <DistanceDrivenWidget
                  isLoading={widgetloadings.loading_widget_2}
                  distanceDriven={distanceDriven}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_5" && (
                <SpeedDeviationWidget
                  isLoading={widgetloadings.loading_widget_5}
                  usersetting={usersetting}
                  speedDeviation={speedDeviation}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_6" && (
                <DistanceDeviationWidget
                  isLoading={widgetloadings.loading_widget_6}
                  usersetting={usersetting}
                  distanceDeviation={distanceDeviation}
                  deviationlimit={deviationlimit}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_3" && (
                <BusAttendanceWidget
                  isLoading={widgetloadings.loading_widget_3}
                  busAttendance={busAttendance}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_4" && (
                <CampusAttendanceWidget
                  isLoading={widgetloadings.loading_widget_4}
                  campusAttendance={campusAttendance}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_8" && (
                <InsuranceSummaryWidget
                  isLoading={widgetloadings.loading_widget_8}
                  insuranceChartKey={insuranceChartKey}
                  insuranceOptions={insuranceOptions}
                  insuranceSeries={insuranceSeries}
                  insurancedatas={insurancedatas}
                  openPopup={openPopup}
                />
              )) ||
              ("widget_" + widgetid === "widget_9" && (
                <PermitSummaryWidget
                  isLoading={widgetloadings.loading_widget_9}
                  permitdatas={permitdatas}
                  openPopup={openPopup}
                />
              ))
          )}
        </div>
      </div>
      <NeoModal
        onOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        actionType={modalName}
        hasActions={false}
        data={modalData}
        onModalSubmit={handleDashboardSubmit}
      ></NeoModal>
    </>
  );
};
