import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="flex items-center justify-center">
      <img
        src="/images/loading-lines.webp"
        alt="Loading..."
        className="h-36 w-36 sm:h-24 sm:w-24"
      />
    </div>
  );
};

export default Loader;
