import {EyeIcon} from "@/components/shared";
import React from "react";
import Loader from "../Loader";

interface TripStatusProps {
  isLoading: boolean;
  tripDatas: any;
  openPopup: (title: popupParams) => void;
}

const TripStatusWidget: React.FC<TripStatusProps> = ({
  isLoading,
  tripDatas,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2 text-center ">
          <div className="grid-cols grid w-full flex-row">
            <div className="flex items-center justify-between p-2">
              <h5 className="text-lg font-semibold dark:text-neo-white">
                Trip Status
              </h5>
              <h5
                className="cursor-pointer pr-1"
                title="View"
                onClick={() => openPopup({title: "Trip Status"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="grid w-full grid-cols-1 gap-2 pt-4 sm:grid-cols-2 lg:grid-cols-3">
              <div className="rounded-2xl border bg-[#000000] p-4 text-[#FFFFFF]">
                <div className="flex items-start justify-center pt-2">
                  <div className="flex items-center">
                    <img
                      className="h-14 w-14 max-w-xs p-3"
                      src="/icons/trip.svg"
                      alt="Trip"
                    />
                  </div>
                </div>
                <h5 className="pt-1 text-sm font-semibold">Total</h5>
                <h5 className="pt-1 text-xl font-semibold">
                  {tripDatas.total}
                </h5>
              </div>
              <div className="rounded-2xl border bg-[#FFC727] p-4 text-[#FFFFFF]">
                <div className="flex items-start justify-center pt-2">
                  <div className="flex items-center">
                    <img
                      className="h-14 w-14 max-w-xs p-3"
                      src="/icons/trip.svg"
                      alt="Trip"
                    />
                  </div>
                </div>
                <h5 className="pt-1 text-sm font-semibold dark:text-neo-black">
                  In Progress
                </h5>
                <h5 className="pt-1 text-xl font-semibold dark:text-neo-black">
                  {tripDatas.inprogress}
                </h5>
              </div>
              <div className="rounded-2xl border bg-[#E5E7EB] p-4">
                <div className="flex items-start justify-center pt-2">
                  <div className="flex items-center">
                    <img
                      className="h-14 w-14 max-w-xs p-3"
                      src="/icons/tick-circle.svg"
                      alt="Trip"
                    />
                  </div>
                </div>
                <h5 className="pt-1 text-sm font-semibold dark:text-neo-black">
                  Completed
                </h5>
                <h5 className="pt-1 text-xl font-semibold dark:text-neo-black">
                  {tripDatas.completed}
                </h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TripStatusWidget;
