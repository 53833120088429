"use client";
import {NeoButton} from "@/components/forms";
import {NeoHeader} from "@/components/shared";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

interface WidgetOrderingProps {
  onSubmit: any;
  orderingdatas: any;
}

export const WidgetOrdering: React.FC<WidgetOrderingProps> = ({
  onSubmit,
  orderingdatas
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [widgetorderingdatas, setWidgetOrderingdatas] = useState([]);
  const {
    reset,
    resetField,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    control,
    setValue,
    setError,
    clearErrors
  } = useForm<any>({
    mode: "all"
  });

  useEffect(() => {
    setWidgetOrderingdatas(orderingdatas);
  }, [orderingdatas]);

  const onSubmitEvent = async (data: any) => {
    onSubmit(widgetorderingdatas);
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.dataTransfer.setData("dragIndex", index.toString());
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Allow drop by preventing default behavior
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    dropIndex: number
  ) => {
    const dragIndex = parseInt(event.dataTransfer.getData("dragIndex"), 10);
    if (dragIndex === dropIndex) return;

    const reorderedData = [...widgetorderingdatas];
    const [draggedItem] = reorderedData.splice(dragIndex, 1); // Remove dragged item
    reorderedData.splice(dropIndex, 0, draggedItem); // Insert at new position

    setWidgetOrderingdatas(reorderedData);
  };

  return (
    <>
      <div className="flex flex-col gap-1 p-2">
        <div className="mb-1 flex-row">
          <NeoHeader message="Widget Ordering"></NeoHeader>
        </div>
        <form
          onSubmit={handleSubmit(event => {
            onSubmitEvent(event);
          })}
        >
          <div className="flex flex-col gap-1 p-2">
            {widgetorderingdatas.map((widgetdata: any, index: number) => (
              <div
                key={widgetdata.key}
                className="flex cursor-move items-center rounded border bg-gray-100 p-2 dark:bg-[#1F2937]"
                draggable
                onDragStart={event => handleDragStart(event, index)}
                onDragOver={handleDragOver}
                onDrop={event => handleDrop(event, index)}
              >
                <img
                  className="mr-2 h-5 w-5"
                  src="/icons/noun-reorder.svg"
                  alt="Reorder"
                  title="Reorder"
                />
                <h5 className="dark:text-neo-white">{widgetdata.name}</h5>
              </div>
            ))}
            <div className="flex justify-center pt-5">
              <NeoButton type="submit" color="yellow" className="text-lg">
                Submit
              </NeoButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
