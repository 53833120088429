type NavItem = {
  title: string;
  href: string;
  icon: any;
};

export const neoSideNav: NavItem[] = [
  {
    title: "Live",
    href: "/live",
    icon: "live"
  },
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: "monitor"
  },
  {
    title: "AI Dashboard",
    href: "/AI",
    icon: "dashboard"
  },
  {
    title: "Vehicles",
    href: "/vehicles",
    icon: "vehicles"
  },
  {
    title: "Drivers",
    href: "/drivers",
    icon: "drivers"
  },
  {
    title: "Users",
    href: "/users",
    icon: "users"
  },
  // {
  //   title: "Routes",
  //   href: "/routes",
  //   icon: "routes"
  // },
  {
    title: "Reports",
    href: "/reports",
    icon: "reports"
  },
  // {
  //   title: "Configuration",
  //   href: "/configuration",
  //   icon: "configuration"
  // },
  // {
  //   title: "Profile",
  //   href: "/profile",
  //   icon: "profile"
  // }

  {
    title: "Announcements",
    href: "/announcements",
    icon: "announcements"
  }
];

export const PartialPageConfig = {
  error: {
    imageUrl: "/images/error.svg",
    head: "500 - Something went wrong",
    message:
      "The server encountered an internal error or misconfiguration and was unable to complete your request",
    btnText: "Reload",
    btnUrl: "#"
  },
  notFound: {
    imageUrl: "/images/not-found.svg",
    head: "404 - Page not found",
    message: "The page you are looking for doesn’t exist or an error occurred.",
    btnText: "Back to home",
    btnUrl: "#"
  }
};
