import {Tooltip} from "@nextui-org/react";

export const NeoPickupDrop: React.FC<any> = ({data}) => {
  return (
    <Tooltip content={data.map((item: any) => item.name).join(", ")}>
      <div className="flex items-center">
        <p>{data[0]?.name}</p>
        {data?.length > 1 ? (
          <div className="ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-gray-200 text-xs dark:text-neo-black">
            +{data?.length - 1}
          </div>
        ) : (
          ""
        )}
      </div>
    </Tooltip>
  );
};
