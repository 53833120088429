"use client";

import {ManageChatModal} from "@/components/@modal/neo-ai-dashboard-chart/manage-chat.modal";
import {useGlobalContext} from "@/context/global-ai/global";
import {useNeoContext} from "@/context/neo/neo.context";
import {AllVehicles} from "@/data/neo-ai-dashboard/chart-configs/chart.config";
import {aidashboardService} from "@/service/aidashboard.service";
import {Modal, ModalBody, ModalContent} from "@nextui-org/react";
import {NextPage} from "next";
import {useEffect, useState} from "react";
import ChartCard from "./chart-card";

interface DashboardComProps {
  id: string;
}

export const AIDashboard: NextPage<DashboardComProps> = function ({id}) {
  const {
    isOpened,
    userId,
    setUserId,
    setChatHistoryList,
    currentItem,
    setCurrentItem,
    setVehiclesList,
    timeZone,
    setTimeZone
  } = useGlobalContext();
  const [chartDataCards, setChartDataCards] = useState<CurrentItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<CurrentItem>(
    {} as CurrentItem
  );
  const {filterContext} = useNeoContext();
  useEffect(() => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    currentTimeZone && setTimeZone(currentTimeZone);
    id
      ? setUserId(id.toString())
      : setUserId(filterContext?.client?.institutionUserId);
  }, [id, filterContext]);
  useEffect(() => {
    id ? setUserId(id.toString()) : setUserId("baHwjzU");
  }, [id]);

  useEffect(() => {
    userId && (fetchData(), fetchVehicleList());
  }, [userId]);

  useEffect(() => {
    if (!isOpened && currentItem.priorityId) {
      setChartDataCards(prev => {
        return prev.map(item => {
          if (item.priorityId === currentItem.priorityId) {
            return {...currentItem};
          }
          return item;
        });
      });
      setCurrentItem({} as CurrentItem);
    }
  }, [isOpened]);

  const fetchData = async () => {
    setIsLoading(true);
    setChatHistoryList([]);
    const response = await aidashboardService.onGetList(userId, timeZone);
    setIsLoading(false);
    if (response.status == 200) {
      const data = response.data as CurrentItem[];
      setChartDataCards(data);
      const setMessageHisList: ChatHistory[] = data.map(x => ({
        priorityId: x.priorityId,
        messageList: []
      }));
      setChatHistoryList(setMessageHisList);
    }
  };

  const fetchVehicleList = async () => {
    const response = await aidashboardService.onGetVehicleList(userId);
    if (response.status == 200) {
      const allOption: dropDownValue = {
        value: AllVehicles.ALL,
        label: "All Vehicles"
      };
      const vehiclesList: dropDownValue[] = [allOption, ...response.data];
      setVehiclesList(vehiclesList);
    }
  };

  return (
    <div className="h-full p-4">
      {/* <div className="grid w-full grid-cols-2 gap-6 pt-2">
        <div className="flex grid-cols-3 gap-6">
          {TripsCardConfig.map((card, index) => (
            <TripsCard
              key={index}
              title={card.title}
              value={card.value}
              color={card.color}
              additionalInfo={
                card.additionalInfo && <div>{card.additionalInfo}</div>
              }
            />
          ))}
        </div>
        <div className="w-3/4">
          <div className=" grid  grid-cols-2 flex-col justify-between rounded-lg bg-blue-600 p-5">
            {AlertConfig.map((stat, index) => (
              <div key={index} className="p-6 text-center">
                <div className="text-3xl font-semibold text-white">
                  {stat.value}
                </div>
                <div className="text-white">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="mt-3 grid grid-cols-3 gap-3">
        {chartDataCards.length == 0 && isLoading && <LoadingChartCards />}
        {chartDataCards &&
          chartDataCards?.map((x, i) => (
            <ChartCard
              key={i}
              dataSet={x}
              onSelectData={event => setSelectedData(event)}
            ></ChartCard>
          ))}
      </div>

      {/* Modal */}
      <Modal
        classNames={{
          base: "min-w-[70vw] min-h-[80vh] max-h-[80vh]",
          body: "py-0 px-0"
        }}
        isOpen={isOpened}
        hideCloseButton
      >
        <ModalContent>
          {() => (
            <ModalBody>
              <ManageChatModal dataSet={selectedData}></ManageChatModal>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AIDashboard;

const LoadingChartCards = () => {
  return (
    <>
      {[1, 2, 3].map(x => (
        <div
          key={x}
          className="animate-pulse rounded-lg bg-white p-4 shadow-md"
        >
          <div className="mb-2 h-4 w-2/3 rounded bg-gray-300"></div>
          <div className="mb-2 h-8 w-full rounded bg-gray-200"></div>
          <div className="mb-3 h-8 w-full rounded bg-gray-200"></div>
          <div className="mb-4 h-48 w-3/4 rounded-md bg-gray-200"></div>
          <div className="mb-2 h-8 w-2/5 rounded bg-gray-300"></div>
          <div className="mb-2 h-8 w-5/6 rounded bg-gray-200"></div>
          <div className="h-8 w-full rounded bg-gray-200"></div>
        </div>
      ))}
    </>
  );
};
