"use client";
import {useGlobalContext} from "@/context/global-ai/global";
import {
  AllVehicles,
  SenderTypes
} from "@/data/neo-ai-dashboard/chart-configs/chart.config";
import {ChatDefaultQuestions} from "@/data/neo-ai-dashboard/dashboard/dashboard-config";
import {aidashboardService} from "@/service/aidashboard.service";
import {Select, SelectItem} from "@nextui-org/react";
import React, {useEffect, useRef, useState} from "react";
import {IoMdCloseCircleOutline, IoMdSend} from "react-icons/io";
import {ChatBody} from "./chatBody";

interface ChatPanelProps {
  data: CurrentItem;
  onUpdate: (data: CurrentItem) => void;
  onSetInsights: (value: string) => void;
}

export const ChatPanel: React.FC<ChatPanelProps> = ({
  data,
  onUpdate,
  onSetInsights
}) => {
  const {
    isOpened,
    userId,
    chatHistoryList,
    setChatHistoryList,
    setIsOpened,
    vehiclesList,
    timeZone
  } = useGlobalContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [sendTrigger, setSendTrigger] = useState<boolean>(false);
  const chatBody = useRef<HTMLDivElement>(null);
  const [currentPriorityId, setCurrentPriorityId] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState<string | null>(null);
  const [selectedData, setSelectedData] = useState<CurrentItem>(
    data as CurrentItem
  );

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (isOpened && !currentPriorityId) {
      setCurrentPriorityId(selectedData.priorityId);
      setMessages(
        chatHistoryList.find(x => x.priorityId === selectedData.priorityId)
          ?.messageList || []
      );
    }

    if (!isOpened || (isOpened && currentPriorityId)) {
      setChatHistoryList(prev => {
        return prev.map(chatHistory => {
          if (chatHistory.priorityId === currentPriorityId) {
            return {
              ...chatHistory,
              messageList: messages
            };
          }
          return chatHistory;
        });
      });
      setMessages([]);
      setCurrentPriorityId(null);
    }
  }, [isOpened, selectedData?.priorityId]);

  // Automatically scroll to the last message
  const scrollToBottom = () => {
    if (chatBody != undefined) {
      setTimeout(() => {
        const maxScroll = chatBody?.current?.scrollHeight;
        chatBody?.current?.scrollTo({
          top: maxScroll,
          behavior: "smooth"
        });
      }, 100);
    }
  };

  useEffect(() => {
    if (sendTrigger) {
      sendMessage();
      setSendTrigger(false);
      if (
        selectedData.chatResponses &&
        selectedData.chatResponses?.length > 0
      ) {
        setSelectedData(prev => ({
          ...prev,
          chatResponses: [],
          isComplete: true
        }));
      }
    }
  }, [sendTrigger]);

  const sendMessage = async () => {
    setIsLoading(true);
    try {
      onSetMessage("Loading...", SenderTypes.BOT);
      onSetInsights("");
      const response = await aidashboardService.onGetData(
        userId,
        currentQuestion,
        selectedData,
        timeZone
      );
      const result = response.data as CurrentItem;
      if (response.status == 200) {
        if (result.isComplete) {
          setSelectedData(result);
          onUpdate(result); // we update the data into manage-chat.modal
          setMessages(currentMessages => {
            const updatedMessages = [...currentMessages];
            updatedMessages[updatedMessages.length - 1].message =
              result.message || "Updated";
            return updatedMessages;
          });
          if (result.outputType === "CHART" && result.isInsights) {
            const insightsResult = await aidashboardService.onGetInsights(
              userId,
              result.promptId
            );
            onSetInsights(insightsResult.data?.insights || "");
          }
        } else {
          setMessages(prevMessages => prevMessages.slice(0, -1));
          const chatResponses = onSetInComQuesConfig(
            result.additionalQuestions || []
          );
          setSelectedData(prev => ({
            ...prev,
            promptId: result.promptId,
            isComplete: result.isComplete,
            chatResponses: chatResponses
          }));
        }
      }
    } catch (err: any) {
      const message = err?.response?.data?.message || "Try again later";
      setMessages(currentMessages => {
        const updatedMessages = [...currentMessages];
        updatedMessages[updatedMessages.length - 1].message = message;
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  // If the user asks an incomplete question, the backend team will return additional questions.
  // We will display them one by one.
  const onSetInComQuesConfig = (
    questionList: ChatResponses[]
  ): ChatResponses[] => {
    const listItems: ChatResponses[] = questionList.map((x, i) => ({
      answer: "",
      question: x.question,
      answerType: x.answerType
    }));
    setSelectedData(prev => ({
      ...prev,
      chatResponses: listItems,
      currentQuestionIndex: 0
    }));
    if (listItems.length > 0) {
      const set = listItems[0];
      onSetMessage(set.question, SenderTypes.BOT, set.answerType);
    }
    return listItems;
  };

  //we Added the User & Bot message in this function
  const onSetMessage = (
    message: string,
    sender: SenderTypes,
    answerType: answerType = "TEXT"
  ) => {
    const set: Message = {
      id: messages.length + 1,
      message: message,
      sender: sender,
      answerType: answerType
    };
    setMessages(currentMessages => [...currentMessages, set]);
  };

  // Handle sending the message. Before sending, check if there are any additional questions.
  // If there are none, call the API directly.
  const handleSentMessage = () => {
    if (input.length === 0 || isLoading) {
      return;
    }
    onSetMessage(input, SenderTypes.USER);
    setSendTrigger(true);
    if (
      !selectedData.isComplete &&
      selectedData.chatResponses &&
      selectedData.chatResponses?.length > 0
    ) {
      const index: number = selectedData.currentQuestionIndex || 0;
      const unansweredQuestions = selectedData?.chatResponses.filter(
        x => !x.answer
      ).length;
      if (unansweredQuestions > 0) {
        setSelectedData(prev => ({
          ...prev,
          chatResponses: prev.chatResponses?.map((response, i) =>
            i === index ? {...response, answer: input} : response
          ),
          currentQuestionIndex: index + 1
        }));
        setSendTrigger(false);
        if (unansweredQuestions > 1) {
          const set = selectedData?.chatResponses[index + 1];
          onSetMessage(set.question, SenderTypes.BOT, set.answerType);
        } else {
          setSendTrigger(true);
        }
      }
    } else {
      setCurrentQuestion(input);
    }
    setInput("");
  };

  const handleSelectVehicles = (value: string) => {
    value.includes(AllVehicles.ALL)
      ? setInput(AllVehicles.ALL)
      : setInput(value);
  };

  return (
    <div className="h-full border-s">
      <div className="grid grid-cols-1 gap-3 bg-black p-3 text-white">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="h-14 w-14 rounded-full bg-white p-2">
              <img
                className="h-full w-full object-contain"
                src="/images/IconLogo.svg"
                alt="boticon"
              />
            </div>
            <h1 className="ms-2 text-3xl font-bold">NeoBot</h1>
          </div>
          <IoMdCloseCircleOutline
            onClick={() => setIsOpened(false)}
            className="!cursor-pointer text-2xl"
          />
        </div>
        <label htmlFor="disc">
          A live chat interface that allows for seamless, natural communication
          and connection.
        </label>
      </div>
      <div
        ref={chatBody}
        className="h-[calc(80vh-228px)] overflow-hidden overflow-y-auto p-5"
      >
        {messages.length > 0 ? (
          <ChatBody isLoading={isLoading} messages={messages} />
        ) : (
          <div className={`grid h-full grid-cols-1 justify-between`}>
            <div className="flex flex-col items-center justify-center">
              <img
                className="h-16 w-16 object-contain"
                src="/images/IconLogo.svg"
                alt="boticon"
              />
              <label className="block text-center text-2xl font-bold">
                How can I Assist you today?
              </label>
            </div>
            <div className="flex flex-col items-center justify-end">
              {ChatDefaultQuestions.map((x: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    setInput(x);
                  }}
                  className="mt-2 w-full cursor-pointer rounded-lg border border-[#717171] bg-white p-2 text-sm font-normal"
                >
                  {x}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="h-20 p-2">
        <div className="flex h-full w-full items-center justify-between rounded-md bg-gray-100 px-4 py-2">
          {/* <CiFaceSmile className="w-8 h-8" /> */}
          {messages.length > 0 &&
          messages[messages.length - 1].answerType == "SELECT" ? (
            <Select
              placeholder="Select an Vehicles"
              selectionMode="multiple"
              color="primary"
              selectedKeys={input.length > 0 ? input.split(",") : []}
              className="me-2 max-w-[85%]"
              onChange={e => handleSelectVehicles(e.target.value)}
              aria-label="Select Vehicles"
            >
              {(vehiclesList ?? []).map(x => (
                <SelectItem key={x?.value}>{x?.label}</SelectItem>
              ))}
            </Select>
          ) : (
            <textarea
              placeholder="Reply..."
              className="placeholder-center h-full w-[90%] resize-none rounded-md border-none p-1 leading-tight outline-none"
              rows={1}
              value={input}
              onChange={event => {
                const textarea = event.target;
                textarea.rows = 1;
                const computedRows = Math.min(textarea.scrollHeight / 24, 3); // Assuming each row is 24px tall
                textarea.rows = computedRows;
                setInput(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleSentMessage();
                }
              }}
            />
          )}
          <IoMdSend
            className="text-black-500 h-8 w-8 cursor-pointer"
            onClick={handleSentMessage}
          />
        </div>
      </div>
    </div>
  );
};
