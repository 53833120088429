"use client";
import {axiosInstance} from "@/app/api";

const onGetInstVehiclesandMsgs = async (instituteId: number): Promise<any> => {
  const body = {institute_id: instituteId};
  var AnnouncementMsgs = "";
  var vehicledatas = "";
  return await axiosInstance()
    .post(`/api/v2/announcements`, body)
    .then((response: any) => {
      if (response?.data?.code === 200) {
        if (response?.data?.data) {
          const responsedatas = response?.data?.data;
          if (responsedatas?.annType) {
            AnnouncementMsgs = responsedatas?.annType;
          }
          if (responsedatas?.details) {
            vehicledatas = responsedatas?.details;
          }
        }
        return {
          AnnouncementDatas: AnnouncementMsgs,
          VehicleDatas: vehicledatas
        };
      } else {
        return {
          AnnouncementDatas: AnnouncementMsgs,
          VehicleDatas: vehicledatas
        };
      }
    })
    .catch((error: any) => {
      return {AnnouncementDatas: AnnouncementMsgs, VehicleDatas: vehicledatas};
    });
};

const sendsnnouncements = async (body: any): Promise<any> => {
  return await axiosInstance()
    .post(`/api/send/announcements`, body)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      throw error;
    });
};

export const AnnouncementService = {
  onGetInstVehiclesandMsgs,
  sendsnnouncements
};
