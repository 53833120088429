"use client";
import {NeoButton, NeoSelect} from "@/components/forms";
import {useNeoContext} from "@/context/neo/neo.context";
import {SelectItem} from "@nextui-org/react";
import {ChangeEvent, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import toast from "react-hot-toast";

interface DashboardFilterProps {
  onSubmit: any;
  searchinstitutes: any;
}

export const DashboardFilter: React.FC<DashboardFilterProps> = ({
  onSubmit,
  searchinstitutes
}) => {
  const {clients} = useNeoContext();
  const [dashboardInstitutes, setDashboardInstitutes] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {isSubmitting, errors}
  } = useForm<any>({
    mode: "onChange"
  });

  useEffect(() => {
    /*let instituteusers:any = [];
    if(clients){
      clients.forEach((e: any, index: number) => {
        instituteusers.push(e.institutionUserId)
      });
    }*/
    if (searchinstitutes.length > 0) {
      setDashboardInstitutes(searchinstitutes);
      instituteUserids();
    }
  }, [clients]);

  const InstitutionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    let userids: any = [];
    const value = e.target.value;
    if (value) {
      userids = value.split(",");
    }
    setDashboardInstitutes(userids);
    instituteUserids();
  };

  const instituteUserids = () => dashboardInstitutes;

  const onSubmitEvent = (event: any) => {
    if (dashboardInstitutes.length > 0) {
      onSubmit(dashboardInstitutes);
    } else {
      toast.error("Please select institution");
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmitEvent(event);
        })}
      >
        <div className="flex flex-col gap-1 p-2">
          <div className="col-span-4 py-2 lg:col-span-1">
            <Controller
              name="institution"
              control={control}
              render={({field}) => (
                <NeoSelect
                  {...field}
                  label="Institution"
                  id="institution"
                  placeholder="Please select institution"
                  selectionMode="multiple"
                  selectedKeys={instituteUserids()}
                  onChange={InstitutionChange}
                >
                  {clients?.map((e: any, index: number) => (
                    <SelectItem
                      key={e.institutionUserId}
                      value={e.institutionUserId}
                    >
                      {e.institutionName}
                    </SelectItem>
                  ))}
                </NeoSelect>
              )}
            />
          </div>
          <div className="flex justify-center">
            <NeoButton type="submit" color="yellow" className="text-lg">
              Submit
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
