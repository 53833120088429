export const DriversTableConfig: NeoTableConfig = {
  title: "Drivers",
  table: {
    columns: [
      {name: "Driver Name", uid: "name", type: "value", sortable: true},
      {name: "Mobile Number", uid: "phone", type: "value"},
      {
        name: "Driving License",
        uid: "licenceNo",
        type: "value"
      },
      {name: "Operator", uid: "operator", type: "value"},
      {name: "Facility", uid: "facility", type: "value"},
      {
        name: "License Expiry",
        uid: "licenceExpiryDt",
        type: "value",
        sortable: true
      },
      {name: "Status", uid: "status", type: "status"},
      {name: "Actions", uid: "actions", type: "actions"}
    ],
    activeColumns: [
      "name",
      "phone",
      "licenceNo",
      "operator",
      "facility",
      "licenceExpiryDt",
      "status",
      "actions"
    ],
    style: {
      height: "h-full"
    },
    meta: {
      id: 3,
      title: "Manage Drivers",
      description: "Total Drivers",
      hasSearch: true,
      hasUpload: true,
      hasDownload: false,
      hasAdd: true,
      hasActions: true,
      hasCheckbox: false,
      hasFilter: false,
      hasFooter: true,
      hasHeader: true,
      hasPagination: true,
      hasCreate: true
    },
    url: {
      page: {
        view: "drivers/${navId}/view/driver-details",
        add: "drivers/new/create/driver",
        edit: "drivers/${navId}/edit/driver"
      },
      api: {
        upload: "/api/drivers/upload",
        download: "/api/drivers/download",
        delete: "/api/drivers/delete",
        get: "/api/drivers/list",
        deactivate: "/api/driver/status/update"
      }
    },
    actions: [
      {
        key: "edit",
        label: "Edit",
        type: "navigate",
        isVisible: true
      },
      // enable for phase 2
      {
        key: "delete",
        label: "Delete",
        type: "modal",
        isVisible: false
      },
      {
        key: "deactivate",
        label: "Deactivate",
        type: "modal",
        isVisible: true
      }
    ]
  }
};
