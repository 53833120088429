"use client";

import {DashboardFilter} from "@/components/@filter/dashboard-filter";
import {
  ContactForm,
  DashboardSetting,
  ProfileForm,
  SmartEdit
} from "@/components/pages";
import {Modal, ModalBody, ModalContent, ModalFooter} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {DeActivateModal, DeleteModal, ResetPasswordModal} from ".";
import {NeoButton} from "../forms";
import {DashboardReport} from "../pages/dashboard/dashboard-report";
import {WidgetOrdering} from "../pages/dashboard/widgetordering";
import {PaymentReminder} from "../pages/live/paymentReminder";
import {ReportUi} from "../pages/reports/report-ui";
import {RfidEdit} from "../pages/users/rfid-edit";
type NeoModalProps = {
  data?: any | null;
  actionType: string | null;
  onOpen: any;
  onClose: any;
  onModalSubmit?: (payload?: any) => void;
  actionText?: string;
  hasActions?: boolean;
  selectedRow?: any;
};

type ModalSize =
  | "4xl"
  | "md"
  | "xs"
  | "sm"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "5xl"
  | "full"
  | undefined;

/**
 * Generic modal dialog for all the popups
 *
 * If you need the buttons to be controlled from the component
 * please pass hasActions as true, and create a function called onModalSubmit
 * and pass them as functional reference
 * If you dont want yes & no button from neo modal design the button inside the component and pass hasActions to false.
 * You dont have to pass the functional refernce for modal submit you only need onclose & onopen
 * For example, please check neo-table where both the usecase are handled
 *
 */
export const NeoModal: React.FC<NeoModalProps> = ({
  actionType,
  onOpen,
  onClose,
  onModalSubmit,
  actionText = "Yes",
  data,
  hasActions = true,
  selectedRow
}) => {
  const [size, setSize] = useState<ModalSize>("md");
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [CloseIcon, setCloseIcon] = useState(false);
  useEffect(() => {
    switch (actionType) {
      case "delete":
        setSize("md");
        setContent(<DeleteModal />);
        break;
      case "smart_edit":
        setSize("2xl");
        setContent(<SmartEdit selectedRow={selectedRow} onClose={onClose} />);
        break;
      case "change-mobile":
        setContent(
          <ProfileForm
            mobileNumber={data?.mobileNumber}
            formType={actionType}
            onClose={onClose}
          />
        );
        break;
      case "change-password":
        setContent(
          <ProfileForm
            mobileNumber={data?.mobileNumber}
            formType={actionType}
            onClose={onClose}
          />
        );
        break;
      case "reset_rfid":
        setSize("4xl");
        setContent(<RfidEdit onClose={onClose} selectedRow={selectedRow} />);
        break;
      case "resetpassword":
        setSize("md");
        setContent(<ResetPasswordModal />);
        break;
      case "deactivate":
        setSize("lg");
        setContent(<DeActivateModal />);
        break;
      case "report":
        setSize("full");
        setContent(<ReportUi reportdata={data} />);
        setCloseIcon(true);
        break;
      case "contactform":
        setSize("lg");
        setContent(<ContactForm />);
        setCloseIcon(true);
        break;
      case "dashboardreport":
        setSize("5xl");
        setContent(<DashboardReport reportdata={data} />);
        setCloseIcon(true);
        break;
      case "dashboardsetting":
        setSize("md");
        setContent(
          <DashboardSetting onSubmit={onModalSubmit} settingdatas={data} />
        );
        setCloseIcon(true);
        break;
      case "dashboardfilter":
        setSize("md");
        setContent(
          <DashboardFilter onSubmit={onModalSubmit} searchinstitutes={data} />
        );
        setCloseIcon(true);
        break;
      case "widgetordering":
        setSize("lg");
        setContent(
          <WidgetOrdering onSubmit={onModalSubmit} orderingdatas={data} />
        );
        setCloseIcon(true);
        break;
      case "paymentReminder":
        setSize("lg");
        setContent(<PaymentReminder data={data} />);
        setCloseIcon(true);
        break;
      default:
        setContent(null);
    }
  }, [actionType, data?.mobileNumber, onClose, onModalSubmit, selectedRow]);

  return (
    <Modal
      backdrop="blur"
      scrollBehavior={actionType === "report" ? "outside" : undefined}
      size={size}
      isOpen={onOpen}
      onClose={onClose}
      closeButton={CloseIcon ? <AiFillCloseCircle size={50} /> : ""}
    >
      <ModalContent>
        {onClose => (
          <>
            <ModalBody
              className="p-4"
              style={
                CloseIcon
                  ? {
                      flex: 1,
                      overflowY: "auto",
                      maxHeight: "100vh"
                    }
                  : {}
              }
            >
              {content}
            </ModalBody>
            {hasActions && (
              <ModalFooter className="justify-center py-5">
                <NeoButton color="white" onPress={onClose}>
                  Close
                </NeoButton>
                <NeoButton
                  color={actionType === "delete" ? "red" : "black"}
                  onPress={onModalSubmit}
                >
                  {actionText}
                </NeoButton>
              </ModalFooter>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
