import {EyeIcon} from "@/components/shared";
import React from "react";
import Loader from "../Loader";

interface SpeedDeviationProps {
  isLoading: boolean;
  usersetting: any;
  speedDeviation: any;
  openPopup: (title: popupParams) => void;
}

const SpeedDeviationWidget: React.FC<SpeedDeviationProps> = ({
  isLoading,
  usersetting,
  speedDeviation,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-3">
          <div className="flex items-center justify-between p-2">
            <h5 className="text-lg font-semibold">Speed Deviation</h5>
            <div className="flex items-center">
              <h5
                className="cursor-pointer pr-2"
                title="Settings"
                onClick={usersetting}
              >
                <img
                  className="h-6 w-6 p-1"
                  src="/icons/setting_1.svg"
                  alt="Setting"
                />
              </h5>
              <h5
                className="cursor-pointer"
                title="View"
                onClick={() => openPopup({title: "Speed Deviation"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
          </div>
          <div
            className={`justify-left flex ${isLoading ? "hidden" : "block"}`}
          >
            <img
              className="h-12 w-12 max-w-xs p-2"
              src="/icons/ic_speed.svg"
              alt="Trip"
            />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="p-3 pt-3">
                <h5 className="text-sm font-semibold text-slate-500 dark:text-neo-white">
                  Deviation Count
                </h5>
                <h5 className="text-4xl font-semibold">
                  {speedDeviation.deviationcount}
                </h5>
                <h5 className="mt-2 text-sm font-semibold text-slate-500 dark:text-neo-white">
                  Limit
                </h5>
                <h5 className="text-4xl font-semibold">
                  {speedDeviation.threshold}
                  <span className="text-lg font-semibold"> kmph</span>
                </h5>
              </div>
              <div className="p-1">
                <div className="flex items-start justify-center">
                  <div className="flex items-center">
                    <img
                      className=""
                      src="/icons/speed_deviation.svg"
                      alt="Speed Deviation"
                    />
                  </div>
                </div>
                <div className="-mt-10">
                  <h5 className="text-center text-3xl font-semibold">
                    {speedDeviation.deviationvehicles}
                    <span className="text-sm font-semibold text-slate-500 dark:text-neo-white">
                      /{speedDeviation.total}
                    </span>
                  </h5>
                  <h5 className="text-m text-center font-semibold text-slate-500 dark:text-neo-white">
                    Deviated Vehicles
                  </h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SpeedDeviationWidget;
