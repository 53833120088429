import {axiosInstance} from "@/app/api";
import {AuthSessionLoginURL} from "@/libs/config";
import {LoginApiUrl} from "@/libs/consts";
import axios from "axios";
const baseURL = process.env.NEXT_PUBLIC_API_URL;

const login = async (data: any): Promise<any> => {
  const result = await axiosInstance().post(LoginApiUrl, data);
  return result.data;
};

const sessionlogin = async (data: any): Promise<any> => {
  const result = await axiosInstance().post(AuthSessionLoginURL, data);
  return result.data;
};

const resetPassword = async (body: any): Promise<any> => {
  return await axiosInstance().post("/api/change/password", body);
};

const fetchOTP = async (body: any): Promise<any> => {
  return await axiosInstance().post("/api/otp", body);
};

const refreshToken = async (body: any): Promise<any> => {
  const url = baseURL?.concat("/api/refresh/token");
  return await axios.post(url as string, body);
};

const logOut = async (): Promise<any> => {
  return await axiosInstance().get("/api/logout");
};

export const authService = {
  login,
  resetPassword,
  refreshToken,
  fetchOTP,
  logOut,
  sessionlogin
};
