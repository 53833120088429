import SharedTable from "@/components/pages/AI/table/table";
import {useGlobalContext} from "@/context/global-ai/global";
import {ChartTypeConfigs} from "@/data/neo-ai-dashboard/chart-configs/chart.config";
import {SelectChartTypesList} from "@/data/neo-ai-dashboard/dashboard/dashboard-config";
import {aidashboardService} from "@/service/aidashboard.service";
import {getLocalTimeZone, parseDate, today} from "@internationalized/date";
import {DateRangePicker, Select, SelectItem, Spinner} from "@nextui-org/react";
import dynamic from "next/dynamic";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {TbDownload} from "react-icons/tb";

interface ChartCardProps {
  dataSet: CurrentItem;
  onSelectData: (data: CurrentItem) => void;
}

const ChartCard: React.FC<ChartCardProps> = ({dataSet, onSelectData}) => {
  const {setIsOpened, userId, timeZone} = useGlobalContext();
  const [dataSource, setDataSource] = useState<CurrentItem>({} as CurrentItem);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inSights, setInsights] = useState<string>("");

  useEffect(() => {
    const updatedDataSet = {...dataSet};
    if (dataSet.outputType === "CHART") {
      updatedDataSet.chartOptions = onSetChartConfig(dataSet);
    } else if (dataSet.outputType === "TABLE") {
      updatedDataSet.tableConfig = onSetTableConfig(dataSet.tableData || []);
    }
    setDataSource(updatedDataSet);
  }, [dataSet]);

  useEffect(() => {
    if (inSights.length > 0) {
      setDataSource(prev => ({
        ...prev,
        insights: inSights
      }));
    }
  }, [inSights]);

  // We convert the raw data into the proper chatConfig format from the backend.
  const onSetChartConfig = useCallback(
    (data: CurrentItem): ApexCharts.ApexOptions => {
      const chartConfig =
        ChartTypeConfigs[data.chartType as keyof ChartTypeConfigs] ||
        ({} as ApexCharts.ApexOptions);
      if (data.chartData && data.chartData.x_data) {
        chartConfig.xaxis = chartConfig.xaxis || {};
        chartConfig.yaxis =
          (chartConfig.yaxis as ApexYAxis) || ({} as ApexYAxis);
        chartConfig.xaxis.title = chartConfig.xaxis.title || {};
        chartConfig.yaxis.title = chartConfig.yaxis.title || {};

        chartConfig.xaxis.categories = data.chartData.x_data;
        chartConfig.xaxis.title.text = data.chartData.x_label;
        chartConfig.yaxis.title.text = data.chartData.y_label;
        chartConfig.series = [
          {
            name: data.chartData.y_label || "",
            data: data.chartData.y_data || []
          }
        ];
      }
      return JSON.parse(JSON.stringify(chartConfig)) as ApexCharts.ApexOptions;
    },
    []
  );

  // We convert the raw data into the proper TableConfig format from the backend.
  const onSetTableConfig = useCallback((tableData: any[]): SharedTableProps => {
    return {
      headers:
        tableData.length > 0
          ? Object.keys(tableData[0]).map(key => ({key, label: key}))
          : [],
      data: tableData.length > 0 ? tableData : []
    };
  }, []);

  // Handle opening the ChatBot modal
  const handleClickAi = useCallback(() => {
    setIsOpened(true);
    onSelectData(dataSource);
  }, [dataSource, onSelectData, setIsOpened]);

  // Handle downloading the table data in (.xlsx) formate
  const handleDownload = useCallback(async (data: CurrentItem) => {
    try {
      const response = await aidashboardService.onGetDownloadExcel(
        data.tableData || []
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${data.title}-report.xlsx`;
      link.click();
      // Cleanup: revoke the URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file", error);
    }
  }, []);

  //User Change the chart type
  const handleChangeChartType = useCallback(
    (chartType: any, selectedItem: CurrentItem) => {
      if (selectedItem.priorityId) {
        setDataSource(prev => ({...prev, chartType: chartType}));
        aidashboardService.onUpdateChartType(userId, chartType, selectedItem);
      }
    },
    [userId]
  );

  const handleDateRange = useCallback(
    async (date: any, selectedItem: CurrentItem) => {
      setIsLoading(true);
      setInsights("");
      try {
        const fromDate = date?.start?.toString();
        const toDate = date?.end?.toString();
        const result = await aidashboardService.onUpdateDate(
          userId,
          fromDate,
          toDate,
          selectedItem,
          timeZone
        );
        if (result.status == 200) {
          const set = result.data as CurrentItem;
          set.chartOptions =
            dataSet.outputType === "CHART"
              ? onSetChartConfig(set)
              : set.chartOptions;
          set.tableConfig =
            dataSet.outputType === "TABLE"
              ? onSetTableConfig(set.tableData || [])
              : set.tableConfig;
          setDataSource(set);
          if (set.outputType === "CHART" && set.isInsights) {
            const insightsResult = await aidashboardService.onGetInsights(
              userId,
              set.promptId
            );
            setInsights(insightsResult.data?.insights || "");
          }
        }
      } catch {
        console.error("Updation failed");
      } finally {
        setIsLoading(false);
      }
    },
    [userId, dataSet, onSetChartConfig, onSetTableConfig]
  );

  const chartOptions = useMemo(
    () => dataSource.chartOptions as ApexCharts.ApexOptions,
    [dataSource.chartOptions]
  );

  return (
    <div
      className={`flex flex-col items-start gap-1 rounded-xl bg-white p-3 shadow`}
    >
      <div className="mb-2 flex w-full items-center justify-between">
        <label className="text-base font-bold">{dataSource.title}</label>
        <div className="flex items-center gap-2">
          {dataSource.outputType === "TABLE" && (
            <TbDownload
              onClick={() => handleDownload(dataSource)}
              className="mt-1 cursor-pointer text-2xl text-sky-600"
            />
          )}
          <img
            className="cursor-pointer"
            onClick={handleClickAi}
            src="/images/AI.svg"
            alt="ai"
          />
        </div>
      </div>
      <div className="flex w-full justify-end gap-4 pb-2">
        {dataSource.isDateRange && (
          <DateRangePicker
            isDisabled={isLoading}
            startContent={isLoading && <Spinner size="sm" />}
            aria-label="Date Range"
            color="primary"
            minValue={today(getLocalTimeZone()).subtract({months: 1})}
            maxValue={today(getLocalTimeZone())}
            size="sm"
            className={`max-w-72`}
            onChange={event => handleDateRange(event, dataSource)}
            defaultValue={
              dataSource.fromDate && dataSource.toDate
                ? {
                    start: parseDate(dataSource.fromDate),
                    end: parseDate(dataSource.toDate)
                  }
                : null
            }
          />
        )}

        {dataSource.outputType === "CHART" && (
          <Select
            isDisabled={isLoading}
            aria-label="chart type"
            disallowEmptySelection
            onSelectionChange={(event: any) =>
              handleChangeChartType(event?.currentKey, dataSource)
            }
            defaultSelectedKeys={[`${dataSource.chartType}`]}
            color="primary"
            size="sm"
            placeholder="Chart Type"
            className="max-w-48"
          >
            {SelectChartTypesList.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </Select>
        )}
      </div>
      <label htmlFor="question">{dataSource.question}</label>
      <div className="mt-2 w-full">
        {(() => {
          switch (dataSource.outputType) {
            case "CHART": {
              return (
                <ChartCom
                  dataSet={chartOptions}
                  chartType={dataSource.chartType as ChartTypes}
                />
              );
            }
            case "TABLE": {
              return (
                <SharedTable
                  headers={dataSource.tableConfig?.headers || []}
                  data={dataSource.tableConfig?.data || []}
                />
              );
            }
            default: {
              return (
                <div className="flex items-center justify-center">
                  <img
                    className="h-[250px] w-[250px] object-contain"
                    src="/images/notRecordFound.svg"
                    alt=""
                  />
                </div>
              );
            }
          }
        })()}
      </div>
      {dataSource.outputType === "CHART" && (
        <>
          <label className="text-base font-bold">Insights</label>
          {dataSource.isInsights ? (
            <span
              dangerouslySetInnerHTML={{__html: dataSource.insights || ""}}
            ></span>
          ) : (
            <span className="p-2 font-medium opacity-25">
              Insights not applicable for live data
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(ChartCard);

interface ChartComProps {
  dataSet: ApexCharts.ApexOptions;
  chartType: ChartTypes;
}

const ChartCom = React.memo(({dataSet, chartType}: ChartComProps) => {
  const Chart = dynamic(() => import("react-apexcharts"), {ssr: false});

  return (
    <Chart
      key={"chart"}
      options={dataSet}
      series={dataSet?.series}
      type={chartType}
      width={"100%"}
      height="auto"
    />
  );
});

ChartCom.displayName = "ChartCom";
