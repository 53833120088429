"use client";
import {NeoButton, NeoSelect, NeoTextBox} from "@/components/forms";
import {NeoHeader} from "@/components/shared";
import {CountriesData} from "@/data/login/countries-data";
import {yupResolver} from "@hookform/resolvers/yup";
import {SelectItem} from "@nextui-org/react";
import {ChangeEvent, useState} from "react";
import {CircleFlag} from "react-circle-flags";
import {Controller, useForm} from "react-hook-form";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";

const countries = CountriesData;

interface ContactFormProps {
  yourname: string;
  countryCode: string;
  emailaddress: string;
  content: string;
  phonenumber?: number | null;
}

const defaultValues: ContactFormProps = {
  yourname: "",
  countryCode: "91",
  emailaddress: "",
  content: "",
  phonenumber: null
};

export const ContactForm: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [country, setCountry] = useState("in");
  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry =
      countries.find(x => x.value === e.target.value)?.flag ?? "in";
    setCountry(selectedCountry);
  };

  const validationSchema = yup.object().shape({
    yourname: yup
      .string()
      .required("Please enter your name")
      .matches(/^[a-zA-Z .]*$/, "Please enter the valid name"),
    emailaddress: yup
      .string()
      .required("Please enter email address")
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
        "Please enter valid email address"
      ),
    content: yup
      .string()
      .required("Please enter message")
      .matches(/^[a-zA-Z0-9 .,=-]*$/, "Please enter the valid message")
      .max(300, "Maximum 300 characters allowed"),
    phonenumber: yup
      .string()
      .matches(/^[0-9]*$/, "Please enter the valid message")
      .nullable()
  });

  const {
    reset,
    resetField,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    control,
    setValue,
    setError,
    clearErrors
  } = useForm<any>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: any) => {
    //setLoading(true);
    var yourname = data.yourname;
    var countryCode = data.countryCode;
    var emailaddress = data.emailaddress;
    var message = data.content;
    var phonenumber = data.phonenumber;

    /*var requestdata = { 'type': msgtype, 'id': id, 'msg': msgprint };
    await AnnouncementService.sendsnnouncements(requestdata)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        handleReset();
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    })
    .catch(err => {
      toast.error("Sorry!, We have technical issue please try again later");
    })
    .finally(() => {
      setLoading(false);
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
    });*/
  };

  return (
    <>
      <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-5">
        <div className="mb-5 flex-row">
          <NeoHeader message="Contact us"></NeoHeader>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="py-2">
            <NeoTextBox
              label={
                <span>
                  Your Name <span className="text-red-500">*</span>
                </span>
              }
              id="yourname"
              type="text"
              placeholder="Please enter your name"
              color={errors.yourname ? "danger" : "default"}
              isInvalid={errors.yourname ? true : false}
              errorMessage={errors.yourname && `${errors.yourname.message}`}
              {...register("yourname")}
            />
          </div>
          <div className="py-2">
            <NeoTextBox
              label={
                <span>
                  Email Address <span className="text-red-500">*</span>
                </span>
              }
              id="emailaddress"
              type="email"
              placeholder="Please enter email address"
              color={errors.emailaddress ? "danger" : "default"}
              isInvalid={errors.emailaddress ? true : false}
              errorMessage={
                errors.emailaddress && `${errors.emailaddress.message}`
              }
              {...register("emailaddress")}
            />
          </div>
          <div className="py-2">
            <div className="flex items-center">
              <Controller
                name="countryCode"
                control={control}
                render={({field}) => (
                  <NeoSelect
                    {...field}
                    id="countryCode"
                    labelPlacement="outside"
                    label="Country"
                    placeholder="Select"
                    onChange={handleCountryChange}
                    startContent={
                      <CircleFlag
                        height="25"
                        width="25"
                        countryCode={country}
                      />
                    }
                    className="mr-2 w-1/2"
                  >
                    {countries.map(code => (
                      <SelectItem
                        key={code.value}
                        value={code.value}
                        startContent={
                          <CircleFlag
                            height="25"
                            width="25"
                            countryCode={code.flag}
                          />
                        }
                      >
                        {code.label}
                      </SelectItem>
                    ))}
                  </NeoSelect>
                )}
              />
              <NeoTextBox
                label="Phone Number (Optional)"
                id="phonenumber"
                type="text"
                placeholder="Please enter phone number"
                color={errors.phonenumber ? "danger" : "default"}
                isInvalid={errors.phonenumber ? true : false}
                errorMessage={
                  errors.phonenumber && `${errors.phonenumber.message}`
                }
                {...register("phonenumber")}
              />
            </div>
          </div>
          <div className="py-2">
            <label className="mb-2 block">
              Message <span className="text-red-500">*</span>
            </label>
            <textarea
              id="content"
              className={`block h-24 w-full rounded-lg border bg-gray-50 p-2.5 text-sm ${errors.content ? "border-rose-500 focus:border-rose-500 focus:ring-rose-500" : ""} ${errors.content ? "dark:focus:border-rose-500 dark:focus:ring-rose-500" : ""}`}
              placeholder="Add your Message"
              {...register("content")}
            />

            {errors?.content?.message && (
              <p className="mt-1 text-sm text-red-500">
                {errors?.content?.message &&
                typeof errors.content.message === "string"
                  ? errors.content.message
                  : ""}
              </p>
            )}
          </div>
          <div className="flex items-center justify-end p-1">
            <NeoButton
              className="mx-1 bg-[#FFC727] text-[#000000]"
              type="submit"
              disabled={isSubmitting}
              isLoading={loading}
              spinner={
                <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
              }
              color="black"
            >
              Leave Us Message
            </NeoButton>
            <NeoButton
              className=""
              type="reset"
              color="white"
              onClick={() => reset()}
            >
              Reset
            </NeoButton>
          </div>
        </form>
      </div>
    </>
  );
};
