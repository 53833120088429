import {EyeIcon} from "@/components/shared";
import React from "react";
import Loader from "../Loader";

interface CampusAttendanceProps {
  isLoading: boolean;
  campusAttendance: any;
  openPopup: (title: popupParams) => void;
}

const CampusAttendanceWidget: React.FC<CampusAttendanceProps> = ({
  isLoading,
  campusAttendance,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="grid-cols grid w-full flex-row">
            <div className="flex items-center justify-between p-2">
              <h5 className="text-lg font-semibold">Campus Attendance</h5>
              <h5
                className="cursor-pointer pr-1"
                title="View"
                onClick={() => openPopup({title: "Campus Attendance"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-1 gap-4 pt-2 sm:grid-cols-2">
              <div className="border-r-0 p-3 sm:border-r-2 sm:border-dashed">
                <img
                  className="h-12 w-12 p-2"
                  src="/icons/UserGroup.svg"
                  alt="Present"
                />
                <h5 className="text-base font-semibold text-slate-500 dark:text-neo-white">
                  Total Students
                </h5>
                <h5 className="text-xl font-semibold">
                  {campusAttendance.total}
                </h5>
              </div>
              <div className="p-1">
                <div className="">
                  <div className="flex">
                    <div className="mt-1 h-3 w-1 rounded-2xl bg-[#349C42]"></div>
                    <h5 className="pl-1 text-sm font-semibold text-slate-500 dark:text-neo-white">
                      Present
                    </h5>
                  </div>
                  <h5 className="text-xl font-semibold">
                    {campusAttendance.present}
                  </h5>
                  <div className="flex justify-end">
                    <h5 className="text-sm font-semibold">
                      {campusAttendance.presentpercent}
                    </h5>
                  </div>
                  <div className="h-2 w-full rounded bg-[#C1E8C6]">
                    <div
                      className="h-2 rounded bg-[#349C42]"
                      style={{width: campusAttendance.presentpercent}}
                    ></div>
                  </div>
                </div>
                <div className="pb-2 pt-3">
                  <div className="flex items-center">
                    <div className="mt-1 h-3 w-1 rounded-2xl bg-[#FF5555]"></div>
                    <h5 className="pl-1 text-sm font-semibold text-slate-500 dark:text-neo-white">
                      Absent
                    </h5>
                  </div>
                  <h5 className="text-xl font-semibold">
                    {campusAttendance.absent}
                  </h5>
                  <div className="flex justify-end">
                    <h5 className="text-sm font-semibold">
                      {campusAttendance.absentpercent}
                    </h5>
                  </div>
                  <div className="h-2 w-full rounded bg-[#FCD0D0]">
                    <div
                      className="h-2 rounded bg-[#FF5555]"
                      style={{width: campusAttendance.absentpercent}}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CampusAttendanceWidget;
