"use client";
import {ChatPanel} from "@/components/pages/AI/chat/chatPanel";
import SharedTable from "@/components/pages/AI/table/table";
import Typewriter from "@/components/pages/AI/typeWriter/typeWriter";
import {useGlobalContext} from "@/context/global-ai/global";
import {ChartTypeConfigs} from "@/data/neo-ai-dashboard/chart-configs/chart.config";
import dynamic from "next/dynamic";
import {useEffect, useState} from "react";

interface ManageChatModalProps {
  dataSet: CurrentItem;
}

export const ManageChatModal = ({dataSet}: ManageChatModalProps) => {
  const Chart = dynamic(() => import("react-apexcharts"), {ssr: false});
  const {setCurrentItem, currentItem} = useGlobalContext();
  const [dataSource, setDataSource] = useState<CurrentItem>(
    dataSet as CurrentItem
  );
  const [inSights, setInsights] = useState<string>("");

  useEffect(() => {
    if (currentItem?.priorityId) {
      setDataSource(prev => ({
        ...currentItem,
        chartOptions:
          currentItem.outputType === "CHART"
            ? onSetChartConfig(currentItem)
            : currentItem.chartOptions,
        tableConfig:
          currentItem.outputType === "TABLE"
            ? onSetTableConfig(currentItem.tableData || [])
            : currentItem.tableConfig
      }));
    }
  }, [currentItem]);

  useEffect(() => {
    if (inSights.length > 0) {
      setCurrentItem(prev => ({
        ...prev,
        insights: inSights
      }));
    }
  }, [inSights]);

  const onSetChartConfig = (data: CurrentItem): ApexCharts.ApexOptions => {
    const chartConfig =
      ChartTypeConfigs[data.chartType as keyof ChartTypeConfigs] ||
      ({} as ApexCharts.ApexOptions);
    if (data.chartData && data.chartData.x_data) {
      chartConfig.xaxis = chartConfig.xaxis || {};
      chartConfig.yaxis = (chartConfig.yaxis as ApexYAxis) || ({} as ApexYAxis);
      chartConfig.xaxis.title = chartConfig.xaxis.title || {};
      chartConfig.yaxis.title = chartConfig.yaxis.title || {};

      chartConfig.xaxis.categories = data.chartData.x_data;
      chartConfig.xaxis.title.text = data.chartData.x_label;
      chartConfig.yaxis.title.text = data.chartData.y_label;
      chartConfig.series = [
        {
          name: data.chartData.y_label || "",
          data: data.chartData.y_data || []
        }
      ];
    }
    return JSON.parse(JSON.stringify(chartConfig)) as ApexCharts.ApexOptions;
  };

  const onSetTableConfig = (tableData: any[]): SharedTableProps => {
    const result: SharedTableProps = {
      headers:
        tableData.length > 0
          ? Object.keys(tableData[0]).map(key => ({key, label: key}))
          : [],
      data: tableData.length > 0 ? tableData : []
    };
    return result;
  };

  return (
    <div className="grid min-h-[80vh] grid-cols-6">
      <div className="col-span-4">
        <div className={`flex flex-col items-start gap-1 bg-white p-4`}>
          <label className="my-2 text-base font-bold">
            {dataSource?.title}
          </label>
          <label htmlFor="question">{dataSource?.question}</label>
          <div className="mt-2 max-h-[45vh] min-h-[45vh] w-full">
            {(() => {
              switch (dataSource?.outputType) {
                case "CHART": {
                  return (
                    <Chart
                      key={dataSource?.priorityId}
                      options={dataSource?.chartOptions}
                      series={dataSource?.chartOptions?.series}
                      type={dataSource?.chartType}
                      width={"100%"}
                      height={"100%"}
                    />
                  );
                }
                case "TABLE": {
                  return (
                    <SharedTable
                      headers={dataSource.tableConfig?.headers || []}
                      data={dataSource.tableConfig?.data || []}
                    />
                  );
                }
                default: {
                  return (
                    <div className="flex items-center justify-center">
                      <img
                        className="h-[250px] w-[250px] object-contain"
                        src="/images/notRecordFound.svg"
                        alt=""
                      />
                    </div>
                  );
                }
              }
            })()}
          </div>
          {dataSource.outputType === "CHART" && (
            <>
              <label className="text-base font-bold">Insights</label>
              {dataSource.isInsights ? (
                <Typewriter
                  isTyping={inSights.length > 0}
                  text={dataSource?.insights || ""}
                />
              ) : (
                <span className="p-2 font-medium opacity-25">
                  Insights not applicable for live data
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="col-span-2">
        <ChatPanel
          onSetInsights={event => setInsights(event)}
          data={dataSource}
          onUpdate={event => setCurrentItem(event)}
        />
      </div>
    </div>
  );
};
