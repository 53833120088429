import {EyeIcon} from "@/components/shared";
import React from "react";
import Loader from "../Loader";

interface DistanceDrivenProps {
  isLoading: boolean;
  distanceDriven: any;
  openPopup: (title: popupParams) => void;
}

const DistanceDrivenWidget: React.FC<DistanceDrivenProps> = ({
  isLoading,
  distanceDriven,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="grid-cols grid w-full flex-row">
            <div className="flex items-center justify-between p-2">
              <h5 className="text-lg font-semibold dark:text-neo-white">
                Distance Driven
              </h5>
              <h5
                className="cursor-pointer pr-1"
                title="View"
                onClick={() => openPopup({title: "Distance Driven"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="rounded-2xl bg-neo-white dark:bg-[#1F2937]">
              <div className="justify-left flex items-center">
                <img
                  className="h-12 w-12 max-w-xs p-2"
                  src="/icons/dashboard_distance_1.svg"
                  alt="Trip"
                />
                <h5 className="text-2xl font-semibold">
                  {distanceDriven.total}
                </h5>
              </div>
              <div className="grid grid-cols-1 gap-2 pt-4 sm:grid-cols-2">
                <div className="ml-2 border-l-2 border-dashed pl-3 pt-3">
                  <h5 className="text-2xl font-semibold text-[#349C42]">
                    {distanceDriven.scheduled}
                  </h5>
                  <h5 className="text-sm font-semibold">Scheduled</h5>
                  <div className="pt-3">
                    <h5 className="text-sm font-semibold text-[#349C42]">
                      {distanceDriven.scheduledpercent}
                    </h5>
                    <div className="h-2 w-full rounded bg-[#C1E8C6]">
                      <div
                        className="h-2 rounded bg-[#349C42]"
                        style={{width: distanceDriven.scheduledpercent}}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="mr-2 border-l-2 border-r-2 border-dashed pl-3 pt-3">
                  <h5 className="text-2xl font-semibold text-[#11428C]">
                    {distanceDriven.unscheduled}
                  </h5>
                  <h5 className="text-sm font-semibold">Unscheduled</h5>
                  <div className="pr-1 pt-3">
                    <h5 className="text-sm font-semibold text-[#11428C]">
                      {distanceDriven.unscheduledpercent}
                    </h5>
                    <div className="h-2 w-full rounded bg-[#BCD6FD]">
                      <div
                        className="h-2 rounded bg-[#11428C]"
                        style={{width: distanceDriven.unscheduledpercent}}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DistanceDrivenWidget;
