import {EyeIcon} from "@/components/shared";
import dynamic from "next/dynamic";
import React from "react";
import Loader from "../Loader";
const Chart = dynamic(() => import("react-apexcharts"), {ssr: false});

interface InsuranceSummaryProps {
  isLoading: boolean;
  insuranceChartKey: string | number;
  insuranceOptions: any;
  insuranceSeries: any;
  insurancedatas: any;
  openPopup: (title: popupParams) => void;
}

const InsuranceSummaryWidget: React.FC<InsuranceSummaryProps> = ({
  isLoading,
  insuranceChartKey,
  insuranceOptions,
  insuranceSeries,
  insurancedatas,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="grid-cols grid w-full flex-row">
            <div className="flex items-center justify-between p-2">
              <h5 className="text-lg font-semibold">Insurance Summary</h5>
              <h5
                className="cursor-pointer pr-1"
                title="View"
                onClick={() => openPopup({title: "Insurance Summary"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {insurancedatas.upToDate ? (
                  <div className="flex h-full items-center justify-center p-2">
                    <img
                      src="/images/InsuranceGroup.svg"
                      alt="Loading..."
                      className=""
                    />
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-2 flex-row gap-2 p-1">
                      <div className="p-1">
                        <h5 className="text-md font-semibold">Expiring Soon</h5>
                        {insurancedatas.range.map(
                          (policies: any, index: number) => (
                            <div
                              key={index}
                              className="justify-left mt-2  flex items-center rounded-full border-[#E5E7EB] bg-[#E5E7EB]"
                            >
                              <img
                                className="h-8 w-8 max-w-xs p-2"
                                src={
                                  policies.days == 15
                                    ? "/icons/bolt-yellow.svg"
                                    : policies.days == 30
                                      ? "/icons/bolt-blue.svg"
                                      : "/icons/bolt-red.svg"
                                }
                              />
                              <h5 className="text-sm dark:text-neo-black">
                                {policies.days} days{" "}
                                <span className="text-[#006DED]">
                                  {policies.count} policies
                                </span>
                              </h5>
                            </div>
                          )
                        )}
                      </div>
                      <div className="">
                        {insurancedatas.total > 0 && (
                          <>
                            <div className="flex flex-col items-center justify-center ">
                              <div className="items-center">
                                <Chart
                                  key={insuranceChartKey}
                                  options={insuranceOptions}
                                  series={insuranceSeries}
                                  type="donut"
                                  width="180"
                                  height="180"
                                />
                              </div>
                              <div className="flex items-center gap-2 text-xs">
                                <span className="inline-block  h-3 w-3 rounded-full bg-green-500"></span>
                                <span className="text-center">
                                  {insuranceSeries[0]}%
                                </span>

                                <span className="inline-block  h-3 w-3 rounded-full bg-red-500"></span>
                                <span className="text-center">
                                  {insuranceSeries[1]}%
                                </span>

                                <span className="inline-block  h-3 w-3 rounded-full bg-gray-300"></span>
                                <span className="text-center">
                                  {insuranceSeries[2]}%
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="grid grid-cols-3 flex-row gap-2 p-1">
                  <div
                    className="p-1"
                    onClick={() =>
                      openPopup({
                        title: "Insurance Summary",
                        filter: {status: [1]}
                      })
                    }
                  >
                    <div className="flex items-center">
                      <div className="h-4 w-1 rounded-2xl bg-[#349C42]"></div>
                      <h5 className="pl-3 text-2xl font-semibold dark:text-neo-white">
                        {insurancedatas.active}
                      </h5>
                    </div>
                    <h5 className="text-xs font-semibold">Active Policies</h5>
                  </div>
                  <div
                    className="p-1"
                    onClick={() =>
                      openPopup({
                        title: "Insurance Summary",
                        filter: {status: [2]}
                      })
                    }
                  >
                    <div className="flex items-center">
                      <div className="h-4 w-1 rounded-2xl bg-[#FF5555]"></div>
                      <h5 className="pl-3 text-2xl font-semibold dark:text-neo-white">
                        {insurancedatas.expired}
                      </h5>
                    </div>
                    <h5 className="text-xs font-semibold">Expired Policies</h5>
                  </div>
                  <div
                    className="p-1"
                    onClick={() =>
                      openPopup({
                        title: "Insurance Summary",
                        filter: {status: [3]}
                      })
                    }
                  >
                    <div className="flex items-center">
                      <div className="h-4 w-1 rounded-2xl bg-[#A3A4A4]"></div>
                      <h5 className="pl-3 text-2xl font-semibold dark:text-neo-white">
                        {insurancedatas.noData}
                      </h5>
                    </div>
                    <h5 className="text-xs font-semibold">No Data</h5>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceSummaryWidget;
