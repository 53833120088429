"use client";
import {NeoTable} from "@/components/table";
import {useNeoContext} from "@/context/neo/neo.context";
import {useForm} from "react-hook-form";

interface DASHBOARDREPORTCONFIG {
  reportdata: {
    header: string;
    description: string;
    order: number;
    activeReport?: {
      icon: string;
      menu: string;
      menu_description: string;
      url: string;
      method: string;
      filters: any;
      columns?: any;
      activeColumns?: any;
      dashboardfilter?: any;
    };
    dashboardfilter?: any;
    menus: {
      icon: string;
      menu: string;
      menu_description: string;
      url: string;
      method: string;
      filters: any;
      columns?: any;
      activeColumns?: any;
    }[];
  };
}

export const DashboardReport: React.FC<DASHBOARDREPORTCONFIG> = ({
  reportdata
}) => {
  const {clients} = useNeoContext();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {isSubmitting, errors}
  } = useForm<any>({
    mode: "onChange"
  });

  const handleFilterSubmit = (req: any) => {};

  const datas: NeoTableConfig = {
    title: reportdata?.activeReport?.menu
      ? reportdata?.activeReport?.menu
      : "Report",
    table: {
      columns: reportdata?.activeReport?.columns,
      activeColumns: reportdata?.activeReport?.activeColumns,
      activeFilters: reportdata?.activeReport?.filters,
      style: {
        height: "h-full"
      },
      meta: {
        id: 5,
        title: reportdata?.activeReport?.menu
          ? reportdata?.activeReport?.menu
          : "Report",
        description: "Total Data",
        hasSearch: true,
        hasUpload: false,
        hasDownload: false,
        hasAdd: false,
        hasActions: false,
        hasCheckbox: false,
        hasFilter: reportdata?.activeReport?.filters.length ? true : false,
        hasFooter: true,
        hasHeader: true,
        hasPagination: true,
        hasCreate: false
      },
      url: {
        page: {},
        api: {
          get: reportdata?.activeReport?.url
        }
      },
      dashboardfilter: reportdata?.dashboardfilter,
      actions: []
    }
  };
  return (
    <>
      <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
        {reportdata?.activeReport?.columns.length > 0 && (
          <NeoTable table={datas.table}></NeoTable>
        )}
      </div>
    </>
  );
};
