import {EyeIcon} from "@/components/shared";
import React, {useEffect, useState} from "react";
import Loader from "../Loader";

interface PermitSummaryProps {
  isLoading: boolean;
  permitdatas: any;
  openPopup: (title: popupParams) => void;
}

type percentageData = {
  activePer: number;
  expiredPer: number;
  noDataPer: number;
};
const PermitSummaryWidget: React.FC<PermitSummaryProps> = ({
  isLoading,
  permitdatas,
  openPopup
}) => {
  const [percentageData, setpercentageData] = useState<percentageData>({
    activePer: 0,
    expiredPer: 0,
    noDataPer: 0
  });

  useEffect(() => {
    const totalVehicle = permitdatas.total;
    const activePer = permitdatas.active
      ? parseFloat(((permitdatas.active / totalVehicle) * 100).toFixed(2))
      : 0;

    const expiredPer = permitdatas.expired
      ? parseFloat(((permitdatas.expired / totalVehicle) * 100).toFixed(2))
      : 0;

    const noDataPer = permitdatas.noData
      ? parseFloat(((permitdatas.noData / totalVehicle) * 100).toFixed(2))
      : 0;

    setpercentageData({
      activePer: activePer,
      expiredPer: expiredPer,
      noDataPer: noDataPer
    });
  }, [permitdatas]);
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="flex items-center justify-between p-2">
            <h5 className="text-lg font-semibold">Permit Summary</h5>
            <h5
              className="cursor-pointer"
              title="View"
              onClick={() => openPopup({title: "Permit Summary"})}
            >
              <EyeIcon size={18} />
            </h5>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex flex-col  p-1">
                <div className="flex items-center justify-between">
                  <h5 className="flex flex-col items-start text-xl font-semibold">
                    <div className="flex items-center">
                      {permitdatas.total}
                      <span className="ml-3 text-sm text-slate-400">
                        Total Vehicles
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
              {!permitdatas.upToDate && (
                <div className="rounded-full border-[#FFC7271A] bg-[#FFC7271A] p-2">
                  <div className="flex justify-between">
                    <div className="ml-2 items-start">
                      <h5 className="font-semibold text-red-500">Expiring</h5>
                      <h5 className="text-sm">Soon</h5>
                    </div>
                    <h5 className="items-end text-base font-semibold">
                      <div className="justify-left mt-2 flex items-center rounded-full border-[#E5E7EB] bg-[#E5E7EB]">
                        <img
                          className="h-8 w-8 max-w-xs p-1"
                          src="/icons/bolt-blue.svg"
                        />
                        {permitdatas.range.length > 0 ? (
                          permitdatas.range.map(
                            (permits: any, index: number) =>
                              permits.days === 30 ? (
                                <>
                                  <h5
                                    key={index}
                                    className="mr-2 text-sm dark:text-neo-black"
                                  >
                                    30 days{" "}
                                    <span className="text-[#006DED]">
                                      {permits.count} Permits
                                    </span>
                                  </h5>
                                </>
                              ) : null
                          )
                        ) : (
                          <h5 className="mr-2 text-sm dark:text-neo-black">
                            30 days{" "}
                            <span className="text-[#006DED]">0 Permits</span>
                          </h5>
                        )}
                      </div>
                    </h5>
                  </div>
                </div>
              )}

              <div className="w-full">
                <div className="flex h-4 w-full overflow-hidden rounded-md">
                  <div
                    className=" bg-green-500"
                    style={{width: `${percentageData.activePer}%`}}
                    title={`${percentageData.activePer}%`}
                  />
                  <div
                    className="bg-red-500"
                    style={{width: `${percentageData.expiredPer}%`}}
                    title={`${percentageData.expiredPer}%`}
                  />
                  <div
                    className="bg-gray-300"
                    style={{width: `${percentageData.noDataPer}%`}}
                    title={`${percentageData.noDataPer}%`}
                  />
                </div>
                <div className="mt-1 flex items-center justify-end gap-3 text-sm">
                  <span className="inline-block h-3 w-3 rounded-full bg-green-500"></span>
                  <span
                    className="text-center"
                    //style={{width: `${percentageData.activePer}%`}}
                  >
                    {percentageData.activePer}%
                  </span>
                  <span className="inline-block h-3 w-3 rounded-full bg-red-500"></span>
                  <span
                    className="text-center"
                    // style={{width: `${percentageData.expiredPer}%`}}
                  >
                    {percentageData.expiredPer}%
                  </span>
                  <span className="inline-block h-3 w-3 rounded-full bg-gray-300"></span>
                  <span
                    className="text-center"
                    //style={{width: `${percentageData.noDataPer}%`}}
                  >
                    {percentageData.noDataPer}%
                  </span>
                </div>
              </div>
              {/* <div className="relative flex w-full items-center">
                <div className="relative mx-0 flex-grow">
                  <div className="h-3 w-full rounded-full bg-gray-300">
                    <div className="absolute top-0 h-3 w-3 rounded-full border-2 border-gray-400 bg-white"></div>
                    <div
                      className="h-3 rounded-full bg-green-500"
                      style={{width: permitdatas.permitpercentage}}
                    ></div>
                    <div className="absolute right-0 top-0 h-3 w-3 rounded-full border-2 border-gray-400 bg-white"></div>
                  </div>
                  {permitdatas.permitpercentage.replace(".00", "") !== "0%" &&
                    permitdatas.permitpercentage.replace(".00", "") !==
                      "100%" && (
                      <div
                        className={twMerge(
                          "absolute top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-gray-400 bg-white"
                        )}
                        style={{left: `${permitdatas.permitpercentage}`}}
                      ></div>
                    )}
                </div>
              </div> */}
              {/*<div className="mt-2 h-2 w-full rounded bg-[#C1E8C6]">
                <div
                  className="h-2 rounded bg-[#349C42]"
                  style={{width: permitdatas.permitpercentage}}
                ></div>
              </div>*/}
              {/* <div className="flex justify-between">
                <span className="items-start text-xs">Active</span>
                <span className="items-end text-xs">Expired</span>
              </div> */}
              <div className=" grid grid-cols-3 flex-row gap-2 p-1">
                <div
                  className="p-1"
                  onClick={() =>
                    openPopup({title: "Permit Summary", filter: {status: [1]}})
                  }
                >
                  <div className="flex items-center">
                    <div className="h-4 w-1 rounded-2xl bg-[#349C42]"></div>
                    <h5 className="pl-3 text-2xl font-semibold dark:text-neo-white">
                      {permitdatas.active}
                    </h5>
                  </div>
                  <h5 className="text-xs font-semibold">Active Permits</h5>
                </div>
                <div
                  className="p-1"
                  onClick={() =>
                    openPopup({title: "Permit Summary", filter: {status: [2]}})
                  }
                >
                  <div className="flex items-center">
                    <div className="h-4 w-1 rounded-2xl bg-[#FF5555]"></div>
                    <h5 className="pl-3 text-2xl font-semibold dark:text-neo-white">
                      {permitdatas.expired}
                    </h5>
                  </div>
                  <h5 className="text-xs font-semibold">Expired Permits</h5>
                </div>
                <div
                  className="p-1"
                  onClick={() =>
                    openPopup({title: "Permit Summary", filter: {status: [3]}})
                  }
                >
                  <div className="flex items-center">
                    <div className="h-4 w-1 rounded-2xl bg-[#A3A4A4]"></div>
                    <h5 className="pl-3 text-2xl font-semibold dark:text-neo-white">
                      {permitdatas.noData}
                    </h5>
                  </div>
                  <h5 className="text-xs font-semibold">No Data</h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PermitSummaryWidget;
