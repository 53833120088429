"use client";
import {
  NeoButton,
  NeoDatePicker,
  NeoSelect,
  NeoTextBox
} from "@/components/forms";
import {NeoCard, NeoError, NeoHeader} from "@/components/shared";
import {useNeoContext} from "@/context/neo/neo.context";
import {AnnouncementUsers} from "@/data";
import {formatDate} from "@/libs/helper";
import {AnnouncementService} from "@/service/announcement.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {CardBody, CardHeader, SelectItem} from "@nextui-org/react";
import {ChangeEvent, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";

interface FormAnnouncements {
  vehicleId: any;
  routeId?: any | undefined;
  type: any;
  fromdate?: any;
  todate?: any;
  info1?: any | undefined;
  info2?: any | undefined;
  info3?: any | undefined;
}

type AnnouncementsFormProps = {
  items: any;
  client: Client;
};

export const AnnouncementsForm: React.FC = () => {
  const mindate = formatDate(new Date());
  const [loading, setLoading] = useState(false);
  const dotedline = ".....................";
  const formdefaultValues: FormAnnouncements = {
    vehicleId: "",
    routeId: "",
    type: "",
    fromdate: "",
    todate: "",
    info1: "",
    info2: "",
    info3: ""
  };
  const {filterContext} = useNeoContext();
  const [defaultValues, setDefaultvalue] = useState(formdefaultValues);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [Announcements, setAnnouncements] = useState([]);
  const [msgdefaultselect, setMsgDefaultSelect] = useState("");
  const [vehicles, setvehicles] = useState([]);
  const [vehicleroutes, setvehicleroutes] = useState([]);
  const [routes, setroutes] = useState([]);
  const [msgcontents, setmsgcontents] = useState([]);
  const [msgprint, setmsgprint] = useState<string | null>(null);
  const [fromdatevisible, setfromdatevisible] = useState(false);
  const [todatevisible, settodatevisible] = useState(false);
  const [info1visible, setinfo1visible] = useState(false);
  const [info2visible, setinfo2visible] = useState(false);
  const [info3visible, setinfo3visible] = useState(false);
  const [msgformData, setmsgFormData] = useState({
    fromdate: dotedline,
    todate: dotedline,
    info1: dotedline,
    info2: dotedline,
    info3: dotedline,
    type: ""
  });
  const datas = AnnouncementUsers;
  const validationSchema = yup.object().shape({
    vehicleId: yup.string().required("Please select vehicle"),
    routeId: yup.string(),
    type: yup.string().required("Please select type"),
    fromdate: fromdatevisible
      ? yup
          .date()
          .nullable()
          .typeError("Please Enter the Valid Date")
          .transform((value, originalValue) => {
            return originalValue === "" ? null : value;
          })
          .required("Please select from date")
      : yup.string(),
    todate: todatevisible
      ? yup
          .date()
          .nullable()
          .typeError("Please Enter the Valid Date")
          .transform((value, originalValue) => {
            return originalValue === "" ? null : value;
          })
          .required("Please select from date")
          .min(yup.ref("fromdate"), "To Date cannot be before From Date")
      : yup.string(),
    info1: info1visible
      ? yup
          .string()
          .required("Please enter the message")
          .matches(/^[a-zA-Z0-9 .,=-]*$/, "Please enter the valid message")
      : yup.string(),
    info2: info2visible
      ? yup
          .string()
          .required("Please enter the message")
          .matches(/^[a-zA-Z0-9 .,=-]*$/, "Please enter the valid message")
      : yup.string(),
    info3: info3visible
      ? yup
          .string()
          .required("Please enter the message")
          .matches(/^[a-zA-Z0-9 .,=-]*$/, "Please enter the valid message")
      : yup.string()
  });

  const {
    reset,
    resetField,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    control,
    setValue,
    setError,
    clearErrors
  } = useForm<FormAnnouncements>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    const fetchAnnouncementsAndVehicles = async () => {
      if (vehicles.length === 0 && filterContext.client.institutionId) {
        const instituteId = filterContext.client.institutionId;
        const msgdatas: any = {};
        const vehicledetails: any = [];
        const routedatas: any = {};

        try {
          const response =
            await AnnouncementService.onGetInstVehiclesandMsgs(instituteId);
          if (response?.AnnouncementDatas) {
            setAnnouncements(response.AnnouncementDatas);
            response.AnnouncementDatas.forEach((value: any) => {
              if (!msgdatas[value.type]) {
                msgdatas[value.type] = [];
              }
              msgdatas[value.type].push(value.msg);
            });

            if (Object.keys(msgdatas).length > 0) {
              setmsgcontents(msgdatas);
            }
          }

          if (response?.VehicleDatas) {
            response.VehicleDatas.forEach((value: any) => {
              const vehicle = {
                trackerId: value.trackerId,
                trackerName: value.trackerName
              };
              vehicledetails.push(vehicle);

              if (value.routes) {
                value.routes.forEach((routevalue: any) => {
                  if (!routedatas[value.trackerId]) {
                    routedatas[value.trackerId] = [];
                  }
                  const route = {
                    routeId: routevalue.routeId,
                    routeName: routevalue.routeName
                  };
                  routedatas[value.trackerId].push(route);
                });
              }
            });

            if (vehicledetails.length > 0) {
              setvehicles(vehicledetails);
            }

            if (Object.keys(routedatas).length > 0) {
              setvehicleroutes(routedatas);
            }
          }
        } catch (error) {
          setErrorMessage(
            "Sorry!, We have technical issue please try again later"
          );
        }
      }
    };

    fetchAnnouncementsAndVehicles();
    if (msgformData?.type) {
      custommsg(msgformData.type);
    }
  }, [msgformData, filterContext]);

  const handleReset: any = () => {
    reset();
  };

  const msgfieldReset = () => {
    setValue("fromdate", "");
    setValue("todate", "");
    setValue("info1", "");
    setValue("info2", "");
    setValue("info3", "");
    setfromdatevisible(false);
    settodatevisible(false);
    setinfo1visible(false);
    setinfo2visible(false);
    setinfo3visible(false);
    setmsgprint("");
    clearErrors(["type", "fromdate", "todate", "info1", "info2", "info3"]);
  };

  const VehicleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue("type", "");
    var vehicleid: any = e.target.value;
    var name: any = e.target.name;
    setValue(name, vehicleid);
    clearErrors([name]);
    var vehicleroute: any = [];
    if (vehicleid && vehicleroutes[vehicleid]) {
      vehicleroute = vehicleroutes[vehicleid];
    }
    setroutes(vehicleroute);
    setMsgDefaultSelect("");
    msgfieldReset();
  };

  const RouteChange = (e: any) => {
    setValue("type", "");
    setMsgDefaultSelect("");
    var type: any = e.target.value;
    var name: any = e.target.name;
    setValue(name, type);
    msgfieldReset();
  };

  const MsgChange = (e: any) => {
    msgfieldReset();
    var type: any = e.target.value;
    var name: any = e.target.name;
    setMsgDefaultSelect(type);
    setValue(name, type);
    clearErrors([name]);
    setmsgFormData(prevData => ({
      ...prevData,
      ["fromdate"]: dotedline,
      ["todate"]: dotedline,
      ["info1"]: dotedline,
      ["info2"]: dotedline,
      ["info3"]: dotedline,
      ["type"]: type
    }));
    custommsg(type);
  };

  const custommsg = (type: any) => {
    var contents: any = msgcontents;
    var content: any = "";
    const fields: any = [
      "#fromdate",
      "#todate",
      "#textfield1",
      "#textfield2",
      "#textfield3"
    ];
    if (type && contents[type]) {
      content = contents[type][0];
      const contentsplit = content.split(" ");
      fields.map((word: any, index: any) => {
        if (contentsplit.includes(word)) {
          var inputfield: any = "";
          if (word == "#fromdate") {
            inputfield = `${msgformData.fromdate}`;
            setfromdatevisible(true);
          } else if (word == "#todate") {
            inputfield = `${msgformData.todate}`;
            settodatevisible(true);
          } else if (word == "#textfield1") {
            inputfield = `${msgformData.info1}`;
            setinfo1visible(true);
          } else if (word == "#textfield2") {
            inputfield = `${msgformData.info2}`;
            setinfo2visible(true);
          } else if (word == "#textfield3") {
            inputfield = `${msgformData.info3}`;
            setinfo3visible(true);
          }
        }
        const replacedata: any = content.replace(word, inputfield);
        content = replacedata;
      });
    }
    setmsgprint(content);
  };

  const onDateChange = (name: any, event: any) => {
    clearErrors(name);
    setValue(name, event);
    var date = formatDate(event);
    setmsgFormData(prevData => ({
      ...prevData,
      [name]: date
    }));
  };

  const msgreplaceword = (e: any) => {
    const {name, value} = e.target;
    var inputvalue = dotedline;
    if (value) {
      inputvalue = value;
    }
    setmsgFormData(prevData => ({
      ...prevData,
      [name]: inputvalue
    }));
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    var vehicleId = data.vehicleId;
    var routeId = data.routeId;
    var type = data.type;
    var fromdate = data.fromdate;
    var todate = data.todate;
    var info1 = data.info1;
    var info2 = data.info2;
    var info3 = data.info3;

    var msgtype = 2;
    var id = vehicleId;
    if (vehicleId != "" && routeId != "") {
      msgtype = 1;
      id = routeId;
    }

    var requestdata = {type: msgtype, id: id, msg: msgprint};
    await AnnouncementService.sendsnnouncements(requestdata)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          handleReset();
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch(err => {
        toast.error("Sorry!, We have technical issue please try again later");
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-5">
      <div className="mb-5 flex-row">
        <NeoHeader message="Announcements"></NeoHeader>
      </div>
      <div className="grid w-full grid-cols-2 flex-row">
        <div className="">
          <NeoCard className="mb-5">
            <CardHeader></CardHeader>
            <CardBody className="min-h-[calc(100vh-180px)]">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid w-full grid-cols-2 gap-9 max-sm:grid-cols-1">
                  <Controller
                    name="vehicleId"
                    control={control}
                    render={({field}) => (
                      <NeoSelect
                        {...field}
                        label="Vehicle"
                        id="vehicleId"
                        placeholder="Please select vehicle"
                        color={errors.vehicleId ? "danger" : "default"}
                        isInvalid={errors.vehicleId ? true : false}
                        errorMessage={
                          errors.vehicleId && `${errors.vehicleId.message}`
                        }
                        {...register("vehicleId")}
                        onChange={VehicleChange}
                      >
                        {vehicles?.map((e: any, index: number) => (
                          <SelectItem key={e.trackerId} value={e.trackerId}>
                            {e.trackerName}
                          </SelectItem>
                        ))}
                      </NeoSelect>
                    )}
                  />
                  <Controller
                    name="routeId"
                    control={control}
                    render={({field}) => (
                      <NeoSelect
                        {...field}
                        label="Route (Optional)"
                        id="routeId"
                        placeholder="Please select route"
                        {...register("routeId")}
                        onChange={RouteChange}
                      >
                        {routes?.map((e: any, index: number) => (
                          <SelectItem key={e.routeId} value={e.routeId}>
                            {e.routeName}
                          </SelectItem>
                        ))}
                      </NeoSelect>
                    )}
                  />
                </div>
                <div className="grid w-full grid-cols-2 gap-9 pt-5 max-sm:grid-cols-1">
                  <Controller
                    name="type"
                    control={control}
                    render={({field}) => (
                      <NeoSelect
                        {...field}
                        label="Type"
                        id="type"
                        placeholder="Please select type"
                        color={errors.type ? "danger" : "default"}
                        isInvalid={errors.type ? true : false}
                        errorMessage={errors.type && `${errors.type.message}`}
                        selectedKeys={[msgdefaultselect]}
                        onChange={MsgChange}
                      >
                        {Announcements?.map((e: any, index: number) => (
                          <SelectItem key={e.type} value={e.type}>
                            {e.type}
                          </SelectItem>
                        ))}
                      </NeoSelect>
                    )}
                  />
                </div>
                <div
                  className={`grid w-full grid-cols-2 gap-9 pt-5 max-sm:grid-cols-1 ${fromdatevisible || todatevisible ? "block" : "hidden"}`}
                >
                  <div className={fromdatevisible ? "block" : "hidden"}>
                    <Controller
                      name="fromdate"
                      control={control}
                      render={({
                        field: {ref, value, onChange, ...fieldRest}
                      }) => (
                        <NeoDatePicker
                          {...fieldRest}
                          label="From Date"
                          placeholder="Please select from date"
                          errorMessage={
                            errors.fromdate && `${errors.fromdate.message}`
                          }
                          minDate={mindate}
                          value={value}
                          handleDateChange={(e: any) => {
                            onChange(e);
                            onDateChange("fromdate", e);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className={todatevisible ? "block" : "hidden"}>
                    <Controller
                      name="todate"
                      control={control}
                      render={({
                        field: {ref, value, onChange, ...fieldRest}
                      }) => (
                        <NeoDatePicker
                          {...fieldRest}
                          label="To Date"
                          placeholder="Please select to date"
                          errorMessage={
                            errors.todate && `${errors.todate.message}`
                          }
                          minDate={mindate}
                          value={value}
                          handleDateChange={(e: any) => {
                            onChange(e);
                            onDateChange("todate", e);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  className={`grid w-full grid-cols-2 gap-9 pt-5 max-sm:grid-cols-1 ${info1visible || info2visible || info3visible ? "block" : "hidden"}`}
                >
                  <div className={info1visible ? "block" : "hidden"}>
                    <Controller
                      name="info1"
                      control={control}
                      render={({field}) => (
                        <NeoTextBox
                          {...field}
                          label="Info"
                          placeholder="Please enter message"
                          id="info1"
                          color={errors.info1 ? "danger" : "default"}
                          isInvalid={errors.info1 ? true : false}
                          errorMessage={
                            errors.info1 && `${errors.info1.message}`
                          }
                          autoComplete="off"
                          {...register("info1")}
                          onChange={e => {
                            field.onChange(e);
                            msgreplaceword(e);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className={info2visible ? "block" : "hidden"}>
                    <Controller
                      name="info2"
                      control={control}
                      render={({field}) => (
                        <NeoTextBox
                          {...field}
                          label="Info"
                          placeholder="Please enter message"
                          id="info2"
                          color={errors.info2 ? "danger" : "default"}
                          isInvalid={errors.info2 ? true : false}
                          errorMessage={
                            errors.info2 && `${errors.info2.message}`
                          }
                          autoComplete="off"
                          {...register("info2")}
                          onChange={e => {
                            field.onChange(e);
                            msgreplaceword(e);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className={info3visible ? "block" : "hidden"}>
                    <Controller
                      name="info3"
                      control={control}
                      render={({field}) => (
                        <NeoTextBox
                          {...field}
                          label="Info"
                          placeholder="Please enter message"
                          id="info3"
                          color={errors.info3 ? "danger" : "default"}
                          isInvalid={errors.info3 ? true : false}
                          errorMessage={
                            errors.info3 && `${errors.info3.message}`
                          }
                          autoComplete="off"
                          {...register("info3")}
                          onChange={e => {
                            field.onChange(e);
                            msgreplaceword(e);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="grid w-full grid-cols-1 gap-9 pt-5 max-sm:grid-cols-1">
                  <h4>Message</h4>
                </div>
                <div className="grid w-full grid-cols-1 gap-9 pl-5 pt-1 max-sm:grid-cols-1">
                  <p>{msgprint}</p>
                </div>
                {errorMessage ? (
                  <NeoError message={errorMessage}></NeoError>
                ) : null}
                <div className="flex items-center justify-end p-4">
                  <NeoButton
                    className="mx-5 mt-2"
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={loading}
                    spinner={
                      <AiOutlineLoading
                        size="xs"
                        className="h-6 w-6 animate-spin"
                      />
                    }
                    color="black"
                  >
                    Submit
                  </NeoButton>
                  <NeoButton className="mt-2" type="reset" color="white">
                    Cancel
                  </NeoButton>
                </div>
              </form>
            </CardBody>
          </NeoCard>
        </div>
        {/*<div className="ml-2">
          <NeoCard className="mb-5">
            <CardHeader>Users Details</CardHeader>
            <CardBody className="min-h-[calc(100vh-205px)]">
              <div className="flex-row">
                <NeoTable table={datas.table}></NeoTable>
              </div>
            </CardBody>
          </NeoCard>
        </div>*/}
      </div>
    </div>
  );
};
