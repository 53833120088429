export const NeoName: React.FC<any> = ({data, cellValue}) => {
  const firstLetter = cellValue.charAt(0).toUpperCase();
  return (
    <>
      <div className="flex items-center">
        {/* <p className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 text-xl font-medium leading-none text-white">
          {firstLetter}
        </p> */}
        <span className="pl-2">{cellValue}</span>
      </div>
    </>
  );
};
