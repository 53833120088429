"use client";

import {sessionSignIn} from "@/service/login.service";
import {NextPage} from "next";
import {useEffect} from "react";

interface ManageSesionProps {
  params: PageProps;
}
interface PageProps {
  token: number | string;
}
export const SessionLogin: NextPage<ManageSesionProps> = function ({params}) {
  useEffect(() => {
    sessionSignIn(params.token);
  }, [params.token]);
  return (
    <>
      <div className="">Loading...</div>
    </>
  );
};
