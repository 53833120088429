"use client";
import {LiveFilter} from "@/components/@filter/live-filter";
import {NeoModal} from "@/components/@modal/neo.modal";
import {
  NeoButton,
  NeoDatePicker,
  NeoSelect,
  NeoTextBox
} from "@/components/forms";
import {
  NeoButtonGroup,
  NeoChips,
  NeoTabs,
  SearchIcon
} from "@/components/shared";

import {useLiveContext} from "@/context/live/live.context";
import {useNeoContext} from "@/context/neo/neo.context";
import {LiveConfig} from "@/data";
import {
  GPSTracker,
  PageType,
  PaginationAction,
  PlayBackType,
  StatusType
} from "@/libs/enums/live";

import {
  Avatar,
  Button,
  Chip,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectItem,
  Spinner,
  Tab,
  Tabs,
  Tooltip
} from "@nextui-org/react";
import type {NextPage} from "next";
import {useRouter} from "next/navigation";
import {useEffect, useRef, useState} from "react";
import {MapView} from "./map-view";
import {TimelineView} from "./timeline-view";
import {VehicleView} from "./vehicle-view";
const liveConfig = LiveConfig as LivePageConfig;

export const Live: NextPage = function () {
  const router = useRouter();
  const leftContent = useRef<HTMLDivElement>(null);
  const rightContent = useRef<HTMLDivElement>(null);
  const [hideFeatures, setHideFeatures] = useState(true);
  const {filterContext, clients} = useNeoContext();
  const [leftContentHeight, setLeftContentHeight] = useState(0);
  const [rightContentHeight, setRightContentHeight] = useState(0);
  const {countDown, setGpsTracker, meta, vehicleRoutes} = useLiveContext();
  const [viewTitle, setViewTitle] = useState<string>("Maps");
  const [selectedPage, setSelectedPage] = useState<PageType>(PageType.LIVE);
  const reload = useRef(false);
  const [vehicleSelctedRoute, setVehicleSelctedRoute] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<StatusType>(
    StatusType.ALL
  );
  const [selectedView, setSelectedView] = useState<PlayBackType>(
    PlayBackType.MAPS
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    from: null,
    to: null
  });
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 10
  });
  const {filterValues, setFilterValues} = useLiveContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [remainderData, setRemainderData] = useState();
  const [actionType, setActionType] = useState<string>("");

  useEffect(() => {
    if (leftContent.current)
      setLeftContentHeight(leftContent?.current?.clientHeight);
    if (rightContent.current)
      setRightContentHeight(rightContent.current.clientHeight);
  }, [selectedPage]);

  useEffect(() => {
    setVehicleSelctedRoute("");
  }, [vehicleRoutes]);

  const onDateChange = (event: any) => {
    setSelectedDateRange(event);
  };

  const handleSearch = (value: any) => {
    setFilterValues(prev => ({
      ...prev,
      searchText: value
    }));
  };

  const handleFilter = (ev: any) => {
    setFilterValues(prev => ({
      ...prev,
      driverId: ev?.driver,
      vehicleId: ev?.vehicle
    }));
  };
  useEffect(() => {
    if (clients.length > 0) {
      if (clients[0]["paymentReminder"]) {
        setModalOpen(true);
        setRemainderData(clients[0]["paymentReminder"]);
      }
    }
  }, [clients]);
  useEffect(() => {
    setFilterValues(prev => ({
      ...prev,
      currentPage: pagination.currentPage,
      totalPage: pagination.totalPages
    }));
  }, [pagination]);

  useEffect(() => {
    if (meta) {
      liveConfig.statusType.map(x => {
        switch (x.key) {
          case 0:
            return (x.count = meta.totalVehicles);
          case 1:
            return (x.count = meta.inProgressTrips);
          case 2:
            return (x.count = meta.completedTrips);
          default:
            return (x.count = meta.notInTrips);
        }
      });
    }
  }, [meta]);

  const handlePagination = (action: PaginationAction) => {
    if (
      action === PaginationAction.FORWARD &&
      pagination.currentPage < pagination.totalPages
    ) {
      setPagination(prev => ({...prev, currentPage: prev.currentPage + 1}));
    } else if (
      action === PaginationAction.BACKWARD &&
      pagination.currentPage > 1
    ) {
      setPagination(prev => ({...prev, currentPage: prev.currentPage - 1}));
    }
  };

  // useEffect(() => {
  //   const handleReload = () => {
  //     if (
  //       !document.hidden &&
  //       document.visibilityState === "visible" &&
  //       !reload.current
  //     ) {
  //       console.warn("online");
  //       reload.current = true;
  //       setGpsTracker(GPSTracker.START)
  //       //window.location.reload();
  //     } else {
  //       reload.current = false;
  //       setGpsTracker(GPSTracker.STOP)
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleReload);
  //   // window.addEventListener("focus", handleReload);
  //   // return () => {
  //   //   document.removeEventListener("visibilitychange", handleReload);
  //   //   window.removeEventListener("focus", handleReload);
  //   // };
  // }, [router]);

  return (
    <div className="flex h-[calc(100vh-63px)] w-full overflow-hidden">
      <div className=" flex w-full flex-col md:flex-row">
        <div className="w-6/6 flex h-full flex-col border-l border-r border-gray-200  xl:w-3/12">
          <div
            ref={leftContent}
            className="sticky top-0 border-b border-gray-200 py-4"
          >
            {hideFeatures && (
              <div className="flex items-center justify-center gap-2 p-1">
                {/* {selectedStatus == 0 && ( */}
                <FilterPopover onSubmit={handleFilter} />
                {/* )} */}
                <NeoTextBox
                  isClearable
                  className="w-full pr-2"
                  //placeholder="Search"
                  value={filterValues?.searchText}
                  onChange={e => handleSearch(e?.target?.value)}
                  onClear={() => handleSearch("")}
                  size="sm"
                  startContent={<SearchIcon />}
                />
                <div className="col-span-1 px-3">
                  <NeoButtonGroup radius="sm">
                    <NeoButton
                      color={
                        selectedPage === PageType.LIVE ? "success" : "secondary"
                      }
                      size="sm"
                      radius="sm"
                      onClick={() => {
                        setViewTitle("Live");
                        setSelectedPage(PageType.LIVE);
                        setSelectedStatus(StatusType.ALL);
                        setGpsTracker(GPSTracker.STOP);
                      }}
                    >
                      Live
                    </NeoButton>
                    <NeoButton
                      color={
                        selectedPage === PageType.PLAYBACK
                          ? "success"
                          : "secondary"
                      }
                      size="sm"
                      radius="sm"
                      onClick={() => {
                        setViewTitle("Playback");
                        setSelectedPage(PageType.PLAYBACK);
                        setGpsTracker(GPSTracker.PLAYBACK);
                      }}
                    >
                      Playback
                    </NeoButton>
                  </NeoButtonGroup>
                </div>

                {/* <div className="flex items-center gap-2">
                  <Button
                    onClick={() => handlePagination(PaginationAction.BACKWARD)}
                    isIconOnly
                    variant="light"
                    className="w-2 min-w-max"
                  >
                    <IoIosArrowBack />
                  </Button>
                  <Label
                    className="whitespace-nowrap text-xs"
                    value={`${pagination.currentPage} / ${pagination.totalPages}`}
                  />
                  <Button
                    onClick={() => handlePagination(PaginationAction.FORWARD)}
                    isIconOnly
                    variant="light"
                    className="w-2 min-w-max"
                  >
                    <IoIosArrowForward />
                  </Button>
                </div> */}
              </div>
            )}
            {selectedPage === PageType.LIVE && (
              <div className="flex w-full items-center justify-center overflow-y-auto  bg-gray-100 p-1">
                <Tabs
                  aria-label="Options"
                  color="primary"
                  variant="underlined"
                  defaultSelectedKey={selectedStatus}
                  onSelectionChange={key => {
                    setSelectedStatus(key as StatusType);
                    setGpsTracker(GPSTracker.STOP);
                  }}
                  classNames={{
                    tabList:
                      "gap-0 w-full items-center rounded-none p-0 border-b bg-gray-100",
                    cursor: "w-full bg-neo-green",
                    tab: " h-12 ", // px-1
                    tabContent: "group-data-[selected=true]:text-neo-green"
                    // tab: "bg-gray-200 h-14 data-[selected=true]:bg-white",
                    //tabList: "bg-gray-100 gap-0 p-0 rounded",
                    // cursor: "w-full bg-neo-green top-0"
                  }}
                >
                  {liveConfig?.statusType?.map(item => (
                    <Tab
                      key={item?.key}
                      title={
                        <div className="flex flex-col  space-x-2">
                          <span className="text-xs text-neo-black">
                            {(item?.status === "all" && "All") ||
                              (item?.status === "in-progress" && "On Trip") ||
                              (item?.status === "completed" && "Completed") ||
                              (item?.status === "off-trip" && "Not In Trip")}
                          </span>
                          <Chip
                            size="sm"
                            color={
                              (item?.status === "all" && "default") ||
                              (item?.status === "in-progress" && "primary") ||
                              (item?.status === "completed" && "success") ||
                              (item?.status === "off-trip" && "warning") ||
                              "default"
                            }
                          >
                            {item?.count ? item?.count : 0}
                          </Chip>
                        </div>
                      }
                    />
                  ))}
                </Tabs>
              </div>
            )}
            {/* <div className="mb-2 flex justify-between">
              <div className="col-span-1 px-3">
                <p className="text-lg">{liveConfig?.title}</p>
              </div>
              <div className="col-span-1 px-3">
                <NeoButtonGroup radius="sm">
                  <NeoButton
                    color={
                      selectedPage === PageType.LIVE ? "success" : "secondary"
                    }
                    size="sm"
                    radius="sm"
                    onClick={() => {
                      setViewTitle("Live");
                      setSelectedPage(PageType.LIVE);
                      setSelectedStatus(StatusType.ALL);
                      setGpsTracker(GPSTracker.STOP);
                    }}
                  >
                    Live
                  </NeoButton>
                  <NeoButton
                    color={
                      selectedPage === PageType.PLAYBACK
                        ? "success"
                        : "secondary"
                    }
                    size="sm"
                    radius="sm"
                    onClick={() => {
                      setViewTitle("Playback");
                      setSelectedPage(PageType.PLAYBACK);
                      setGpsTracker(GPSTracker.PLAYBACK);
                    }}
                  >
                    Playback
                  </NeoButton>
                </NeoButtonGroup>
              </div>
            </div> */}
          </div>
          <div
            className="flex-grow overflow-y-auto"
            style={{height: `calc(100% - ${leftContentHeight}px)`}}
          >
            <VehicleView
              page={selectedPage}
              client={filterContext.client}
              status={selectedStatus}
            />
          </div>
        </div>
        <div className="h-full md:flex-1">
          <div
            ref={rightContent}
            className="border-b border-gray-200 bg-neo-white py-1 dark:bg-neo-black"
          >
            <div className="flex items-center px-10">
              <div className="w-1/2">
                {hideFeatures && (
                  <NeoTabs
                    defaultSelectedKey={selectedView}
                    items={liveConfig?.playBackType}
                    onSelectionChange={(key: any) => {
                      setGpsTracker(GPSTracker.STOP);
                      if (key) setSelectedView(key as PlayBackType);
                    }}
                    variant="solid"
                    className="py-3"
                    size="sm"
                  >
                    {liveConfig?.playBackType?.map(item => (
                      <Tab
                        key={item?.key}
                        title={
                          <div className="flex flex-col">
                            <span>{item?.label}</span>
                          </div>
                        }
                      ></Tab>
                    ))}
                  </NeoTabs>
                )}
              </div>
              <div id="PlayBackHistory" className="w-1/2 gap-2">
                <div className="flex">
                  <div className="flex w-full items-center gap-2">
                    {selectedPage === PageType.PLAYBACK &&
                      selectedView == 1 && (
                        <NeoDatePicker
                          range
                          showTime
                          type="icon"
                          handleDateChange={onDateChange}
                        />
                      )}
                    {(selectedPage === PageType.PLAYBACK ||
                      selectedView == 2) && (
                      <NeoSelect
                        placeholder="Routes"
                        labelPlacement="outside"
                        aria-label="routes"
                        classNames={{
                          trigger: "border-none bg-gray-500/50"
                        }}
                        size="sm"
                        onChange={e => setVehicleSelctedRoute(e.target.value)}
                        selectedKeys={
                          vehicleSelctedRoute ? [vehicleSelctedRoute] : []
                        }
                      >
                        {vehicleRoutes?.trips?.map((item, index) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.name}
                          </SelectItem>
                        ))}
                      </NeoSelect>
                    )}
                  </div>
                  {/* Routes Feature currently hide, if May be its needed, must we change the css in above div  id="PlayBackHistory"  'grid-cols-1' into 'grid-cols-2'   */}
                  <div></div>
                </div>
                <div className="col-span-2 flex items-center justify-end">
                  <div className="flex">
                    {liveConfig?.chips?.map((item, index) => (
                      <Tooltip content={item.label} key={index} offset={-3}>
                        <NeoChips
                          key={index}
                          className="mt-1 cursor-pointer dark:text-white"
                          variant="dot"
                          color={item.value ?? undefined}
                          avatar={
                            item.image ? (
                              <Avatar
                                name="JW"
                                src="/images/boarding_change.png"
                              />
                            ) : (
                              ""
                            )
                          }
                        >
                          {item.label}
                        </NeoChips>
                      </Tooltip>
                    ))}
                  </div>
                  <div className="h-7 w-7">
                    {countDown < 6 && (
                      <Spinner
                        classNames={{
                          wrapper: "h-7 w-7"
                        }}
                        className="gap-0"
                        color="success"
                      >
                        <span className="absolute left-[37%] top-[13%] text-xs ">
                          {countDown}
                        </span>
                      </Spinner>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{height: `calc(100% - ${rightContentHeight}px)`}}>
            {selectedView == 1 ? (
              <MapView
                page={selectedPage}
                dateRange={selectedDateRange}
                tabStatus={selectedStatus}
                vehicleSelctedRoute={vehicleSelctedRoute}
              ></MapView>
            ) : (
              <TimelineView
                client={filterContext.client}
                vehicleSelctedRoute={vehicleSelctedRoute}
              ></TimelineView>
            )}
          </div>
        </div>
      </div>
      <NeoModal
        actionType={"paymentReminder"}
        onOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        hasActions={false}
        data={remainderData}
      ></NeoModal>
    </div>
  );
};

const FilterPopover: NextPage<{onSubmit: (data: any) => void}> = ({
  onSubmit
}) => {
  const {filterValues} = useLiveContext();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopOnsubmit = (data: any) => {
    onSubmit(data);
    setIsPopoverOpen(false);
  };
  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };
  const validatePopover = () => {
    const filteredNames = filterValues.vehicleId as string;
    let filteredArray: any = [];
    if (Array.isArray(filterValues?.vehicleId && filterValues?.vehicleId)) {
      filteredArray = filteredNames;
    } else if (
      filterValues?.vehicleId &&
      filterValues?.vehicleId.length > 0 &&
      filteredNames !== ""
    ) {
      filteredArray = filteredNames.split(",");
    }
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  };
  return (
    //isOpen={isPopoverOpen}
    <Popover
      placement="bottom-start"
      isOpen={isPopoverOpen}
      key={"opaque"}
      showArrow
      offset={10}
      backdrop={"opaque"}
    >
      <PopoverTrigger>
        <Button
          isIconOnly
          size="sm"
          variant={validatePopover() ? "solid" : "light"}
          onClick={togglePopover}
          className="w-1 flex-none"
        >
          <Image
            className="h-6 w-6 rounded-none"
            alt="filter"
            src="/icons/filter.svg"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Button
          isIconOnly
          size="sm"
          variant="light"
          onClick={togglePopover}
          className="absolute right-2 top-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </Button>
        <LiveFilter onSubmit={e => closePopOnsubmit(e)} />
      </PopoverContent>
    </Popover>
  );
};
