"use client";

import {isDesktop, isMobile} from "@/libs/helper/device";
import type {FC, PropsWithChildren} from "react";
import {createContext, useContext, useEffect, useState} from "react";

interface SidebarContextProps {
  isCollapsed: boolean;
  setCollapsed: (isOpen: boolean) => void;
}

const SidebarContext = createContext<SidebarContextProps>(
  {} as SidebarContextProps
);
export const useSidebarContext = () =>
  useContext<SidebarContextProps>(SidebarContext);

export const SidebarContextProvider: FC<PropsWithChildren> = function ({
  children
}) {
  const location = isDesktop()
    ? typeof window !== "undefined"
      ? window.location.pathname
      : "/"
    : "/";
  const storedIsCollapsedString =
    typeof window !== "undefined"
      ? localStorage.getItem("isSidebarCollapsed")
      : "true";
  const storedIsCollapsed = storedIsCollapsedString
    ? JSON.parse(storedIsCollapsedString)
    : false;

  const [isCollapsed, setCollapsed] = useState(storedIsCollapsed);
  useEffect(() => {
    if (isMobile()) {
      setCollapsed(true);
    }
  }, [location]);

  useEffect(() => {
    function handleMobileTapInsideMain(event: MouseEvent) {
      const main = document.querySelector("#neo-content");
      const isClickInsideMain = main?.contains(event.target as Node);

      if (isMobile() && isClickInsideMain) {
        setCollapsed(true);
      }
    }

    document.addEventListener("mousedown", handleMobileTapInsideMain);

    return () => {
      document.removeEventListener("mousedown", handleMobileTapInsideMain);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("isSidebarCollapsed", isCollapsed ? "true" : "false");
  }, [isCollapsed]);

  return (
    <SidebarContext.Provider
      value={{
        isCollapsed,
        setCollapsed
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
