"use client";
import {axiosInstance} from "@/app/api";
import {
  calcTime,
  convertMTOKM,
  formatDateCommon,
  onGetTableResponse
} from "@/libs/helper";

const onGetDashboardDatas = async (requestdata: any): Promise<any> => {
  return await axiosInstance()
    .post(`/api/dashboard`, requestdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      throw error;
    });
};

const onGetDashboardReportData = async (
  url: string,
  body: any
): Promise<any> => {
  const TripStatusData: any = {
    "1": "YET TO START",
    "2": "IN PROGRESS",
    "3": "COMPLETED",
    "4": "NOT COMPLETED"
  };
  const StatusData: any = {"1": "Active", "2": "Expired", "3": "No Data"};

  const AttendanceStatusData: any = {"0": "Absent", "1": "Present"};
  return await axiosInstance()
    .post(url, body)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<any> = response.data;
        var endPointData = neoresponse?.data?.reportData;

        const rows: any = [];
        endPointData.forEach((row: any) => {
          if (row?.date) {
            row.date = formatDateCommon(row?.date); //change the format like Dec
          } else {
            row.date = "N/A";
          }

          /*Vehicle Status Response Value Convertion Start*/
          if (row?.lastContactTime) {
            const lastContactTime = calcTime(row?.lastContactTime);
            const [datePart, timePart] = lastContactTime.split(", ");
            row.lastContactTime = timePart;
          } else {
            row.lastContactTime = "N/A";
          }
          /*Vehicle Status Response Value Convertion End*/

          /*Trip Status Response Value Convertion Start*/
          if (row?.tripStartTime) {
            const tripStartTime = calcTime(row?.tripStartTime);
            const [datePart, timePart] = tripStartTime.split(", ");
            row.tripStartTime = timePart;
          } else {
            row.tripStartTime = "N/A";
          }

          if (row?.tripEndTime) {
            const tripEndTime = calcTime(row?.tripEndTime);
            const [datePart, timePart] = tripEndTime.split(", ");
            row.tripEndTime = timePart;
          } else {
            row.tripEndTime = "N/A";
          }

          if (row?.tripType && row?.tripType == 1) {
            row.tripType = "Drop";
          } else {
            row.tripType = "Pickup";
          }

          if (row?.tripStatus && TripStatusData[row?.tripStatus]) {
            const tripStatus = TripStatusData[row?.tripStatus];
            row.tripStatus = tripStatus;
          } else {
            row.tripStatus = "N/A";
          }
          /*Trip Status Response Value Convertion End*/

          /*Distance Driven Response Value Convertion Start*/
          if (row?.scheduled) {
            row.scheduled = convertMTOKM(row?.scheduled);
          } else {
            row.scheduled = "N/A";
          }

          if (row?.unScheduled) {
            row.unScheduled = convertMTOKM(row?.unScheduled);
          } else {
            row.unScheduled = "N/A";
          }
          /*Distance Driven Response Value Convertion End*/

          /*Distance Deviation Response Value Convertion Start*/
          if (row?.totalDistance) {
            row.totalDistance = convertMTOKM(row?.totalDistance);
          } else {
            row.totalDistance = "N/A";
          }

          if (row?.deviationTime && row?.threshold) {
            row.threshold = convertMTOKM(row?.threshold);
          }

          if (row?.deviationTime) {
            const deviationTime = calcTime(row?.deviationTime);
            const [datePart, timePart] = deviationTime.split(", ");
            row.deviationTime = timePart;
          } else {
            row.deviationTime = "N/A";
          }

          /*Distance Deviation Response Value Convertion Start*/

          /*Bus Attendance Response Value Convertion Start*/
          if (row?.swipes && row?.status && AttendanceStatusData[row?.status]) {
            row.status = AttendanceStatusData[row?.status];
          } else if (
            row?.swipes &&
            row?.status == 0 &&
            AttendanceStatusData[row?.status]
          ) {
            row.status = AttendanceStatusData[row?.status];
          }
          /* status Condition for Permit , Insurance Start*/
          if (row?.validUpto && StatusData[row?.status]) {
            const validUpto = formatDateCommon(row?.validUpto);
            const tripStatus = StatusData[row?.status];
            row.status = tripStatus;
            row.validUpto = validUpto;
          } else {
            row.status = "N/A";
          }
          /* status Condition for Permit , Insurance End*/

          let in_pick_time = "";
          let out_drop_time = "";
          if (row?.swipes) {
            row?.swipes.forEach((swipedatas: any) => {
              let time = "";
              let vehicleName = "";
              const triptype = swipedatas?.type;

              if (swipedatas?.time) {
                const swipetime = calcTime(swipedatas?.time);
                const [datePart, timePart] = swipetime.split(", ");
                time = timePart;
              }
              if (swipedatas?.vehicleName) {
                vehicleName = swipedatas?.vehicleName;
              }

              let swipes = "";
              if (swipedatas?.vehicleName && time) {
                swipes = `${vehicleName} (${time})`;
              } else if (time) {
                swipes = time;
              }

              if (triptype == 1) {
                out_drop_time = swipes;
              } else {
                in_pick_time = swipes;
              }
            });
            row.in_pick_time = in_pick_time;
            row.out_drop_time = out_drop_time;
          }
          /*Bus Attendance Response Value Convertion End*/
          rows.push({...row});
        });
        return {
          columns: neoresponse?.data?.columns,
          rows: rows,
          meta: neoresponse?.meta
        } as TableResponse<any>;
      }
      return onGetTableResponse();
    })
    .catch((error: any) => {
      return onGetTableResponse();
    });
};

const onGetSettingDatas = async (): Promise<any> => {
  return await axiosInstance()
    .get(`/api/user/settings`)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      throw error;
    });
};

const onSaveUserSetting = async (requestdata: any): Promise<any> => {
  return await axiosInstance()
    .post(`/api/save/user/settings`, requestdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      throw error;
    });
};

export const NeoDashboardService = {
  onGetDashboardDatas,
  onGetDashboardReportData,
  onGetSettingDatas,
  onSaveUserSetting
};
