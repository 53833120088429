import {EyeIcon} from "@/components/shared";
import dynamic from "next/dynamic";
import React from "react";
import Loader from "../Loader";
const Chart = dynamic(() => import("react-apexcharts"), {ssr: false});

interface VehicleStatusProps {
  isLoading: boolean;
  fleetChartKey: string | number;
  fleetOptions: any;
  fleetSeries: any;
  openPopup: (title: popupParams) => void;
}

const VehicleStatusWidget: React.FC<VehicleStatusProps> = ({
  isLoading,
  fleetChartKey,
  fleetOptions,
  fleetSeries,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="grid-cols grid w-full flex-row">
            <div className="flex items-center justify-between p-2">
              <h5 className="text-lg font-semibold dark:text-neo-white">
                Vehicle Status
              </h5>
              <h5
                className="cursor-pointer pr-1"
                title="View"
                onClick={() => openPopup({title: "Vehicle Status"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex items-center justify-center">
              <Chart
                key={fleetChartKey}
                options={fleetOptions}
                series={fleetSeries}
                type="donut"
                width="320"
                height="250"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleStatusWidget;
