"use client";
import {NeoTable} from "@/components/table";
interface REPORTMEUSCONFIG {
  reportdata: {
    header: string;
    description: string;
    order: number;
    activeReport?: {
      icon: string;
      menu: string;
      menu_description: string;
      url: string;
      method: string;
      filters: any;
      columns?: any;
      activeColumns?: any;
    };
    menus: {
      icon: string;
      menu: string;
      menu_description: string;
      url: string;
      method: string;
      filters: any;
      columns?: any;
      activeColumns?: any;
    }[];
  };
  // Add other properties as needed
}
export const ReportUi: React.FC<REPORTMEUSCONFIG> = ({reportdata}) => {
  const datas: NeoTableConfig = {
    title: reportdata?.activeReport?.menu
      ? reportdata?.activeReport?.menu
      : "Report",
    table: {
      columns: reportdata?.activeReport?.columns,
      activeColumns: reportdata?.activeReport?.activeColumns,
      activeFilters: reportdata?.activeReport?.filters,
      style: {
        height: "h-full"
      },
      meta: {
        id: 4,
        title: reportdata?.activeReport?.menu
          ? reportdata?.header + " ( " + reportdata?.activeReport?.menu + " ) "
          : "Report",
        description: "Total Data",
        hasSearch: true,
        hasUpload: false,
        hasDownload: false,
        hasAdd: false,
        hasActions: false,
        hasCheckbox: false,
        hasFilter: reportdata?.activeReport?.filters.length ? true : false,
        hasFooter: true,
        hasHeader: true,
        hasPagination: true,
        hasCreate: false
      },
      url: {
        page: {},
        api: {
          get: reportdata?.activeReport?.url
        }
      },
      actions: []
    }
  };
  const handleFilterSubmit = (req: any) => {};
  return (
    <>
      <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
        {reportdata?.activeReport?.columns.length > 0 && (
          <NeoTable table={datas.table}></NeoTable>
        )}
      </div>
    </>
  );
};
