"use client";

import {axiosInstance} from "@/app/api";
import {UserFormConfig, UserViewConfig} from "@/data";
import {TransportationType} from "@/libs/enums/users";
import {mapFields, onGetTableResponse} from "@/libs/helper";

const onGetUser = async (id: string): Promise<any> => {
  const user = UserViewConfig;
  return await axiosInstance()
    .get(`/api/users/read?userid=${id}`)
    .then((response: any) => {
      if (response.data.status === "success") {
        const neoresponse: NeoResponse<IUserView> = response.data;
        if (neoresponse && neoresponse.data) {
          neoresponse.data.rfid = neoresponse.data.rfid == true ? "Yes" : "No";

          const pickup = neoresponse.data.vehicles
            .filter(
              item =>
                item.type === TransportationType.Pickup ||
                item.type === TransportationType.Both
            )
            .map(x => x.trackerName);
          const drop = neoresponse.data?.vehicles
            .filter(
              item =>
                item.type === TransportationType.Drop ||
                item.type === TransportationType.Both
            )
            .map(x => x.trackerName);
          neoresponse.data.pickup = pickup;
          neoresponse.data.drop = drop;
          const userType = neoresponse.data?.userType
            ? neoresponse.data?.userType === "Parent" ||
              neoresponse.data?.userType === "Student"
              ? "student"
              : neoresponse.data?.userType
            : neoresponse.data?.className
              ? "student"
              : "employee";
          const cards = user.cards.filter(
            x => x.type == userType || x.type == "user"
          );
          user.cards = cards.map(card => ({
            ...card,
            fields: mapFields([neoresponse.data], card.fields as TNeoField[])
          }));
        }
        return user;
      } else {
        return user;
      }
    })
    .finally(() => {
      return user;
    });
};

const onFetchUserDetails = async (
  id: string,
  action: string,
  slug: string
): Promise<any> => {
  const formView = UserFormConfig;
  if (action === "create") {
    return {
      userId: "",
      data: slug === "user" ? {} : slug === "employee" ? {} : {}
    };
  }
  return await axiosInstance()
    .get(`/api/users/read?userid=${id}`)
    .then((response: any) => {
      const data = response.data.data;
      if (response.data.status === "success") {
        data.gender =
          data.gender.toLowerCase() === "male"
            ? "1"
            : data.gender.toLowerCase() === "female"
              ? "2"
              : "";
        if (slug === "user") {
          formView.user.name = data?.name;
          formView.user.gender = data?.gender;
          formView.user.parentName = data?.parentName;
          formView.user.phoneNumber = data?.userPhone;
          formView.user.email = data?.email;
          formView.user.emergencyContactNo = data?.contactNo;
          formView.user.destination = data?.stop;
          formView.user.rfidNo = data?.rfidNo;
          formView.user.rfid = data?.rfid;
          formView.user.userRole = data?.role;
          formView.user.userType = data?.type;
          formView.user.admissionNo = data?.admissionNo;
          formView.user.className = data?.className;
          formView.user.vehicles = data?.vehicles;
          return {
            userId: data?.userId,
            data: formView.user
          };
        } else if (slug === "student") {
          formView.student.userId = data?.userId;
          formView.student.studentName = data?.name;
          formView.student.studentId = data?.studentId;
          formView.student.className = data?.className;
          formView.student.sectionName = data?.sectionName;
          formView.student.admissionNo = data?.admissionNo;
          formView.student.pickup = data?.vehicles?.filter(
            (vehicle: any) =>
              vehicle.type == TransportationType.Pickup ||
              vehicle.type == TransportationType.Both
          );
          formView.student.drop = data?.vehicles?.filter(
            (vehicle: any) =>
              vehicle.type == TransportationType.Drop ||
              vehicle.type == TransportationType.Both
          );
          return {
            studentId: data?.studentId,
            data: formView.student
          };
        } else if (slug === "employee") {
          formView.employee.employeeId = data?.employeeId;
          formView.employee.empDepartment = data?.empDept;
          formView.employee.destination = data?.stop;
          formView.employee.employeeType = data?.eType;
          formView.employee.agency = data?.agency;
          formView.employee.category = data?.category;
          formView.employee.shift = data?.shift;
          formView.employee.pickup = data?.vehicles?.filter(
            (vehicle: any) =>
              vehicle.type == TransportationType.Pickup ||
              vehicle.type == TransportationType.Both
          );
          formView.employee.drop = data?.vehicles?.filter(
            (vehicle: any) =>
              vehicle.type == TransportationType.Drop ||
              vehicle.type == TransportationType.Both
          );
          return {
            employeeId: data?.employeeId,
            data: formView.employee
          };
        }
      }
    })
    .catch((error: any) => {
      return {
        userId: id,
        data:
          slug === "user"
            ? formView.user
            : slug === "employee"
              ? formView.employee
              : formView.student
      };
    });
};

const onUpdateUserDetails = async (
  userId: any,
  additionalPrimaryData: any,
  data: any,
  userData: any
): Promise<any> => {
  // arranging body json, because we should send as like web api doc
  const body = {
    userId: userId,
    insId: additionalPrimaryData?.insId,
    loginUserId: additionalPrimaryData?.currentUser,
    name: data?.name,
    parentName: data?.parentName,
    phone: data?.phoneNumber,
    email: data?.email,
    rfidNo: data?.rfidNo,
    vehicles: data?.vehicles,
    gender: data?.gender,
    emergencyNo: data?.emergencyContactNo,
    rfid: data?.rfid,
    userRole: data?.userRole,
    userType: data?.userType
  };
  return await axiosInstance().put("/api/users/update", body);
};
//@surya use types what is this 1,2,3
const onUpdateStudentDetails = async (
  userId: any,
  additionalPrimaryData: any,
  data: any
): Promise<any> => {
  // Split the pickup and drop IDs into arrays
  const pickupIds = data.pickup.split(",");
  const dropIds = data.drop.split(",");

  // Combine both arrays
  const combinedIds = Array.from(new Set([...pickupIds, ...dropIds]));

  // Map combined array to form a obj with type
  const trackerObjects = combinedIds.map(trackerid => {
    // to cosider type 3 if same trackerid in pick and drop
    const isPickup = pickupIds.includes(trackerid);
    const isDrop = dropIds.includes(trackerid);

    let type;
    if (isPickup && isDrop) {
      // Tracker ID is present in both pickup and drop
      type = 3;
    } else if (isPickup) {
      // Tracker ID is only present in pickup
      type = 1;
    } else {
      // Tracker ID is only present in drop
      type = 2;
    }

    return {
      trackerid: trackerid,
      type: type
    };
  });

  // arranging body json, because we should send as like web api doc
  const body = {
    userClass: data?.standard,
    userSection: data?.section,
    admissionNo: data?.admissionNo,
    vehicles: trackerObjects,
    userId: userId,
    insId: additionalPrimaryData?.insId,
    loginUserId: additionalPrimaryData?.currentUser
  };
  return await axiosInstance().put("/api/users/update/secondary", body);
};

const onCreateUserDetails = async (
  additionalPrimaryData: any,
  data: any
): Promise<any> => {
  // arranging body json, because we should send as like web api doc
  const body = {
    name: data?.name,
    parentName: data?.parentName,
    phone: data?.phoneNumber,
    gender: data?.gender,
    email: data?.email,
    emergencyNo: data?.emergencyContactNo,
    destination: data?.destination,
    rfid: data?.rfid,
    rfidNo: data?.rfidNo,
    userRole: data?.userRole,
    userType: data?.userType,
    loginUserId: additionalPrimaryData?.currentUser, // should change dynamically
    insId: additionalPrimaryData?.insId
  };
  return await axiosInstance().post("api/users/create", body);
};

const onGetUsers = async (url: string, body: any): Promise<any> => {
  return await axiosInstance()
    .post(url, body)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<IUsers> = response.data;
        const rows = neoresponse?.data?.users?.map(row => ({
          ...row,
          id: row.userId
        }));
        return {
          columns: neoresponse?.data?.columns,
          rows: rows,
          meta: neoresponse?.meta
        } as TableResponse<Users>;
      }
      return onGetTableResponse();
    })
    .catch((error: any) => {
      return onGetTableResponse();
    });
};

const onUserAction = async (
  url: string,
  institutionUserId: string | null,
  actionType: string,
  payload: any
): Promise<any> => {
  let body;

  switch (actionType) {
    case "delete":
    case "resetpassword":
      body = {userId: institutionUserId};
      break;
    default:
      body = payload;
      break;
  }
  return await axiosInstance()
    .put(url, body)
    .then((response: any) => {
      return response; // returning status and message
    })
    .catch((error: any) => {
      throw error;
    });
};

export const userService = {
  onGetUsers,
  onGetUser,
  onUpdateUserDetails,
  onFetchUserDetails,
  onUserAction,
  onUpdateStudentDetails,
  onCreateUserDetails
};
