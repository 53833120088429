"use client";
import {NeoButton} from "@/components/forms";
import {NeoHeader} from "@/components/shared";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";

interface DashboardSettingProps {
  onSubmit: any;
  settingdatas: any;
}

export const DashboardSetting: React.FC<DashboardSettingProps> = ({
  onSubmit,
  settingdatas
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [speedLimit, setSpeedLimit] = useState({
    min: 0,
    max: 0,
    value: 0
  });
  const [distanceLimit, setDistanceLimit] = useState({
    min: 0,
    max: 0,
    value: 0
  });
  const [speedTooltipPosition, setSpeedTooltipPosition] = useState(0);
  const [distanceTooltipPosition, setDistanceTooltipPosition] = useState(0);
  const speedDivRef = useRef<HTMLInputElement>(null);
  const distanceDivRef = useRef<HTMLInputElement>(null);

  const handleTooltipPosition = (divRef: any) => {
    if (!divRef.current) return 0;
    const slider = divRef.current;
    const sliderWidth = slider.offsetWidth;
    const min = parseInt(slider.min, 10);
    const max = parseInt(slider.max, 10);
    const value = parseInt(slider.value, 10);
    const percentage = ((value - min) / (max - min)) * 100;
    return (percentage / 100) * sliderWidth;
  };

  const {
    reset,
    resetField,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    control,
    setValue,
    setError,
    clearErrors
  } = useForm<any>({
    mode: "all"
  });

  useEffect(() => {
    if (settingdatas?.speedLimit) {
      var speeddata = settingdatas.speedLimit;
      setSpeedLimit((prevData: any) => ({
        ...prevData,
        ["min"]: speeddata.min,
        ["max"]: speeddata.max,
        ["value"]: speeddata.value
      }));
    }

    if (settingdatas?.distanceLimit) {
      var distancedata = settingdatas.distanceLimit;
      setDistanceLimit((prevData: any) => ({
        ...prevData,
        ["min"]: distancedata.min,
        ["max"]: distancedata.max,
        ["value"]: distancedata.value
      }));
    }
  }, [settingdatas]);

  useEffect(() => {
    const speedTooltipposition = handleTooltipPosition(speedDivRef);
    if (speedTooltipposition) {
      setSpeedTooltipPosition(speedTooltipposition);
    }

    const distanceTooltipPosition = handleTooltipPosition(distanceDivRef);
    if (distanceTooltipPosition) {
      setDistanceTooltipPosition(distanceTooltipPosition);
    }
  }, [speedLimit, distanceLimit]);

  const speedlimitchange = (e: ChangeEvent<any>) => {
    setSpeedLimit((prevData: any) => ({
      ...prevData,
      ["value"]: e.target.value
    }));
    const speedposition = handleTooltipPosition(speedDivRef);
    setSpeedTooltipPosition(speedposition);
  };

  const distancelimitchange = (e: ChangeEvent<any>) => {
    setDistanceLimit((prevData: any) => ({
      ...prevData,
      ["value"]: e.target.value
    }));
    const distanceTooltipPosition = handleTooltipPosition(distanceDivRef);
    setDistanceTooltipPosition(distanceTooltipPosition);
  };

  const onSubmitEvent = async (data: any) => {
    const requestdatas = {speedLimit: speedLimit, distanceLimit: distanceLimit};
    onSubmit(requestdatas);
  };

  return (
    <>
      <div className="flex flex-col gap-1 p-2">
        <div className="mb-1 flex-row">
          <NeoHeader message="Limits"></NeoHeader>
        </div>
        <form
          onSubmit={handleSubmit(event => {
            onSubmitEvent(event);
          })}
        >
          <div className="flex flex-col gap-1 p-2">
            <div className="py-2">
              <h5 className="">Speed Limit</h5>
              <div className="mt-6 flex grid grid-cols-1 gap-5">
                <div className="relative w-full max-w-md">
                  <div
                    className="absolute -top-6 w-12 rounded-md bg-black text-center text-white shadow-md"
                    style={{
                      left: `${speedTooltipPosition}px`,
                      transform: "translateX(-50%)"
                    }}
                  >
                    {speedLimit.value}
                  </div>
                  <input
                    ref={speedDivRef}
                    type="range"
                    min={speedLimit.min}
                    max={speedLimit.max}
                    step="5"
                    value={speedLimit.value}
                    onChange={speedlimitchange}
                    className="h-4 w-full cursor-pointer rounded-full accent-black"
                  />
                </div>
              </div>
            </div>
            <div className="py-2">
              <h5>Distance Limit</h5>
              <div className="mt-6 flex grid grid-cols-1 gap-5">
                <div className="relative w-full max-w-md">
                  <div
                    className="absolute -top-6 w-12 rounded-md bg-black text-center text-white shadow-md"
                    style={{
                      left: `${distanceTooltipPosition}px`,
                      transform: "translateX(-50%)"
                    }}
                  >
                    {distanceLimit.value}
                  </div>
                  <input
                    ref={distanceDivRef}
                    type="range"
                    min={distanceLimit.min}
                    max={distanceLimit.max}
                    step="5"
                    value={distanceLimit.value}
                    onChange={distancelimitchange}
                    className="h-4 w-full cursor-pointer rounded-full accent-black"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-5">
              <NeoButton type="submit" color="yellow" className="text-lg">
                Submit
              </NeoButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
