"use client";
import {NeoSwitch} from "@/components/forms";
import {SwitchProps} from "@nextui-org/react";
import React from "react";

interface NeoSwitchInputProps extends Omit<SwitchProps, "onChange"> {
  className?: string;
  label?: string;
  activeLabel?: string;
  inActiveLabel?: string;
  errors?: string;
}

export const NeoSwitchInput: React.FC<NeoSwitchInputProps> = ({
  className,
  label,
  activeLabel,
  inActiveLabel,
  errors,
  ...restProps
}) => {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <label className="text-base font-normal text-neo-black dark:text-neo-white">
        {label}
      </label>
      <div className="flex items-center gap-1 text-base">
        <span className="mr-2">{inActiveLabel}</span>
        <NeoSwitch {...restProps} />
        <span>{activeLabel}</span>
      </div>
      {errors && <span className="text-tiny text-danger">{errors}</span>}
    </div>
  );
};
