"use client";
import {NeoModal} from "@/components/@modal/neo.modal";
import {NeoCard, NeoHeader} from "@/components/shared";
import {ReportMenu} from "@/data";
import type {NextPage} from "next";
import {useState} from "react";
import {twMerge} from "tailwind-merge";

const reportMenu = ReportMenu as REPORTMEUSCONFIG;

export const Reports: NextPage = function () {
  const [modalOpen, setModalOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const openPopup = (reportType: any, activereport: any) => {
    setModalOpen(true);
    reportType.activeReport = activereport;
    setMenuData(reportType);
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value.toLowerCase().trim());
  };

  // **Filter Report Menu Based on Search Term**
  const filteredReports = reportMenu
    .map(submenus => {
      // **Apply search filter**
      const filteredMenus = submenus.menus.filter(
        menus =>
          searchTerm === "" || menus.menu.toLowerCase().includes(searchTerm)
      );

      // **Skip if no reports match the search term**
      if (filteredMenus.length === 0) {
        return null;
      }

      return (
        <NeoCard
          className="h-96 min-h-64 overflow-y-auto p-4"
          key={submenus.order}
        >
          <h2 className="mb-2 text-xl font-semibold">{submenus.header}</h2>
          <ul>
            {filteredMenus.map((menus, key) =>
              menus.url ? (
                <li
                  key={key}
                  className={twMerge(
                    "mb-1 flex min-h-16 cursor-pointer items-center justify-between transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-800",
                    key % 2 !== 0
                      ? "bg-gray-100 dark:bg-gray-800"
                      : "bg-white dark:bg-gray-900"
                  )}
                  onClick={() => openPopup(submenus, menus)}
                  title={menus.menu_description}
                >
                  <div className="flex w-full items-center gap-2 p-4">
                    <div className="rounded-full bg-yellow-300 p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-bus"
                      >
                        <path d="M8 6v6" />
                        <path d="M15 6v6" />
                        <path d="M2 12h19.6" />
                        <path d="M18 18h3s.5-1.7.8-2.8c.1-.4.2-.8.2-1.2 0-.4-.1-.8-.2-1.2l-1.4-5C20.1 6.8 19.1 6 18 6H4a2 2 0 0 0-2 2v10h3" />
                        <circle cx="7" cy="18" r="2" />
                        <path d="M9 18h5" />
                        <circle cx="16" cy="18" r="2" />
                      </svg>
                    </div>
                    <div className="flex w-full flex-col gap-1">
                      <span className="text-sm font-semibold">
                        {menus.menu}
                      </span>
                      <p className="text-xs text-gray-600">
                        {menus.menu_description}
                      </p>
                    </div>
                  </div>
                </li>
              ) : null
            )}
          </ul>
        </NeoCard>
      );
    })
    .filter(Boolean); // **Removes empty results from the array**

  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
      <div className="mb-4 flex-row px-3">
        <NeoHeader message="Reports" />
      </div>

      {/* 🔍 Search Box */}
      <div className="p-4">
        <input
          type="text"
          placeholder="Search..."
          className="w-full rounded border px-2 py-1"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {/* 📄 Reports Section */}
      <div className="flex-row">
        <div className="p-3">
          <div className="grid grid-cols-2 gap-2 p-1">
            {filteredReports.length > 0 ? (
              filteredReports
            ) : (
              <p className="text-gray-600">No reports found</p>
            )}
          </div>
        </div>
      </div>

      {/* 📌 Modal Popup */}
      <NeoModal
        onOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        actionType={"report"}
        hasActions={false}
        data={menuData}
      />
    </div>
  );
};
