import {EyeIcon} from "@/components/shared";
import {useSidebarContext} from "@/context/sidebar/sidebar.context";
import React from "react";
import {twMerge} from "tailwind-merge";
import Loader from "../Loader";
interface DistanceDeviationProps {
  isLoading: boolean;
  usersetting: any;
  distanceDeviation: any;
  deviationlimit: any;
  openPopup: (title: popupParams) => void;
}

const DistanceDeviationWidget: React.FC<DistanceDeviationProps> = ({
  isLoading,
  usersetting,
  distanceDeviation,
  deviationlimit,
  openPopup
}) => {
  const {isCollapsed} = useSidebarContext();
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="flex items-center justify-between p-2">
            <h5 className="text-lg font-semibold">Distance Deviation</h5>
            <div className="flex items-center">
              <h5
                className="cursor-pointer pr-2"
                title="Settings"
                onClick={usersetting}
              >
                <img
                  className="h-6 w-6 p-1"
                  src="/icons/setting_1.svg"
                  alt="Setting"
                />
              </h5>
              <h5
                className="cursor-pointer"
                title="View"
                onClick={() => openPopup({title: "Distance Deviation"})}
              >
                <EyeIcon size={18} />
              </h5>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-2 flex-row gap-1 pt-5">
              <div className="rounded bg-[#FFC7271A] p-3">
                <div className="justify-left flex">
                  <img
                    className="h-12 w-12 max-w-xs p-2"
                    src="/icons/driver_1.svg"
                    alt="Trip"
                  />
                </div>
                <div className="mt-3">
                  <h5 className="text-3xl font-semibold">
                    {distanceDeviation.deviationvehicles}
                    <span className="text-sm font-semibold text-slate-500 dark:text-neo-white">
                      /{distanceDeviation.total}
                    </span>
                  </h5>
                  <h5 className="text-sm font-semibold text-slate-500 dark:text-neo-white">
                    Deviated Vehicles
                  </h5>
                </div>
              </div>
              <div className="p-2">
                <div className="justify-left mt-1 flex">
                  <h5 className="pr-1 text-xl font-semibold">
                    {distanceDeviation.threshold}{" "}
                    <span className="text-sm font-semibold">kms</span>
                  </h5>
                  <div className="ml-1 mt-1">
                    <img
                      className="w-10 max-w-xs"
                      src="/icons/limit.svg"
                      alt="Limit"
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <h5 className="text-xs font-semibold text-slate-500 dark:text-neo-white">
                    Deviation
                  </h5>
                  <h5 className="text-xs font-semibold ">
                    {distanceDeviation.deviatepercent}
                  </h5>
                </div>
                <div className="items-center">
                  <div className="mt-4 flex flex-col text-[#FFC727]">
                    <div className="flex justify-between">
                      <span className="items-start text-xs">0</span>
                      <span className="items-center text-xs">50</span>
                      <span className="items-end text-xs">100</span>
                    </div>
                    <div
                      className={twMerge(
                        "flex justify-between",
                        isCollapsed ? "gap-x-3" : "gap-x-2"
                      )}
                    >
                      {deviationlimit?.map((value: number, index: any) => (
                        <div
                          key={index}
                          className="h-14 w-2 rounded bg-gray-200"
                        >
                          <div
                            className="h-14 rounded bg-[#FFC727]"
                            style={{width: value}}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DistanceDeviationWidget;
