import {EyeIcon} from "@/components/shared";
import React from "react";
import Loader from "../Loader";

interface BusAttendanceProps {
  isLoading: boolean;
  busAttendance: any;
  openPopup: (title: popupParams) => void;
}

const BusAttendanceWidget: React.FC<BusAttendanceProps> = ({
  isLoading,
  busAttendance,
  openPopup
}) => {
  return (
    <>
      <div className="rounded-2xl border bg-neo-white dark:bg-[#1F2937]">
        <div className="p-2">
          <div className="flex items-center justify-between p-2">
            <h5 className="text-lg font-semibold">Bus Attendance</h5>
            <h5
              className="cursor-pointer"
              title="View"
              onClick={() => openPopup({title: "Bus Attendance"})}
            >
              <EyeIcon size={18} />
            </h5>
          </div>
          <div
            className={`mt-5 rounded-full border-[#FFC7271A] bg-[#FFC7271A] ${isLoading ? "hidden" : "block"}`}
          >
            <div className="justify-left flex">
              <img
                className="h-12 w-12 max-w-xs p-2"
                src="/icons/UserGroup.svg"
                alt="Trip"
              />
              <h5 className="pt-2 text-base font-semibold">
                Total Students{" "}
                <span className="ml-3 text-xl font-semibold">
                  {busAttendance.total}
                </span>
              </h5>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-2 flex-row gap-2 pt-5">
              <div className="border-r-2 border-dashed">
                <div className="justify-left flex">
                  <img
                    className="h-12 w-12 p-2"
                    src="/icons/present.svg"
                    alt="Present"
                  />
                  <div className="pl-2">
                    <h5 className="text-base">Present</h5>
                    <h5 className="text-xl font-semibold">
                      {busAttendance.present}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="justify-left flex">
                  <img
                    className="h-12 w-12 max-w-xs p-2"
                    src="/icons/absent.svg"
                    alt="Absent"
                  />
                  <div className="pl-2">
                    <h5 className="text-base">Absent</h5>
                    <h5 className="text-xl font-semibold">
                      {busAttendance.absent}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BusAttendanceWidget;
