import {MenuIcon} from "@/components/shared";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
import {useMemo} from "react";
type ActionKey = string | number;
export const NeoAction: React.FC<any> = ({
  data,
  actions,
  meta,
  handleAction
}) => {
  const contextId = useMemo(() => {
    switch (meta?.id) {
      case 1:
        return data.userId;
      case 2:
        return data.vehicleId;
      case 3:
        return data.driverId;
      default:
        return data.id;
    }
  }, [actions]);

  return (
    <div className="relative flex items-center justify-end gap-2">
      <Dropdown>
        <DropdownTrigger>
          <Button isIconOnly size="sm" variant="light">
            <MenuIcon />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          items={actions}
          aria-label={contextId}
          onAction={(key: ActionKey) => handleAction(key, contextId, data)}
        >
          {(item: NeoTableActions) => (
            <DropdownItem
              key={item.key}
              aria-label={item.key}
              color={
                item.key === "delete" || item.key === "deactivate"
                  ? "danger"
                  : "warning"
              }
              className={
                item.key === "delete" || item.key === "deactivate"
                  ? "text-danger"
                  : ""
              }
            >
              {item.label}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
