"use client";

import {NeoModal} from "@/components/@modal/neo.modal";
import {
  MenuIcon,
  NeoCard,
  NeoHeader,
  NeoLabel,
  NeoSubHeader
} from "@/components/shared";
import {useNeoContext} from "@/context/neo/neo.context";
import {profileService} from "@/service/profile.service";
import {
  Button,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
import type {NextPage} from "next";
import router from "next/router";
import {useEffect, useState} from "react";
export const Profile: NextPage = function () {
  const [modalOpen, setModalOpen] = useState(false);
  const {filterContext} = useNeoContext();

  const handleAction = (key: any) => {
    const item = data?.actions.find((x: ProfileModule.Action) => x.key === key);
    if (!item?.action) {
      router.push(item.link);
    } else {
      setActionType(item.action);
      setModalOpen(true);
    }
  };

  const [data, setData] = useState<any>();
  const [actionType, setActionType] = useState<string>("");
  useEffect(() => {
    const fetchData = async () => {
      const action = [
        {
          link: "",
          action: "change-password",
          label: "Change Password",
          key: "change-password"
        },
        {
          link: "",
          action: "change-mobile",
          label: "Change Mobile Number",
          key: "change-mobile"
        }
        // {
        //   link: "/profile/edit",
        //   action: "",
        //   label: "Edit",
        //   key: "profile-edit"
        // }
      ];
      setData({...filterContext.client, actions: action});
    };
    fetchData();
  }, [filterContext]);

  return (
    <>
      <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
        <div className="mb-4 flex-row px-3">
          <NeoHeader message={"Profile"}></NeoHeader>
        </div>
        <div className="flex-row">
          <div className="w-1/2 flex-col">
            <NeoCard>
              <CardHeader>
                <div className="flex w-full justify-between">
                  <div className="flex w-[90%] px-2">
                    <NeoSubHeader message={"Information"}></NeoSubHeader>
                  </div>
                  <div className="ml-auto w-[10%] pt-1">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button isIconOnly size="sm" variant="light">
                          <MenuIcon />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        items={data?.actions}
                        onAction={(key: any) => handleAction(key)}
                      >
                        {data?.actions?.map((item: any) => (
                          <DropdownItem key={item.key}>
                            {item.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="flex-row px-3">
                  <div className="flex flex-wrap">
                    {data ? (
                      <>
                        {" "}
                        <div className="mb-5 w-1/2" key={"1"}>
                          <NeoLabel
                            label={"Username"}
                            value={data.institutionUserName}
                          ></NeoLabel>
                        </div>
                        <div className="mb-5 w-1/2" key={"2"}>
                          <NeoLabel
                            label={"Institution name"}
                            value={data.institutionName}
                          ></NeoLabel>
                        </div>
                        <div className="mb-5 w-1/2" key={"3"}>
                          <NeoLabel label={"Name"} value={data.name}></NeoLabel>
                        </div>
                        <div className="mb-5 w-1/2" key={"4"}>
                          <NeoLabel
                            label={"Mobile Number"}
                            value={data.institutionUserPhone}
                          ></NeoLabel>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </CardBody>
            </NeoCard>
          </div>
        </div>
      </div>
      <NeoModal
        actionType={actionType}
        onOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        hasActions={false}
        //  rowData={undefined}
      ></NeoModal>
    </>
  );
};

export async function getServerSideProps() {
  const data = await profileService.onGetProfile();
  return {
    props: {
      data
    }
  };
}
